import { FormGroup, Button, ButtonSpinner } from '@/components';
import { useForm, SubmitHandler } from 'react-hook-form';
import React, { useEffect, useState } from 'react';
import { useAppSelector } from '@/hooks/useAppSelector';
import { Link } from 'react-router-dom';

import {
  StyledWrap,
  StyledTitle,
  StyledSubHeader,
  StyledSubHeaderText,
  StyledSubHeadLogo,
  StyledButtonContainer,
  StyledBrowserClose,
} from './style';

import { BrowserCloseIconSvg } from '@/shared/svg';

interface ChildProps {
  closePopup(arg0: string): void;
  parentModalHandlerFunction: any;
  page: string;
}

const TermsForm = (props: ChildProps) => {
  const [message, setMessage] = useState('');
  const [enableTerms, setEnableTerms] = useState(false);
  const [scrollObj, setScrollObj] = useState({});
  const [isNotValid, setIsNotValid] = useState(false);
  const [decline, setDecline] = useState(false);
  const [loading, setLoading] = useState(false);
  const { isLoading, isError, errorMessage } = useAppSelector(
    state => state.auth
  );

  const {
    handleSubmit,
    formState: { errors },
  } = useForm<TermsCForm>();

  const onSubmit: SubmitHandler<TermsCForm> = data => {
    console.log(errors, data, isNotValid, scrollObj, message);
    setLoading(true);
    if (props.page === 'register')
      props.parentModalHandlerFunction('signupageconsent');
    else if (props.page === 'login')
      props.parentModalHandlerFunction('signinageconsent');
    else props.parentModalHandlerFunction('defaultpageageconsent');
  };

  const handleDecline = () => {
    if (props.page === 'defaultterms') setDecline(true);
    else props.closePopup('close');
  };

  const handleScroll = (e: { target: any }) => {
    const target = e.target;
    const actualHeight = target.scrollHeight - target.scrollTop;

    const clientHeight = Math.round(target.clientHeight) + 5;
    setScrollObj({
      actualHeight: Math.round(actualHeight),
      clinet: clientHeight,
    });
    if (Math.round(actualHeight) < clientHeight) {
      setEnableTerms(true);
    }
  };

  const refresh = () => {
    location.reload();
  };

  useEffect(() => {
    setLoading(isLoading);
    if (isError) {
      setIsNotValid(true);
      setMessage(errorMessage);
    }
  }, [isError, isLoading]);

  return (
    <StyledWrap>
      {!decline && (
        <>
          <StyledTitle>Acknowledgement</StyledTitle>
          <StyledSubHeader>
            <StyledSubHeaderText>
              <p>
                Online Gaming
                <br />
                Terms and Conditions
                <br />
                As of Oct 1, 2024
              </p>
            </StyledSubHeaderText>
            <StyledSubHeadLogo>
              <Link to='/'>
                <img
                  height='40px'
                  src={process.env.REACT_APP_THEME_LOGO}
                  alt=''
                />
              </Link>
            </StyledSubHeadLogo>
          </StyledSubHeader>
          <form action='#' onSubmit={handleSubmit(onSubmit)} noValidate>
            <div className='termsview'>
              <FormGroup className='loginform'>
                <div className='termsandcondition' onScroll={handleScroll}>
                  <h5>ACKNOWLEDGEMENT</h5>
                  <p>Online Gaming</p>

                  <h5>Terms and Conditions</h5>
                  <p>
                    Please read these Terms and Conditions (“Terms and
                    Conditions”, “terms”) carefully before you download mobile
                    application, use, visit and/or access any part of our
                    website and/or any sub-domain, website (the “Website”),
                    and/or registering an account on the Website operated by
                    NEMO INTERACTIVE GROUP (NEMO), doing business as FairPlay
                    Remote Gaming Platform (RGP) (hereafter “Licensee”, “we”,
                    “us”, “our”).
                  </p>
                  <p>
                    By using, visiting and/or accessing any part of our website
                    and/or any sub-domain, website (the “Website”) and/or
                    registering an account on the Website, you are considered to
                    have read and agreed to be bound by these Terms and
                    Conditions, our Privacy Policy, our Cookies Policy and any
                    other rules applicable to our gaming products available on
                    the Website (together the "Terms"), and are deemed to have
                    accepted and understood all the Terms.
                  </p>
                  <h5>Player Confirmation</h5>
                  <p>
                    By registering to our website or via our mobile application,
                    you confirm and affirm:
                  </p>
                  <p>
                    The correctness and authenticity of the mandatory
                    information you provided and the valid identification
                    document you submitted. The information provided is subject
                    to re-verification of user information to ensure ongoing
                    compliance with PAGCOR regulations.
                  </p>
                  <p>
                    That, in pursuance to the Philippine Amusement and Gaming
                    Corporation, PAGCOR, Charter and Malacañang Memorandum
                    Circular No. 8,you are not among those who are unauthorized
                    to gamble such as:
                  </p>
                  <p>
                    (a) Public officials, which includes elective and appointive
                    officials and employees, permanent or temporary, whether in
                    the career or non-career service, whether or not they
                    receive compensation, regardless of amount;
                  </p>
                  <p>
                    (b) Members of the Armed Forces of the Philippines,
                    including the Army, Navy, Air Force or Philippine National
                    Police;.
                  </p>
                  <p>
                    (c) Persons under 21 years of age or students of any school,
                    college or university in the Philippines;
                  </p>
                  <p>(d) PAGCOR officials and employees;</p>
                  <p>(e) Gaming site Operators and their employees;</p>
                  <p>(f) Unregistered players;</p>
                  <p>(g) Banned individuals;</p>
                  <p>
                    (h) Spouse, common-law partner, children, parents of
                    officials and persons mentioned in items (a), (b), and (d)
                    above.
                  </p>
                  <p>
                    that you were not diagnosed to have Compulsive Gambling
                    Disorder, nor registered as a Self- Excluded Player in
                    accordance to the guidelines set by the PAGCOR.
                  </p>
                  <h5>Account Registration</h5>
                  <p>
                    You are required to register or make an account through our
                    website or mobile application, before you are given access
                    to our services.
                  </p>
                  <p>
                    Subject to existing PAGCOR rules and regulations, the Anti
                    Money Laundering Act and other related rules and
                    regulations, you shall only be allowed to register upon
                    complete provision of the mandatory information and
                    submission of at least one (1) valid government-issued
                    identification (ID) card.
                  </p>
                  <h5>List of Acceptable valid and unexpired IDs:</h5>
                  <ul>
                    <li>Driver’s License</li>
                    <li>PhilHealth ID</li>
                    <li>Postal ID</li>
                    <li>NBI Clearance</li>
                    <li>Senior Citizen Card</li>
                    <li>Overseas Workers Welfare Administration (OWWA)</li>
                    <li>ID Passport (Philippine/Foreign)</li>
                    <li>Firearms License</li>
                    <li>Police Clearance</li>
                    <li>Integrated Bar of the Philippines ID</li>
                    <li>Seaman’s Book</li>
                    <li>Professional Regulations Commission</li>
                    <li>Overseas Filipino Workers (OFW) ID</li>
                    <li>Social Security System (SSS) Card</li>
                    <li>Tax Identification (TIN)</li>
                    <li>Unified Multi-Purpose ID (UMID)</li>
                    <li>Voter’s Certificate and/or Voter’s ID</li>
                    <li>Maritime Industry Authority (MARINA) ID</li>
                    <li>Philippine National ID</li>
                    <li>Valid Passport</li>
                    <li>Alien Certificate of Registration</li>
                    <li>Immigrant Certificate of Registration</li>
                  </ul>
                  <p>
                    You are allowed to register only one (1) Online Platform
                    Account.
                  </p>
                  <p>
                    Once you have registered, you shall be responsible for any
                    and all activities on your account. You shall not allow any
                    other person or entity to access or use your account.
                  </p>
                  <p>
                    You understand that your utilization of the Licensee’s
                    services is purely voluntary. You agree and understand that
                    you shall solely bear all the risks, including the risk of
                    loss caused by using our services.
                  </p>
                  <p>
                    The Online Gaming Account of the player shall not be used
                    for or on behalf of another person. The player shall not
                    allow or authorize any third party to access or use his or
                    her account. Members shall, at all times, be responsible for
                    any activity on his or her account. You shall keep and
                    secure your account credentials, including the password
                    properly, and must not allow any other person or entity to
                    use or have access to them. In case you forget or lose your
                    password, you may request assistance from our Customer
                    Service Team, subject to our investigation/verification
                    process, to recover the same.
                  </p>
                  <p>
                    You are responsible for ensuring the confidentiality of your
                    account and login credentials. Any transaction done with
                    your username and password is considered to have been made
                    by you. Members are expected to never share their PIN and/or
                    password with anyone. Any activities/transactions in your
                    account are considered valid if your Member ID No. and PIN
                    and/or password have been entered correctly.
                  </p>
                  <p>
                    For enhanced security, we highly recommend that you activate
                    your device's biometric security feature to prevent unwanted
                    activities made to your account.
                  </p>
                  <p>
                    You shall not, in any way, attempt to modify, reverse
                    engineer, or tamper our gaming application.
                  </p>
                  <p>
                    Licensee may require you to update or submit additional
                    proof of identification at any given moment, thereby
                    ensuring the accuracy and validity of your information.
                  </p>
                  <h5>Use of the Remote Gaming Platform</h5>
                  <p>
                    You may access the Licensee’s Remote Gaming Platform (RGP)
                    using any mobile device or desktop.
                  </p>
                  <p>
                    Your account can only be used on one (1) device at a time.
                    Multiple log-ins will result in session termination on other
                    devices. Your account is exclusively for gaming purposes
                    only.
                  </p>
                  <p>
                    The RGP can only be accessed and used by valid registered
                    players within the Philippine jurisdiction only. Valid
                    Registered players are those who have no pending exclusion
                    orders from PAGCOR or NEMO.
                  </p>
                  <p>
                    NEMO strictly prohibits any reproduction, copying,
                    recording, taking any screenshots, broadcasting, or any
                    other similar activities, of any contents, footages or
                    images shown on NEMO’s RGP.
                  </p>
                  <h5>Account Funding / Withdrawal</h5>
                  <p>
                    It is understood that in order to play on our RGP, you are
                    required to have sufficient funds in your account.
                  </p>
                  <p>
                    You may deposit funds into your online account through
                    several online payment methods authorized by the Licensee.
                  </p>
                  <p>
                    You are required to deposit Five Hundred Pesos (Php 500) on
                    the first deposit.
                  </p>
                  <p>
                    You fully agree that certain personal information (including
                    specific information about payment method) must be provided
                    to the Licensee for confirmation when using its services. In
                    this regard, the Licensee ensures that it will comply with
                    the Data Privacy Act of 2012, and other relevant Issuances
                    with respect to the confidentiality of your financial
                    information and personal data.
                  </p>
                  <p>
                    Applicable taxes and fees arising from game winnings shall
                    be for your sole account, including necessary bank charges.
                    Members shall be responsible for reporting the winnings and
                    remitting the applicable taxes and fees, if any, to the
                    appropriate authorities.
                  </p>
                  <p>
                    Licensee does not guarantee real time posting of deposits or
                    withdrawals as the same is subject to separate processing
                    policies, procedures and turn-around-time of our partner
                    financial institutions.
                  </p>
                  <p>
                    NEMO's RGP is a service provided by NEMO to provide a
                    platform for casino games and shall not be used as a bank
                    account.
                  </p>
                  <p>
                    NEMO may, in certain circumstances as provided in NEMO's
                    FairPlay Online Gaming Implementing Rules and Guidelines,
                    conduct verification of your identity or sources of funds
                    deposited.
                  </p>
                  <h5>Game Selection and Online Gaming</h5>
                  <p>
                    You can select and join games with minimum and maximum bets
                    available on the RGP.
                  </p>
                  <p>
                    You are aware of the possibility of winning or losing money
                    when playing on any of the offered games. You are solely
                    responsible for and shall assume all losses incurred
                    throughout its play.
                  </p>
                  <p>
                    You fully understand the rules and procedures of all games,
                    including their odds, payouts and betting limits offered in
                    the RGP.
                  </p>
                  <p>
                    It is the Member's responsibility to ensure the correctness
                    of bets placed at all times.
                  </p>
                  <p>
                    Should a player be disconnected from the RGP after
                    successfully placing his/her bet, the same shall be
                    considered as a valid bet and the game will continue despite
                    disconnection. Crediting to and debiting from the player’s
                    account shall be in accordance with the results of the game.
                  </p>
                  <h5>Liability</h5>
                  <p>
                    You acknowledge and agree that Licensee shall not be liable
                    for any losses or damages incurred by you due to your fault
                    or negligence, or of third parties. Licensee shall not be
                    liable if the latter is unable to complete any funding
                    instructions initiated by you for any reason or cause
                    beyond: the control of Licensee, including but not limited
                    to: (i) insufficiency of your funds; (ii) withdrawal would
                    exceed the transactional limit set for your account; (iii)
                    system maintenance or system downtime; (iv) Unforeseeable
                    events such as but not limited to fire, flood, system
                    breakdown, technical bugs, and the like, or events though
                    foreseen may have prevented Licensee the proper
                    implementation of the funding transaction, despite the
                    precautions undertaken by the Licensee.
                  </p>
                  <h5>Changes to Terms and Conditions</h5>
                  <p>
                    Licensee reserves the right to modify, change and revise
                    these Terms and Conditions at any time, without notice or
                    compensation and subject to compliance with PAGCOR
                    regulations. This includes, but not limited to, the right to
                    postpone, temporarily halt, or terminate any services,
                    offers and such other activities or transactions in NEMO's
                    FairPlay Online Casino Portal at its sole and absolute
                    discretion.
                  </p>
                  <h5>Termination or Suspension</h5>
                  <p>
                    Licensee reserves the right to terminate, close, freeze or
                    suspend your account and/or hold and/or forfeit winnings,
                    due to the following reasons:
                  </p>
                  <p>
                    (i) You have violated any of the Terms and Conditions, such
                    as but not limited to not completing Licensee's “Know Your
                    Customer” procedure;
                  </p>
                  <p>
                    (ii) You are a person banned from gaming as per the National
                    Database for Restricted Persons (NDRP);
                  </p>
                  <p>(iii) You are using multiple accounts;</p>
                  <p>(iv) You have been abusing our promotional guidelines;</p>
                  <p>
                    (v) You are below 21 years old and/or is among banned
                    personalities;
                  </p>
                  <p>(vi) You are found to have or are using bot software;</p>
                  <p>
                    (vii) You are not using your account for gameplay and/or
                    your account has been used for transactions that are
                    suspicious and subject to further investigation upon the
                    exclusive and absolute discretion of the Licensee;
                  </p>
                  <p>
                    (viii) You deposit, withdraw and/or transfer from and to a
                    payment/banking channel other than your registered, subject
                    to further investigation by Licensee;
                  </p>
                  <p>
                    (ix) You allowed, whether intentionally or not, another
                    person to use your FairPlay Online Gaming Account;
                  </p>
                  <p>(x) You attempted to defraud the system;</p>
                  <p>
                    (xi) You engaged in any fraudulent, irregular or criminal
                    activities, such as but not limited to , collusion,
                    cheating, money laundering, etc;
                  </p>
                  <p>
                    (xii) You violated the regulations set by PAGCOR or NEMO's
                    policies and regulations.
                  </p>
                  <p>
                    Upon termination of the Online Gaming Account, the player
                    may also be endorsed to PAGCOR for banning.
                  </p>
                  <p>
                    A player’s violation of any provisions of the Terms and
                    Conditions shall be a ground for the cancellation or
                    suspension of his/her remote gaming platform account, and/or
                    the forfeiture of funds thereof, as the case may be.
                    Forfeited funds shall be part of the Gross Gaming Revenue
                    (GGR).
                  </p>
                  <h5>Communications</h5>
                  <p>
                    So as to provide you the best gaming experience, we have
                    created various communication mediums including, but not
                    limited to, administrative and law-mandated announcements,
                    campaigns, or promotional materials, such as:
                  </p>
                  <p>
                    Website
                    <br />
                    Social media accounts (Facebook)
                    <br />
                    Email
                  </p>
                  <h5>Privacy Notice</h5>
                  <p>
                    We respect and value your rights as a data subject, thus, in
                    collecting, using, processing and storing your personal
                    information in compliance with the requirements of PAGCOR
                    and in order to cater to you our services, we strictly abide
                    by the mandate of the Data Privacy Act of 2012 (DPA) and its
                    Implementing Rules and Regulations (collectively "Privacy
                    Laws"). Aside from PAGCOR, it is necessary to share your
                    information with our third-party service providers,
                    suppliers, partners, related companies, affiliates and
                    subsidiaries to enable us to provide our services, manage
                    your account, track your gameplay, conduct profiling,
                    promotions, campaigns, distribution of prize/s, and such
                    other similar services.
                  </p>
                  <p>
                    You have the right to access, correct, or delete your
                    personal data at any time by contacting our Data Privacy
                    Officer.
                  </p>
                  <h5>When and how do we collect your data or information?</h5>
                  <p>
                    We collect your data or personal information upon your entry
                    into any of our facilities and registration in our guest
                    registration system.
                  </p>
                  <h5>Why do we collect your data or information?</h5>
                  <p>
                    We collect your data or information for any of the following
                    cause(s):
                  </p>
                  <p>Security Reasons;</p>
                  <p>Compliance with lawful orders of competent authorities;</p>
                  <p>Marketing and statistical research;</p>
                  <p>Processing of registrations to avail of our services;</p>
                  <p>
                    Processing of requests to join our marketing
                    programs/campaigns;
                  </p>
                  <p>Contractual and regulatory compliance;</p>
                  <p>
                    Enforcement of our rights before any competent authority,
                    regulator, law enforcement agency, or any judicial or
                    quasi-judicial body.
                  </p>
                  <h5>How do we protect your data or information?</h5>
                  <p>
                    Our player registration system is manned by trained
                    employees and security personnel outsourced from third-party
                    providers who went through our strict screening process.
                    Each of them is covered by appropriate Confidentiality and
                    Data Sharing Agreements.
                  </p>
                  <p>
                    The management and maintenance of our information database,
                    as well as custody of the files and/or logbooks containing
                    the data and information, remain within Licensee’s control
                    and are only viewed by authorized personnel, on a
                    need-to-know basis.
                  </p>
                  <p>
                    As a matter of policy and in compliance with the Data
                    Privacy Act of 2012, we do not share your data or
                    information with third parties, unless the same is with your
                    express consent, or in compliance with the lawful orders of
                    competent authorities.
                  </p>
                  <h5>How long do we store your data or information?</h5>
                  <p>
                    Your personal information will only be retained only for a
                    period of three (3) months or as long may be necessary for
                    the fulfillment of the declared, specified, and legitimate
                    purposes provided above, or when the processing is relevant
                    to the purpose, strictly in accordance with our records
                    retention policy. After which, your personal data shall be
                    disposed of or discarded in a secure manner that would
                    prevent further processing, unauthorized access, or
                    disclosure to any other party or the public, or prejudice
                    the interests of the data subjects.
                  </p>
                  <h5>What are your rights?</h5>
                  <p>
                    Be informed that your personal data will be, are being, or
                    were collected and processed.
                  </p>
                  <p>
                    Reasonable access, upon written requests, to your personal
                    data that an organization collects or holds of you and the
                    reason for such processing.
                  </p>
                  <p>
                    Object if the processing of personal data involved is based
                    on consent or on legitimate interest unless the processing
                    is pursuant to a subpoena, contract, or a result of a legal
                    obligation.
                  </p>
                  <p>
                    Suspend, withdraw, or order the blocking, removal, or
                    destruction of your personal data; Claim compensation if you
                    suffered damages due to inaccurate, incomplete, outdated,
                    false, unlawfully obtained, or unauthorized use of personal
                    data, considering any violation of your rights and freedoms
                    as a data subject.
                  </p>
                  <p>
                    File a complaint to the National Privacy Commission if any
                    of your rights as a data subject were violated.
                  </p>
                  <p>
                    Right dispute and demand correction of the information if
                    the same is inaccurate or erroneous. Obtain and
                    electronically move, copy, or securely transfer your data
                    for further use.
                  </p>
                  <h5>Cookies or links to other websites</h5>
                  <p>
                    Cookies are small text files that are placed on your
                    computer by websites you visit. They are widely used to make
                    websites more efficient, as well as to provide information
                    to the owners of the site. We use cookies to gain a better
                    understanding of how visitors use our website. Cookies help
                    us tailor the website to your personal needs and improve the
                    usability of the website. We keep all the information from
                    cookies in a non- personally identifiable format.
                  </p>
                  <p>
                    While the website may contain links to websites operated by
                    parties other than us, we are not responsible for the
                    privacy practices or contents of such websites.
                  </p>
                  <h5>Our website use:</h5>
                  <p>
                    Necessary cookies - cookies that are necessary for the
                    operation of a website. Without these cookies, this website
                    will not work properly. Accordingly, we are not asking for
                    your consent for those. For all other cookies, your informed
                    consent is required.
                  </p>
                  <p>
                    Functional cookies - cookies that are set up to improve the
                    functionality of the website. Functional cookies may allow
                    us to serve you content that are tailored to your interests.
                  </p>
                  <p>
                    You can change your cookie settings in your browser
                    preferences. This is usually found in the options or
                    preferences menu of your internet browser. You can block any
                    cookies from any website by activating the setting on your
                    browser which allows you to refuse the setting of some or
                    all cookies. However, if you block all cookies you may not
                    be able to access all or a part of our website. You can also
                    use your browser settings to delete cookies.
                  </p>
                  <p>
                    If you have any complaints, questions, concerns, or
                    objections about this Privacy Notice, and/or our personal
                    information processing activities please contact us through
                    the following:
                  </p>
                  <p>Office of the Data Privacy Officer</p>
                  <p>E-mail: dpo@nemo-ig.com</p>
                  <h5>Miscellaneous Provisions</h5>
                  <p>
                    Licensee does not guarantee that its RGP will have
                    uninterrupted availability or that it is infallible or that
                    it will be free of bugs, viruses or other similar errors. We
                    may, at our own discretion, temporarily suspend access to
                    the RGP without notice, due to circumstances such as system
                    failure, maintenance, repair or such other reasons beyond
                    our control.
                  </p>
                  <p>
                    Licensee shall not be held liable for any service
                    interruption or any errors and results thereof caused by the
                    player's internet provider or NEMO's internet provider.
                  </p>
                  <p>
                    Licensee may contain hyperlinks to external or third-party
                    websites, including but not limited to, content, products,
                    programs, services or software, that are not operated or
                    managed by NEMO.{' '}
                  </p>
                  <p>
                    Licensee shall not be held responsible or liable for any of
                    the information or representation contained in the
                    third-party websites. Furthermore, in accessing the
                    third-party website, it is the player's sole responsibility
                    to review the terms and conditions or any other provisions
                    stipulated therein.
                  </p>
                  <p>
                    Licensee shall not be liable to any persons for any
                    injuries, losses or damages in respect of, in connection
                    with and/or arising from the access and utilization of
                    NEMO's RGP.
                  </p>
                  <p>
                    All decisions of the Licensee are final, conclusive and
                    binding.
                  </p>
                  <p>
                    This agreement shall be governed by and construed, in
                    accordance with the laws of the Philippines. The parties
                    shall use their best efforts to settle amicably all disputes
                    arising out of or in connection with NEMO's RGP. In case of
                    litigation or judicial action becomes necessary, the parties
                    shall submit to the jurisdiction of the appropriate courts
                    of the Republic of the Philippines, to the exclusion of all
                    other venues.
                  </p>
                  <p>
                    The English language version of these Terms and Conditions
                    shall prevail; all other versions of these Terms and
                    Conditions are only provided for reference. These Terms and
                    Conditions supersede all previously issued Terms and
                    Conditions.
                  </p>
                  <h5>YOUR DECLARATION AND CONSENT</h5>
                  <p>I hereby declare and state that:</p>
                  <p>
                    I am 21 years of age or older, and I am not in the NDRP or
                    among the banned personalities as stated under the
                    Memorandum Circular No. 6, series of 2016 of the Office of
                    the President;
                  </p>
                  <p>
                    I have fully read and understood the Terms and Conditions of
                    my registration and playership and I agree and commit to
                    follow and obey them, as modified or amended;
                  </p>
                  <p>
                    The information and details I provided are true, correct and
                    authentic;
                  </p>
                  <p>
                    I agree and commit to abide by the existing laws, PAGCOR
                    issuances, rules and regulations in relation to my usage of
                    the services of Licensee;
                  </p>
                  <p>
                    I have fully read and understood the Licensee's Privacy
                    Notice, and I freely and voluntarily give my consent and
                    permission for the collection, use and processing of my
                    personal information. I have been fully apprised that
                    withdrawal of this consent will result in the deactivation
                    of my account;
                  </p>
                  <p>
                    I hereby agree and commit to complete the Licensee's “Know
                    Your Customer” requirements before my first cash
                    out/withdrawal, or within five (5) days from my
                    registration, whichever comes earlier;
                  </p>
                  <p>
                    I acknowledge that by using third-party/ies, such as GCash,
                    etc., when I do transactions through Licensee's RGP, I agree
                    and allow Licensee to use all information I have provided in
                    the course of using the Licensee's services;
                  </p>
                  <p>
                    I am fully aware that deposits, withdrawals and/or transfers
                    from and to a payment/banking channel other than my
                    registered account may be grounds for the suspension,
                    termination of my account, subject to further investigation
                    by Licensee.
                  </p>
                </div>
              </FormGroup>
            </div>
            {/* <div style={{ fontSize: '0.8rem', color: '#FFFFFF98', textAlign: 'right', paddingRight: 5, }}>
              Please scroll to bottom to accept
            </div> */}
            <div className='nomargin'>
              <StyledButtonContainer>
                {/* <Button
                  type='button'
                  color='brand2'
                  className='signupbtn'
                  onClick={() => handleDecline()}
                >
                  Decline
                </Button> */}
                <Button
                  type='submit'
                  color='brand2'
                  className='loginbtn'
                  // disabled={!enableTerms ? true : false}
                >
                  {loading ? <ButtonSpinner /> : null}Accept
                </Button>
              </StyledButtonContainer>
            </div>
          </form>
        </>
      )}
      {decline && (
        <>
          <StyledBrowserClose>
            <BrowserCloseIconSvg />
            <StyledTitle>
              <p>
                In-order to access the website you must acknowledge the terms
                and conditions.
                <br /> <br /> Please{' '}
                <Link to='/' onClick={refresh}>
                  refresh
                </Link>{' '}
                browser to try again.
              </p>
            </StyledTitle>
          </StyledBrowserClose>
        </>
      )}
    </StyledWrap>
  );
};

export default TermsForm;
