import React from "react";
// import { StyledInfoBoxRight } from "../style";
import { StyledInfoBoxRight } from "../../../components/Custom/mainstyle"
import AccordionSection from "../../../components/Custom/AccordionSection";

import PageHead from "../components/PageHead";

const listDetails = [
    { title: "How do I place a bet on Arionplay.com?", desc: `<ol><li>Log in to your Arionplay account using your credentials.</li><li>Select the desired date for the event you wish to bet on.</li><li>Choose the specific event or game you are interested in betting on.</li><li>Review the available bets along with their respective odds.</li><li>Enter the amount you want to wager as your stake.</li><li>Click on the "Confirm" or "Place a Bet" button to finalize your bet.</li></ol>` },
    { title: "What are the various types of bets in horse racing?", desc: `<ol><li>Win: You bet on a horse to finish first in the race. If your chosen horse wins, you win the bet.</li><li>Place: You bet on a horse to finish in either first, second or third place. If your chosen horse comes in first, second or third, you win the bet.</li><li>SHP (Second Horse Pool): You pick a horse to finish in the second position in the race. If your selected horse comes in second, you win the bet.</li><li>THP (Third Horse Pool): You predict which horse will finish in the third position in the race. If your chosen horse finishes third, you win the bet.</li><li>Show: You bet on a horse to finish in the top three positions (first, second, or third). If your selected horse finishes in any of these positions, you win the bet.</li><li>Quinella: You choose two horses to finish in the top two positions, regardless of the order. If your chosen horses finish first and second, you win the bet.</li><li>Tanala: You choose three horses to finish in the exact order. If your chosen horses finish first second, third in exact order, you win the bet.</li><li>Exacta: You predict the first four horses in the exact order they finish. If your selected horses finish in the correct order in first, second, third and fourth, you win the bet.</li><li>Forecast: You predict the first two horses in the exact order they finish. If your selected horses finish in the correct order, you win the bet.</li><li>Treble: You select the winners of three selected races. All three selections must win for you to win the bet.</li><li>Mini Jackpot: Similar to the Jackpot, but you pick the winners of four selected races. If all your selections win, you win the Mini Jackpot.</li><li>Jackpot: You choose the winners of a five selected races. If all your selections win, you win the Jackpot.</li><li>Super Jackpot: You choose the winners of a six selected races. If all your selections win, you win the Super Jackpot.</li></ol>` },
    
];

const FaqSportsbetsEnquiries: React.FC = () => {
    return (
        <StyledInfoBoxRight>
            <PageHead title="Betting Enquiries" />
          <AccordionSection list={listDetails} />
        </StyledInfoBoxRight>
    );
};

export default FaqSportsbetsEnquiries;
