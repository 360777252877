import styled, { device, themeGet } from '@/shared/styled';

export const StyledWrap = styled.div`
  .row > * {
    padding-left: 10px;
    padding-right: 10px;
  }
  width: 100%;
  .dob-error {
    width: 100%;
    font-size: 11px;
    color: #ff7c54;
    text-align: right;
    position: absolute;
    bottom: -18px;
    right: 0px;
    text-align: right;
  }
  .datepicker.error input {
    border: 1px solid #ff7c54;
  }
  .date_close {
    background-color: ${themeGet('colors.mainborder')};
    width: 50px;
    color: #fff;
  }
  .dob_btn {
    display: block;
    width: 100%;
    height: 42px;
    padding: 6px 12px 6px 14px;
    font-size: 14px;
    font-family: ${themeGet('fonts.googleSansRegular')};
    color: #f0f0f0;
    background-color: ${themeGet('colors.forminputbg')};
    background-clip: padding-box;
    border: 1px solid #384766;
    border-radius: 6px 6px 6px 6px !important;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &[hidden] {
      display: none;
    }
  }
  .loginform {
    // border-radius: 16px;
    // background: rgba(255, 255, 255, 0.05);
    // padding: 16px 16px 16px 16px;
    // margin-top: 10px;
  }
  .no_padding {
    padding-right: 0px;
  }
  

  .signin_btn {
    background: ${themeGet('colors.mainborder')};
    display: flex;
    align-items: center;
    font-family: ${themeGet('fonts.googleSansBold')};
    font-size: 16px;
    gap: 4px;
    color: ${themeGet('colors.pagefontcolor')} !important;
    width: auto;
    min-width: 160px;
    margin-top: 35px;
    margin-right: 12px;
    height: 42px;
    border: none;
    padding: 0px 40px;
  }
  .btn-check:checked + .signin_btn.btn,
  :not(.btn-check) + .btn:active,
  .signin_btn.btn:first-child:active,
  .signin_btn.btn.active,
  .signin_btn.btn.show {
    background: ${themeGet('colors.mainborder')};
  }
  .row {
    margin-bottom: 16px;
  }
  .mobilenumber {
    padding-left: 52px;
  }
  

  .withdraw_btn {
    background: #FF0000;
    color:#FFF !important
  }

  .btn-check:checked + .withdraw_btn.btn,
  :not(.btn-check) + .btn:active,
  .withdraw_btn.btn:first-child:active,
  .withdraw_btn.btn.active,
  .withdraw_btn.btn.show {
    background: #FF0000;
  }



  .termsandcondition {
    height: 250px;
    overflow-y: auto;
    color: #fff;
    padding-right: 10px;
    ${device.TabToLg} {
      height: 350px;
    }


    p {
      font-size: 15px;
      color: #a4adc0;
      font-family: ${themeGet('fonts.googleSansRegular')};
      margin-bottom: 6px;
    }
    h5 {
      font-size: 15px;
      color: #a4adc0;
      font-family: ${themeGet('fonts.googleSansRegular')};
    }
    ol {
      padding-left: 25px;
      h4 {
        font-size: 16px;
        color: #a4adc0;
        font-family: ${themeGet('fonts.googleSansRegular')};
      }
      li {
        font-size: 14px;
        margin-bottom: 8px;
        color: #a4adc0;
        font-family: ${themeGet('fonts.googleSansRegular')};
      }
    }
  }
  .nomargin {
    margin-bottom: 0px;
  }
  form {
    display: flex;
    width: 100%;
    flex-direction: column;
    input {
      padding-left: 12px;
    }
    select {
      padding-left: 12px;
    }
  }

  .rmdp-input {
    width: 100%;
    height: 42px;
    padding: 6px 12px 6px 33px;
    font-size: 14px;
    font-family: ${themeGet('fonts.googleSansRegular')};
    color: #f0f0f0;
    background-color: ${themeGet('colors.forminputbg')};
    background-clip: padding-box;
    border: 1px solid ${themeGet('colors.largegamecardborder')};
    border-radius: 6px 6px 6px 6px !important;
    margin: 0px;
    &:focus {
      box-shadow: none;
      border-color: ${themeGet('colors.forminputfocusborder')};
    }
  }
  .rmdp-calendar {
    font-family: ${themeGet('fonts.googleSansMedium')};
  }
  .rmdp-header {
    .rmdp-arrow {
      position: relative;
      top: 2px;
    }
  }
  .margin_bottom {
    margin-bottom: 10px;
  }
  .mar_top {
    margin-top: 25px;
  }
  .loginform {
    .rmdp-container {
      width: 100%;
    }
    .rmdp-input {
      padding-left: 12px !important;
    }
  }
  .posswordicon {
    position: absolute;
    height: 42px;
    right: 0px;
    top: 0px;
    width: 30px;
    display: flex;
    justify-content: center;
    z-index: 99;
    button {
      padding: 0px;
      width: 30px;
      &:active {
        box-shadow: none;
        color: inherit;
        background-color: inherit;
        border-color: inherit;
        border: none;
      }
      &:focus {
        box-shadow: none;
        color: inherit;
        background-color: inherit;
        border-color: inherit;
        border: none;
      }
    }
    svg {
      path {
        fill: #6a7a9b;
      }
    }
  }
  .infopassword {
    margin-left: 6px;
    position: relative;
    top: -2px;
  }
  .passwodicon {
    width: 35px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .labeltext {
    position: relative;
  }
  .labelerror {
    width: 100%;
    //margin-top: 0.25rem;
    font-size: 11px;
    color: #ff7c54;
    position: absolute;
    top: 0px;
    left: 0px;
    text-align: left;
    padding-left: 20px;
  }

  ${device.TabToLg} {
    .row {
      margin-bottom: 0px;
      .col-md-6 {
        margin-bottom: 10px;
      }
    }
    .loginform label {
      font-size: 12px;
      line-height: 16px;
    }
    .signin_btn {
      margin-top: 20px;
    }
    .mar_top {
      margin-top: 10px;
    }
  }
  .custom-checkbox {
    cursor: pointer;
  }

  // input:-webkit-autofill {
  //   -webkit-box-shadow: 0 0 0 1000px #000 inset !important;
  // }
  // input:-webkit-autofill,
  // input:-webkit-autofill:hover,
  // input:-webkit-autofill:focus,
  // input:-webkit-autofill:active{
  //     -webkit-background-clip: text;
  //     -webkit-text-fill-color: #ffffff;
  //     transition: background-color 5000s ease-in-out 0s;
  //     box-shadow: inset 0 0 20px 20px #23232329;
  // }

  .react-datepicker-wrapper{
    width: 100%;
    .react-datepicker__input-container{
      input.inputdatepicker{
        display: block;
        width: 100%;
        height: 42px;
        padding: 6px 12px 6px 14px;
        font-size: 14px;
        font-family: ${themeGet('fonts.googleSansRegular')};
        color: #f0f0f0;
        background-color: ${themeGet('colors.forminputbg')};
        background-clip: padding-box;
        border: 1px solid #384766;
        border-radius: 6px 6px 6px 6px !important;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        outline: none;
      }
      input:focus{

      }
    }
  }
  .loginform{
    .react-datepicker__month-select{
      background-position: 95% 2px !important;
      background-color: #21283C !important;
      //background-image: none !important;
      padding-left: 10px !important;
      padding-right: 10px !important;
      border:1px solid #4D6173;
      color:${themeGet('colors.white')};
      width:120px;
      border-radius: 6px;
      height: 28px;
      font-size:13px;
      font-family: ${themeGet('fonts.googleSansMedium')};
      color:${themeGet('colors.eventnumberdefaulttxtcolor')};
    }
  }
  .loginform{
  .react-datepicker__year-select{
      background-position: 95% 2px !important;
      background-color: #21283C !important;
      //background-image: none !important;
      padding-left: 10px !important;
      padding-right: 10px !important;
      border:1px solid #4D6173;
      color:${themeGet('colors.white')};
      width:100px;
      border-radius: 6px;
      height: 28px;
      font-size:13px;
      font-family: ${themeGet('fonts.googleSansMedium')};
      color:${themeGet('colors.eventnumberdefaulttxtcolor')};

    }
  }
  .react-datepicker-popper {
    z-index: 999;
    font-family: ${themeGet('fonts.googleSansMedium')};
}
.react-datepicker__header{
  padding: 20px 0px 8px 0px;
  border-bottom: 1px solid #2C344B;
  background: #21283C;

}
.react-datepicker__header__dropdown.react-datepicker__header__dropdown--select{
  margin-bottom: 10px;
}
.react-datepicker__month-dropdown-container.react-datepicker__month-dropdown-container--select{
  margin: 0 8px;
}
.react-datepicker__navigation{
  top: 8px;
  display: none;
}

.registerdatapicker{
  react-datepicker__header__dropdown.react-datepicker__header__dropdown--select{

  }
  .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name{
    width: 30px;
    height: 30px;
    font-size: 14px;
    font-family: ${themeGet('fonts.googleSansMedium')};
    line-height: 30px;
    margin: 4px;
  }

  .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::after {
    border-bottom-color: ${themeGet('colors.forminputbg')};
  }
  .react-datepicker__day--disabled{
    // background:${themeGet('colors.tableheaderbg')};
    // border:1px solid #dedede;
    // border-radius: 100px;
  }
  .react-datepicker__day--outside-month{
    // background:${themeGet('colors.tableheaderbg')};
    // border:1px solid #dedede;
    // border-radius: 100px;
  }
  .react-datepicker{
    background:${themeGet('colors.forminputbg')};
    font-family: ${themeGet('fonts.googleSansMedium')};
    color:${themeGet('colors.eventnumberdefaulttxtcolor')};
    border:0px;
  }
  .react-datepicker__day-name{
    font-size: 10px;
    color: #768089 !important;
    font-family: ${themeGet('fonts.googleSansBold')};
    text-transform: uppercase;
  }
  .react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header{
    color:${themeGet('colors.eventnumberdefaulttxtcolor')} !important;
  }
  .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name{
    color:${themeGet('colors.eventnumberdefaulttxtcolor')} !important;
  }
  .react-datepicker__day--selected{
    background:${themeGet('colors.mainbackground')};
    color:${themeGet('colors.white')} !important;
    border-radius: 100px;
  }
  .react-datepicker__current-month {
    font-size: 12px;
    font-family: ${themeGet('fonts.googleSansBold')};
    margin-bottom: 10px;
    display: none;
  }
  .react-datepicker__day:hover, .react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover, .react-datepicker__year-text:hover {
    background:${themeGet('colors.mainbackground')};
    color:${themeGet('colors.white')} !important;
    border-radius: 100px;
  }
  .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected{
    background:${themeGet('colors.mainbackground')};
    color:${themeGet('colors.white')} !important;
    border-radius: 100px;
  }
  .react-datepicker__header{
    background:${themeGet('colors.forminputbg')};
    font-family: ${themeGet('fonts.googleSansMedium')};
    color:${themeGet('colors.eventnumberdefaulttxtcolor')};
    .react-datepicker__current-month{
      color:${themeGet('colors.eventnumberdefaulttxtcolor')} !important;
    }
  }
  // .react-datepicker__day--outside-month{
  //   background: #293554;
  // }
  .react-datepicker__day--outside-month {
    color: transparent !important;
    pointer-events: none;
    //display: none;
  }
  .react-datepicker__day-names, .react-datepicker__week{
    //display: flex;
  }
}
${device.MobToSmm}{

  .regesterform{
    padding: 0px 20px 20px 20px;
    background-color: #26334d;
    position: absolute;
    left: 0px;
  }
}

.error.input-group{
  .inputdatepicker{
    border-radius: 6px 6px 6px 6px !important;
    border: 1px solid #ff7c54 !important;
  }

}

.input-group input:autofill:hover, .input-group input:autofill:focus, .input-group input:autofill:active {
  background-clip: padding-box !important;
  //box-shadow: none !important;
  transition: inherit !important;
  background-color: ${themeGet('colors.forminputbg')} !important;
  background: ${themeGet('colors.forminputbg')} ;
  -webkit-text-fill-color: #fff;
    -webkit-box-shadow: rgb(28, 35, 53) 0px 0px 0px 1000px inset !important;
    transition: background-color 5000s ease-in-out 0s;

}
input:-internal-autofill-selected{}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  //border: 1px solid green;
  -webkit-text-fill-color: #fff;
  -webkit-box-shadow: rgb(28, 35, 53) 0px 0px 0px 1000px inset !important;
  transition: background-color 5000s ease-in-out 0s;
  background-color:rgba(0, 0, 0, 0) !important;
  background-color:rgb(0, 0, 0,) !important;
  box-shadow: rgb(28, 35, 53) 0px 0px 0px 1000px inset !important;
}
  input:-webkit-autofill,
    input:-webkit-autofill:focus {
    transition: background-color 0s 600000s, color 0s 600000s;
  }

`;

export const StyledTitle = styled.h3`
  color: ${themeGet('colors.eventnumberdefaulttxtcolor')};
  text-align: center;
  font-family: ${themeGet('fonts.googleSansMedium')};
  font-size: 24px;
  line-height: 24px;
  margin-bottom: 14px;
  margin-top:30px;
`;

export const StyledSubHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const StyledSubHeaderText = styled.div`
  p {
    font-size: 15px;
    color: ${themeGet('colors.eventnumberdefaulttxtcolor')};
    margin-bottom: 0px;
  }
`;

export const StyledSubHeadLogo = styled.div``;
export const StyledDesc = styled.p`
  font-size: 15px;
  text-align: center;
  color: ${themeGet('colors.eventnumberdefaulttxtcolor')};
  margin-bottom: 30px;
  font-family: ${themeGet('fonts.googleSansRegular')};
  ${device.TabToLg} {
    margin-bottom: 15px;
  }
`;

export const StyledSteps = styled.div`
  p {
    color: #7685a0;
    font-family: ${themeGet('fonts.googleSansRegular')};
    font-size: 15px;
    margin: 0px 0px 5px 0px !important;
    display: flex;
    align-items: center;
    gap: 4px;
    svg {
      position: relative;
      top: -2px;
      width: 8px;
      path {
        fill: ${themeGet('colors.ErrorLableText')};
      }
    }
  }
  h4 {
    color: ${themeGet('colors.eventnumberdefaulttxtcolor')};
    font-size: 24px;
    font-family: ${themeGet('fonts.googleSansMedium')};
    margin: 30px 0px 25px 0px;
    display: flex;
    align-items: center;
    span {
      color: ${themeGet('colors.mainborder')};
      font-family: ${themeGet('fonts.googleSansBold')};
    }
  }
  ${device.TabToLg} {
    p {
      //margin-bottom: 15px !important;
    }
  }
`;

export const StyledGcashText = styled.div`
  p {
    color:${themeGet('colors.mainborder')};
    font-family: ${themeGet('fonts.googleSansRegular')};
    font-size: 15px;
    margin: 0px 0px 25px 0px !important;
    display: flex;
    align-items: center;
    gap: 4px;
    svg {
      position: relative;
      top: -2px;
      width: 8px;
      path {
        fill: ${themeGet('colors.ErrorLableText')};
      }
    }
  }
  h4 {
    color: ${themeGet('colors.eventnumberdefaulttxtcolor')};
    font-size: 24px;
    font-family: ${themeGet('fonts.googleSansMedium')};
    margin: 30px 0px 25px 0px;
    display: flex;
    align-items: center;
    span {
      color: ${themeGet('colors.mainborder')};
      font-family: ${themeGet('fonts.googleSansBold')};
    }
  }
  ${device.TabToLg} {
    p {
      margin-bottom: 15px !important;
      font-size: 13px;
      line-height: 16px
    }
  }
`;



export const StyledAlertMessage = styled.div`
  // color: #f31415;
  // background-color: #f8d7da;
  // border-color: #f5c6cb;
  // position: relative;
  // padding: 5px 10px;
  // margin-bottom: 1rem;
  // border: 1px solid transparent;
  // border-radius: 0.25rem;
  // display: flex;
  // align-items: center;
  // gap: 4px;
  border-radius: 8px;
  border: 1px solid #F79009;
  background: rgba(255, 255, 255, 0.05);
  gap: 8px;
  padding: 8px;
  color:${themeGet('colors.eventnumberdefaulttxtcolor')};
  font-size:13px;
  font-family: ${themeGet('fonts.googleSansMedium')};
  margin-bottom: 14px;
  display: flex;
  align-items: center;
`;

export const StyledLabelWrap = styled.div`
  margin-bottom: 5px;
  justify-content: space-between;
  display: flex;
`;

export const StyledDivider = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin: 15px 0;
  color: #c2c9d6;
  &:after,
  &:before {
    content: '';
    display: block;
    flex: 1;
    height: 2px;
    background-color: ${themeGet('colors.tableheaderborder')};
  }
  &:before {
    margin-right: 10px;
  }
  &:after {
    margin-left: 10px;
  }
`;

export const StyledBottomText = styled.div`
  margin-top: 20px;
  text-align: center;
  font-size: 15px;
  color: #c2c9d6;
  border-top: 1px solid rgba(255, 255, 255, 0.15);
  padding-top: 24px;
  margin-top: 30px;
  margin-left: -40px;
  margin-right: -40px;
  ${device.MobToMd}{
    margin-left: -20px;
    margin-right: -20px;
  }
  a {
    color: ${themeGet('colors.homebannertxtcolor')};
    font-family: ${themeGet('fonts.googleSansBold')};
    font-size: 16px;
  }
`;

export const StyledForgotText = styled.div`
  text-align: center;
  padding: 5px 0px;
  a {
    background: none;
    color: ${themeGet('colors.mainborder')};
    font-family: ${themeGet('fonts.googleSansMedium')};
    font-size: 14px;
  }
`;
export const StyledPhoneText = styled.div`
  position: absolute;
  left: 8px;
  top: 11px;
  color: #f0f0f0;
  font-size: 13px;
  z-index: 99;
`;
export const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  padding: 0px;
  border-top: 1px solid;
  padding-top: 20px;
  margin-top: 5px;
  gap: 20px;

  .loginbtn {
    background: ${themeGet('colors.mainborder')};
    display: flex;
    align-items: center;
    font-family: ${themeGet('fonts.googleSansRegular')};
    font-size: 13px;
    border: 0px;
    gap: 4px;
    height: 34px;
    color: ${themeGet('colors.pagefontcolor')} !important;
    &:hover {
      background: ${themeGet('colors.mainborder')};
      font-size: 13px;
      border: 0px;
      height: 34px;
    }
    svg path {
      stroke: ${themeGet('colors.pagefontcolor')} !important;
    }
  }
  .signupbtn {
    background: none;
    border: 1px solid ${themeGet('colors.mainborder')};
    color: ${themeGet('colors.btnfontcolor')};
    display: flex;
    align-items: center;
    gap: 4px;
    height: 34px;
    font-size: 13px;
    font-family: ${themeGet('fonts.googleSansRegular')};
    &:hover {
      background: none;
      border: 1px solid ${themeGet('colors.mainborder')};
      color: ${themeGet('colors.btnfontcolor')};
      height: 34px;
      font-size: 13px;
    }
    &:active {
      background: none;
      border: 1px solid ${themeGet('colors.mainborder')};
      color: ${themeGet('colors.btnfontcolor')};
    }
  }
`;
export const StyledPersonalContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledCheckedText = styled.div`
  display: flex;
  p {
    color: #a4adc0;
    display: inline-block;
    font-size: 12px;
    line-height: 16px;
    a {
      color: ${themeGet('colors.btnfontcolor')};
    }
  }
`;

export const StyledContryCode = styled.div`
  width: 100%;
  display: flex;
  margin-right: 0px;

  .input-group-text {
    padding: 0.475rem 0.175rem;
  }
  select {
    padding-left: 12px !important;
  }
`;

export const StyledMobileCode = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  input {
    padding-left: 26px !important;
  }
`;

export const StyledMobileView = styled.div`
  display: flex;
`;

export const StyledInfoCircle = styled.div`
  display: block;
  position: absolute;
  min-width: 380px;
  word-break: break-all;
  top: -99px;
  left: -75px;
  z-index: 999;
  background: #ff0;
  height: 90px;
  font-size: 13px;
  line-height: 17px;
  padding: 10px 14px;
  border-radius: 10px;
  background: #4a5a76;
  color: ${themeGet('colors.white')};
  font-family: ${themeGet('fonts.googleSansRegular')};
  &::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: '';
    border-top: 8px solid #4a5a76;
    border-right: 8px solid transparent;
    border-bottom: 0;
    border-left: 8px solid transparent;
    color: #fff;
    position: absolute;
    bottom: -8px;
    left: 74px;
  }
`;

export const StyledReferalView = styled.div`
display: flex;
flex-direction: column;
width: 340px;
margin: 0px auto;
.agentcode{
  font-size: 12px;
  font-family: ${themeGet('fonts.googleSansBold')};
}
.loginform.regesterform{
  font-family: ${themeGet('fonts.googleSansMedium')};
  font-size: 14px;
  color: #A4ADC0;
  letter-spacing: 0.042px;
  line-height: 20px;
  margin-bottom: 5px;
  display: flex;
}
`;