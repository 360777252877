import {
  FormGroup,
  Label,
  Input,
  Button,
  ButtonSpinner,
  Select,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from '@/components';
import { useForm, SubmitHandler } from 'react-hook-form';
import { Link } from 'react-router-dom';
import React, { useRef, useState } from 'react';
import { Grid } from '@mui/material/';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import { hasKey } from '@/shared/methods';
import AuthService from '@/services/AuthService';
import { updateForgotData } from '@/redux/slices/forgotpassword';
import ReCAPTCHA from 'react-google-recaptcha';
import Stack from '@mui/material/Stack';
// import {
//   StyledWrap,
//   StyledTitle,
//   StyledDesc,
//   StyledBottomText,
//   StyledPhoneText,
//   StyledAlertMessage,
//   StyledPersonalContainer,
//   StyledInfoCircle,
// } from '@/pages/auth/style';

import {
  StyledWrap,
  StyledTitle,
  StyledDesc,
  StyledBottomText,
  StyledPhoneText,
  StyledAlertMessage,
  StyledPersonalContainer,
  StyledInfoCircle,
} from './style';
import {
  GlobalIconSvg,
  MobileSvg,
  PasswordSvg,
  AlertWarningiconSvg,
  InfocircleIconSvg,
  EyeIconSvg,
  EyeHideIconSvg,
} from '@/shared/svg';

interface ChildProps {
  changeSteps: any;
}
const generateRandomCaptcha = () => {
  return Math.floor(1000 + Math.random() * 9000).toString(); // Generates a number between 1000 and 9999
};
const ForgotForm = (props: ChildProps) => {
  const dispatch = useAppDispatch();
  const [passwordType, setPasswordType] = useState('password');
  const [cpasswordType, setCpasswordType] = useState('password');
  const [message, setMessage] = useState('');
  const [isNotValid, setIsNotValid] = useState(false);
  const [passwordHint, setPasswordHint] = useState(false);
  const [loading, setLoading] = useState(false);
  const [captchaMessage, setCaptchaMessage] = useState('');
  const [isCaptchaDone, setIsCaptchaDone] = useState(false);
  // Set the initial captchaText state to a random 4-digit number

  const [userCaptcha, setUserCaptcha] = useState('');
  const recaptchaRef = useRef(null);

  // const { isLoading, isError, errorMessage } = useAppSelector(
  //   state => state.auth
  // );
  //const { forgotFormData } = useAppSelector(state => state.forgototp);
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<ForgotForm>();

  const onSubmit: SubmitHandler<ForgotForm> = async data => {
    // setCaptchaMessage('');
    // if (!isCaptchaDone) {
    //   setCaptchaMessage('Please verify captcha');
    //   return;
    // }
    setLoading(true);
    data.phone = `${data.country}${data.phone}`;
    const userInfo = { ...data };
    const response = await AuthService.getForgotOTP(userInfo);
    // recaptchaRef.current.reset();
    // setCaptchaMessage('');
    // setUserCaptcha('');
    // setIsCaptchaDone(false);
    if (response.status) {
      data.token = response.data.token;
      dispatch(updateForgotData(data));
      props.changeSteps('forgototp');
    } else {
      setLoading(false);
      setIsNotValid(true);
      setMessage(response.data);
    }
  };
  const showPassword = () => {
    if (passwordType === 'password') {
      setPasswordType('text');
      return;
    }
    setPasswordType('password');
  };

  const showCPassword = () => {
    if (cpasswordType === 'password') {
      setCpasswordType('text');
      return;
    }
    setCpasswordType('password');
  };

  const openInfoPopup = async () => {
    props.changeSteps('login');
  };
  const handleCaptchaImageGenerated = (url: string) => {
    console.log('Captcha image generated:', url); // For debugging
  };
  // Function to handle the generated CAPTCHA image
  const handleCaptcha = (token: string) => {
    setIsCaptchaDone(true);
    setUserCaptcha(token);
    setCaptchaMessage('');
  };

  return (
    <StyledWrap className='signinview'>
      <StyledTitle>Forgot Password</StyledTitle>
      <StyledDesc>
        Enter your phone number and we ll send a OTP to your mobile
      </StyledDesc>
      <form
        className='loginform'
        action='#'
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        {isNotValid ? (
          <StyledAlertMessage>
            <AlertWarningiconSvg /> {message}
          </StyledAlertMessage>
        ) : null}
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1 }}
          style={{ paddingBottom: '5px' }}
        >
          <Grid item xs={4} className='no_padding mobilecode'>
            <Label display='block' mb='5px' htmlFor='email'>
              Code
            </Label>
            <InputGroup>
              <InputGroupAddon dir='prepend'>
                <InputGroupText>
                  <GlobalIconSvg />
                </InputGroupText>
              </InputGroupAddon>
              <Select
                data-testid='countrycode'
                id='countrycode'
                name='countrycode'
                className='select'
                {...register('country')}
              >
                <option value='+63'>PH(+63)</option>
                <option value='+91'>IN(+91)</option>
                <option value='+44'>UK(+44)</option>
              </Select>
            </InputGroup>
          </Grid>
          <Grid item xs={8} className='no_padding mobilecode'>
            <FormGroup>
              <Label display='block' mb='5px' htmlFor='email'>
                Phone Number
              </Label>
              <InputGroup>
                <InputGroupAddon dir='prepend'>
                  <InputGroupText>
                    <MobileSvg />
                  </InputGroupText>
                </InputGroupAddon>
                <StyledPhoneText>(0)</StyledPhoneText>
                <Input
                  className='mobilenumber'
                  data-testid='phone'
                  type='tel'
                  id='phone'
                  name='phone'
                  placeholder='Phone Number'
                  feedbackText={errors?.phone?.message}
                  state={hasKey(errors, 'phone') ? 'error' : 'success'}
                  showState={!!hasKey(errors, 'phone')}
                  {...register('phone', {
                    required: 'Phone is required',
                    pattern: {
                      value: /^(?!(0))[0-9]{10}$/,
                      message: 'invalid phone format',
                    },
                  })}
                />
              </InputGroup>
            </FormGroup>
          </Grid>
        </Grid>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1 }}>
          <Grid item xs={12}>
            <Label display='block' mb='5px' htmlFor='email'>
              Password<span>*</span>{' '}
              <p className='infopassword'>
                <Link
                  to={''}
                  onMouseOver={() => setPasswordHint(true)}
                  onMouseLeave={() => setPasswordHint(false)}
                >
                  <InfocircleIconSvg />
                  {passwordHint && (
                    <StyledInfoCircle>
                      Password should be at least 8 characters long and must
                      <br />
                      contain atleast 1 uppercase letter, 1 lowercase letter,
                      <br />1 special character character (%$@!*&#) and 1 number
                    </StyledInfoCircle>
                  )}
                </Link>
              </p>
            </Label>
            <InputGroup>
              <InputGroupAddon dir='prepend'>
                <InputGroupText>
                  <PasswordSvg />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                id='password'
                type={passwordType}
                name='password'
                placeholder='Enter your Password'
                feedbackText={errors?.password?.message}
                state={hasKey(errors, 'password') ? 'error' : 'success'}
                showState={!!hasKey(errors, 'password')}
                {...register('password', {
                  required: 'Password is required',
                  pattern: {
                    value:
                      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/,
                    message:
                      'Use 8 or more characters-atleast 1 uppercase and 1 lowercase letters, 1 number & 1 symbol(@$!%*?&#)',
                  },
                })}
                onFocus={() => setPasswordHint(true)}
                onBlur={() => setPasswordHint(false)}
              />
              <InputGroupAddon className='posswordicon'>
                <div
                  className='passwodicon'
                  color='light'
                  onClick={() => showPassword()}
                >
                  {passwordType === 'password' && <EyeHideIconSvg />}
                  {passwordType === 'text' && <EyeIconSvg />}
                </div>
              </InputGroupAddon>
            </InputGroup>
          </Grid>
          <Grid item xs={12}>
            <FormGroup>
              <Label display='block' mb='5px' htmlFor='email'>
                Confirm Password<span>*</span>
              </Label>
              <InputGroup>
                <InputGroupAddon dir='prepend'>
                  <InputGroupText>
                    <PasswordSvg />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  id='confirm_pass'
                  type={cpasswordType}
                  name='confirm_pass'
                  placeholder='Enter your Confirm Password'
                  feedbackText={errors?.confirm_pass?.message}
                  state={hasKey(errors, 'confirm_pass') ? 'error' : 'success'}
                  showState={!!hasKey(errors, 'confirm_pass')}
                  {...register('confirm_pass', {
                    required: 'Confirm Password is required',
                    validate: {
                      matchesPreviousPassword: value => {
                        const { password } = getValues();
                        return password === value || 'Passwords should match!';
                      },
                    },
                  })}
                />
                <InputGroupAddon className='posswordicon'>
                  <div
                    className='passwodicon'
                    color='light'
                    onClick={() => showCPassword()}
                  >
                    {cpasswordType === 'password' && <EyeHideIconSvg />}
                    {cpasswordType === 'text' && <EyeIconSvg />}
                  </div>
                </InputGroupAddon>
              </InputGroup>
            </FormGroup>
          </Grid>

          {/* <Grid item xs={12} sx={{ marginTop: '20px', marginBottom: '20px' }}>
            <ReCAPTCHA
              sitekey='6Lc_CWQqAAAAAHbsSmSBA9j-8p09voH1rLhXaxDl'
              ref={recaptchaRef}
              onChange={handleCaptcha}
            />
            <Stack
              sx={{
                // position: 'absolute',
                bottom: '-5px',
                color: '#ff7c54',
                fontSize: '11px',
              }}
            >
              {captchaMessage}
            </Stack>
          </Grid> */}
        </Grid>
        <StyledPersonalContainer>
          <Button
            type='submit'
            color='brand2'
            className='signin_btn'
            data-testid='submit'
          >
            {loading ? <ButtonSpinner /> : null}Set Password
          </Button>
        </StyledPersonalContainer>
        <StyledBottomText>
          Don&apos;t have an account?{' '}
          <Link className='flex' to={''} onClick={() => openInfoPopup()}>
            Sign In
          </Link>{' '}
        </StyledBottomText>
      </form>
    </StyledWrap>
  );
};

export default ForgotForm;
