import React from "react";
// import { StyledInfoBoxRight } from "../style";
import { StyledInfoBoxRight } from "../../../components/Custom/mainstyle"
import AccordionSection from "../../../components/Custom/AccordionSection";

const listDetails = [
    { title: "Getting Started", desc: "Get to know FairPlay" },
    { title: "Account Enquiries", desc: "Learn about account creation, KYC verification, and password reset" },
    { title: "Deposits, Withdrawals", desc: "Get information about deposit and withdrawal processes and parameters" },
    { title: "Betting Enquiries", desc: "Learn how to place bets" },
    { title: "Account Assistance &<br /> Controls", desc: "Read about other account-related concerns" },
    { title: "Trust & Safety", desc: "Check out other security-related queries" },
];

const FaqRtggamesInquiries: React.FC = () => {
    return (
        <StyledInfoBoxRight>
          <AccordionSection list={listDetails} />
        </StyledInfoBoxRight>
    );
};

export default FaqRtggamesInquiries;
