import React from "react";
// import { StyledInfoBoxRight } from "../style";
import { StyledInfoBoxRight } from "../../../components/Custom/mainstyle"
import AccordionSection from "../../../components/Custom/AccordionSection";
import PageHead from "../components/PageHead";

const listDetails = [
    { title: `What is ${process.env.REACT_APP_SITE_TITLE}`, desc: `${process.env.REACT_APP_SITE_TITLE} is a PAGCOR-licensed online betting platform that offers Sports Betting, eGames, eBingo, Specialty Games, and more. It features a user-friendly interface and is accessible via website, making it easy for players to place bets anytime, anywhere. ` },
    { title: `What services does ${process.env.REACT_APP_SITE_TITLE} provide?`, desc: "Play with confidence and ease as you select from a wide range of PAGCOR-approved games. Just top up your ArionPlay Dev wallet, place your bets, and test your luck! The platform also features easy player withdrawals so you can enjoy your winnings. Have fun!" },
    { title: "How can I contact customer support?", desc: `Easily get in touch with our dedicated customer support team through two convenient methods. You can use the contact form on our website or reach out to us directly via email at ${process.env.REACT_APP_SUPPORT_EMAIL} We are always here to assist you and address any inquiries you may have.` },
];

const FaqGettingStarted: React.FC = () => {
    return (
        <StyledInfoBoxRight>
            <PageHead title="Getting Started" />
          <AccordionSection list={listDetails} />
        </StyledInfoBoxRight>
    );
};

export default FaqGettingStarted;
