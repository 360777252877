import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import useMediaQuery from '@mui/material/useMediaQuery';
//
import {
  BannerTwitterSvg,
  BannerFacebookSvg,
  BannerTelegramSvg,
  BannerYouTubeSvg,
  BannerInstagramSvg,
  BannerAnchorSvg,
  BannerSpotifySvg,
  EGamesViewSvg,
  ArrowRightSvg,
  RealTimeGamesSvg,
  ComingSoonSvg,
  WEGamesSvg,
  EvolutionGamesSvg,
  WEGamesViewSvg,
  RealTimeGamViewsSvg,
  TripleCrownViewSvg,
  SpecialgamesSvg,
  MatchRacingSvg,
} from '@/shared/svg';
import {
  StyledContainerFluid,
  StyledBannerContainer,
  // StyledLargeGameContainer,
  StyledBannerImg,
  StyledBannerAdd,
  StyledBannerText,
  StyledBannerAddTitle,
  StyledBannerAddSocialIcons,
  StyledBannerFotter,
  StyledBannerLogo,
  // StyledLoader,
  StyledViewAll,
  StyledGameList,
  StyledComingSoon,
  StyledComingSoonGame,
  StyledTwoGameCard,
  StyledTwoGameList,
  StyledTripleView,
  StyledLargeGameList,
  StyledEvlGameList,
  StyledEvlView,
} from './style';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import { changeModule } from '@/redux/slices/module';
import GameCard from '@/components/RTGgamecard/card';
import ComingSoongamecard from '@/components/ComingSoongamecard';
import TwoGameCard from '@/components/2Ggamecard';
import TripleCrown from '@/components/TripleCrown';
import RTGGameCard from '@/components/RTGgamecard';
import EVLGameCard from '@/components/EVLgamecard';
import RacingService from '@/services/RacingService';
import ComingSoonPopUp from '@/components/ComingSoonPopUp';
import GameList from '@/components/GameList';
import GamesCard from '@/components/GamesCard';
import Page from '@/components/Page';

// Models
import GameModel from '../../@types/models/GameModel';
// Service
import { hasAgeConsent } from '@/services/AuthService';

import { useAppSelector } from '@/hooks/useAppSelector';

type Props = {
  gameList: null | Array<GameModel>;
};

const Home = ({ gameList }: Props) => {
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down('md'));

  const dispatch = useAppDispatch();
  const [raceEventList, SetRaceEventList] = useState(null);
  const [categoryTabIndex, setCategoryTabIndex] = React.useState(0);

  const { raceCardEventChange } = useAppSelector(state => state.raceinfo);

  const ageVerifyPopup = async () => {
    if (!hasAgeConsent()) {
      dispatch(changeModule('deafultterms'));
    }
  };

  const getRaceEventList = async () => {
    const response = await RacingService.getRaceEventList();
    const sortEvents = response.data.sort((x: any, y: any) => {
      return x.mtp - y.mtp;
    });
    SetRaceEventList(sortEvents);
  };

  useEffect(() => {
    ageVerifyPopup();
    getRaceEventList();
  }, []);

  useEffect(() => {
    console.log('RACE CARD EVENT');
    getRaceEventList();
  }, [raceCardEventChange]);

  // ----

  //filter ans sort by desc order
  const groupByCategory = (gameList || [])
    .filter((game: any) => game.displayOrder > 0)
    .sort((x: any, y: any) => {
      return x.displayOrder - y.displayOrder;
    })
    .reduce((group: any, game: any) => {
      const { providerId } = game;
      group[providerId] = group[providerId] ?? [];
      game.image = 'thumbnail_320x320.jpg';
      game.infoUrl = `${process.env.REACT_APP_GAME_S3_PATH}${game.providerId}/${game.id}/factsheet_eng.pdf`;
      group[providerId].push(game);
      return group;
    }, {});

  return (
    <div className=''>
      {raceEventList != undefined && raceEventList.length > 0 && false && (
        <StyledLargeGameList>
          <h5>
            <MatchRacingSvg />
            Triple Crown
          </h5>
          <StyledTripleView>
            <Stack direction='row' flexWrap='wrap' spacing={0} sx={{ gap: 3 }}>
              {raceEventList &&
                raceEventList.map((game: any) => (
                  //<LargeGameCard {...game} key={game.id} />
                  <TripleCrown
                    desc={''}
                    subtitle={''}
                    {...game}
                    key={game.id}
                  />
                ))}
              <StyledViewAll>
                <Link to='/game/triplecrownview'>
                  <TripleCrownViewSvg />
                  <p>
                    View all <ArrowRightSvg />{' '}
                  </p>
                </Link>
              </StyledViewAll>
            </Stack>
          </StyledTripleView>
        </StyledLargeGameList>
      )}
      <Box>
        {groupByCategory &&
          ['jili', 'pragmatic', 'evo', 'rtg', 'we'].map((providerId: string) =>
            groupByCategory[providerId] ? (
              <GameList
                key={providerId}
                providerId={providerId}
                games={groupByCategory[providerId].slice(0, 11)}
                displayViewAllButton={true}
              />
            ) : null
          )}
      </Box>

      {/* <Box sx={{ marginTop: 2 }}>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12} md={6}>
            <GamesCard
              title="Favorite Live Games"
              games={
                groupByCategory ?
                  [...(groupByCategory['evo'] || []), ...(groupByCategory['we'] || [])].filter((gameModel: GameModel) => ['LIVE', 'LIVE-DEALER'].includes(gameModel.category)).slice(0, 10):
                  null
              }
            />
          </Grid>
          <Grid item={true} xs={12} md={6}>
            <GamesCard
              title="Favorite Slot Games"
              games={
                groupByCategory ?
                  (groupByCategory['rtg'] || []).reverse().slice(0, 10):
                  null
              }
            />
          </Grid>
        </Grid>
      </Box> */}

      <StyledComingSoon>
        <h5>
          <ComingSoonSvg />
          Coming Soon
        </h5>
        <StyledComingSoonGame>
          {[
            {
              id: '9000e686-6983-42cd-b46b-5f8cfb92b8f7',
              image: 'habanero-mobilebanner.png',
              name: 'Baccarat',
              infoUrl: 'baccarat-factsheet.pdf',
            },
            {
              id: 'evolution_gaming__crazytime0000002',
              image: 'sagamingbanner.png',
              name: 'Crazy Time',
              infoUrl: 'crazy-time-factsheet.pdf',
            },
            {
              id: 'evolution_gaming__crazytime0000002',
              image: 'aruzebanner.png',
              name: 'Crazy Time',
              infoUrl: 'crazy-time-factsheet.pdf',
            },
            {
              id: 'evolution_gaming__crazytime0000002',
              image: 'jillibanner.png',
              name: 'Crazy Time',
              infoUrl: 'crazy-time-factsheet.pdf',
            },
          ].map(game => (
            <ComingSoongamecard {...game} key={game.id} />
          ))}
        </StyledComingSoonGame>
      </StyledComingSoon>
    </div>
  );
};

export default Home;
