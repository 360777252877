import React, { useState, useEffect, useMemo } from 'react';
import moment from 'moment/moment';
import { Link } from 'react-router-dom';
// Mui
import Container from '@mui/material/Container';
import AuthService from '@/services/AuthService';
import { useAppSelector } from '@/hooks/useAppSelector';
import Page from '@/components/Page';
import MUIDataTable from 'mui-datatables';
import { StyledDatatable, StyledHeaderView } from '@/pages/pagestyles';

import HeaderTitle from '@/components/Custom/HeaderTitle';

const CurrencyFormatPH = new Intl.NumberFormat('en-PH', {
  style: 'currency',
  currency: 'PHP',
});

const formatCurrency = (amount: number | bigint) => {
  // Check if the amount is not empty or undefined
  if (amount || amount === 0) {
    return CurrencyFormatPH.format(amount);
  } else {
    // Return a placeholder or an empty string, depending on your preference
    return '-'; // Placeholder text
    // return ''; // Empty string
  }
};


const columns = [
  { name: 'ticketId', label: 'Ticket Id' },
  {
    name: 'updatedAt', label: 'Time', options: {
      customBodyRender: (value: string) => moment(value).format(
        'DD/MM/YYYY HH:mm:ss'
      )
    }
  },
  { name: 'providerName', label: 'Partner' },
  { name: 'gameName', label: 'Game Name' },
  { name: 'operatorVenueName', label: 'Venue' },
  { name: 'operatorEventName', label: 'Event' },
  { name: 'roundId', label: 'Round Id' },
  { name: 'gameCategory', label: 'Game Category' },
  { name: 'betOn', label: 'Bet On' },
  { name: 'odd', label: 'Odds at Close Bet' },
  { name: 'amount', label: 'Bet' , options: {
    customBodyRender: (value: number | bigint) => formatCurrency(value),
  } },
  {
    name: 'wonAmount', label: 'Winnings', options: {
      customBodyRender: (value: number) => value > 0 ? '+ ' + formatCurrency(value) : formatCurrency(value)
    }
  },
  { name: 'balance', label: 'Balance', options: {
    customBodyRender: (value: number | bigint) => formatCurrency(value),
  }  },
  { name: 'status', label: 'Status' },
  { name: 'betResult', label: 'Result' }
  
];

const Bettinghistory = () => {
  const [walletId, setWalletId] = useState('');
  const { userInfo } = useAppSelector(state => state.auth);
  const [bettingHistoryData, setBettingHistoryData] = useState([]);
  const [walletRecords, setWalletRecords] = useState<Array<IWallet>>([]);

  const getBettingHistoryData = async () => {
    const response = await AuthService.getBettingHistoryData();
    const result = await AuthService.getMyWallets();
    setBettingHistoryData(response.data);
    setWalletRecords(result.data);
  };

  const currentTableData = useMemo(() => {
    let filtered2 = bettingHistoryData;
    if (walletId !== '')
      filtered2 = bettingHistoryData.filter(
        (item: any) => item.currency === walletId
      );
    return filtered2;

  }, [bettingHistoryData, walletId]);

  const filterWallet = async (e: any) => {
    let filterVal = '';
    if (e.target.value != '')
      filterVal = (e.target.value == 'REAL' ? 'PHP' : 'PST');
    setWalletId(filterVal);
  };

  useEffect(() => {
    if (userInfo) getBettingHistoryData();
  }, [userInfo]);

  return (
    <Page title="Betting History" favicon={process.env.REACT_APP_THEME === 'fairplay' ? '/icons/partners/fairplay/favicon.ico' : null}>
      <Container maxWidth={'lg'}>
        <StyledHeaderView>
        <HeaderTitle title="Betting History" />
        <select className='accounttype' onChange={e => filterWallet(e)}>
          <option value="">All</option>
          {walletRecords.map((item: any, index: number) => {
            return (
              <option value={item.type}>
                <Link to={'#'} key={index} className='depositbtn'>
                  {item.name} ({item.currency})
                </Link>
              </option>
            );
          })}
        </select>
        </StyledHeaderView>
          <StyledDatatable className='myactivitytable'>
            {currentTableData && <MUIDataTable
              title={"Displaying your latest account activity."}
              data={currentTableData}
              columns={columns}
              options={{
                filterType: 'checkbox',
                print: false,
                confirmFilters: false,
                filter: false,
                search: false,
                viewColumns: false,
                selectableRows: 'none',
                download: false,
              }}
            />}
          </StyledDatatable>
      </Container>
    </Page>
  );
};

export default Bettinghistory;
