import styled, { device, themeGet } from '@/shared/styled';

export const StyledHeaderView = styled.div`
align-items: center;
display: flex;
gap: 20px;

`;

export const StyledDatatable = styled.div`

table{
  thead{
    tr{
        th{
          background: #1b1f25;
          border-top: 1px solid #2a2d32;
          padding: 10px 5px;
          font-size: 13px;
          font-family: ${themeGet('fonts.googleSansMedium')};
          button{
            padding:0px;
            font-size: 13px;
            font-family: ${themeGet('fonts.googleSansMedium')};
            font-weight:inherit;
            color: #768089;
            margin:0px;
            white-space: nowrap;
          &:hover{
            background:none !important;
          }
          }
        }
    }
  }
  tbody{
    tr{
       td{
        font-size: 13px;
        font-family: ${themeGet('fonts.googleSansMedium')};
        padding: 10px 5px;
        color: #768089;
       }

       ${device.MobToMd} {
        td{
          div{
            font-size: 13px;
            font-family: ${themeGet('fonts.googleSansMedium')};
            color: #768089;
          }
          border-bottom:0px;
          padding: 5px 0px;
        }
        
          border-bottom: 2px solid rgba(46, 50, 54, 1);
       
       }
    }

  }
}
.css-175hwst-MuiPaper-root{
  background:none !important;
}
  border: 1px solid #2c3340;
  border-radius: 6px;
  display: flex;
  width: 100%;
  flex-direction: column;
  h6{
    font-size: 13px;
    color: #ffffff;
    font-family: ${themeGet('fonts.googleSansBold')};
    ${device.MobToMd} {
      text-align: left;
    }
  }
  p{
    font-size: 12px;
  }
  ${device.mdToLg} {
  }
  ${device.MobToMd} {
    flex-direction: column;
    border-radius: 0px;
    border:0px;
  }

  .accounttype{
    border-radius: 20px;
    border: 1px solid #616972;
    color: #B7B9BB;
    font-size: 12px;
    font-family: ${themeGet('fonts.googleSansMedium')};
    padding: 0px 14px;
    background:none;
    width: auto;
    height: 32px;
  }
`;

