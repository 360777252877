import React from "react";
// import { StyledInfoBoxRight } from "../style";
import { StyledInfoBoxRight } from "../../../components/Custom/mainstyle"
import AccordionSection from "../../../components/Custom/AccordionSection";

import PageHead from "../components/PageHead";

const listDetails = [
    { title: "How do I register for an account?", desc: `<h4>To create an account with Arionplay, simply follow these steps:</h4>
      <ul><li>Locate the ""Sign Up"" button situated in the top right corner of the screen.</li><li>Fill in your Gcash registered mobile number and birthdate.</li><li>Establish a strong and secure password for your account.</li><li>Click ""Create Account""</li></ul>
    ` },
    { title: "What should I do if I forget my account password?", desc: `<h4>In case you forget your login password, don't worry! Just follow these simple steps to retrieve it:</h4>
    <ul><li>Head to the login screen and click on the ""Forgot Password"" button.</li><li>Provide mobile number associated with your www.arionplay.com account.</li><li>Set up a new password and confirm."</li></ul>` },
    { title: "What information do I need to provide during registration?", desc: `<h4>During the registration process, you will be required to provide essential information to create your account. The specific details may vary depending on the platform or service, but typically, you will need to provide the following:</h4><ul><li>Locate the ""Sign Up"" button situated in the top right corner of the screen.</li><li>Fill in your Gcash registered mobile number and birthdate.</li><li>Establish a strong and secure password for your account.</li><li>Click ""Create Account""</li></ul>` },
    
];

const FaqsDetails: React.FC = () => {
    return (
        <StyledInfoBoxRight>
            <PageHead title="Account Enquiries" />
          <AccordionSection list={listDetails} />
        </StyledInfoBoxRight>
    );
};

export default FaqsDetails;
