import React, { useEffect, useState } from 'react';
import {
  StyledVideoContainer,
  StyledVideoView,
  StyledVideoViewUpdate,
} from './style';
import Iframe from '@/components/iframe';
import VideoJsPlayer from '@/components/player';
import RacingService from '@/services/RacingService';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import { useAppSelector } from '@/hooks/useAppSelector';
import { setRaceStreamData } from '@/redux/slices/racecard';

interface childProps {
  venueId:string;
  status?:string;
}
const RaceVideo = (props:childProps) => {
  const dispatch = useAppDispatch();
  const { raceStreamData } = useAppSelector(
    state => state.raceinfo
  );
  const [streamObj, setStreamObj] = useState<IStream>({message:'',iframeFlag:'',player:'',streamUrl:'',isPaid:false});
  const [player,setPlayer] = useState({fill: true,
    fluid: true,
    autoplay: true,
    controls: true,
    preload: "metadata",
    sources: [
      {
        src: "https://v3.szjal.cn/20191101/PZzNpqB1/index.m3u8",
        type: "application/x-mpegURL"
      }
    ]});
  const getStreamDetails = async() =>{
      setStreamObj({message:'',iframeFlag:'',player:'',streamUrl:'',isPaid:false});
      const response = await RacingService.getStreamData(props.venueId);
      if(response.body && response.body.data){
          const data = response.body.data;
          console.log(data);
          if(data.provider === 'SIS'){
            const result = await RacingService.getSISStreamUrl(data.streamId);
            console.log(result);
            data.iframeFlag = 'N';
            if(result.body.data.error!==undefined && result.body.data.error){
              data.message = result.body.data.error;
            } else{
              data.message = '';
              data.iframeFlag = 'Y';
              data.streamUrl = result.body.data.phenixEmbedUrl;
            }
          }
          console.log(data);
          dispatch(setRaceStreamData(data)); 
      } 
  }
  useEffect(()=>{
    if(props.venueId)
      getStreamDetails();
  },[props.venueId])



  useEffect(()=>{
    if(raceStreamData){
      console.log("RACE STREAM CHANGE====",raceStreamData);
      if(raceStreamData.iframeFlag === 'N' && raceStreamData.player === 'videojs'){
        const source = {sources:[{src:raceStreamData.streamUrl,type: "application/x-mpegURL"}]};
        setPlayer({ ...player, ...source });
        dispatch(setRaceStreamData(raceStreamData));
      } 
      setStreamObj(raceStreamData);
    }
  },[raceStreamData])
  return (
    <>
      <StyledVideoContainer>
        <StyledVideoView>
          {/* <p>Live from Turffontein (SA) - 02 May 2023 10:45:23</p> */}
          <div className='videoview'>
            {
              (props.status!=='FINAL' && streamObj.iframeFlag === 'Y' && (streamObj.message === undefined || streamObj.message === '')) ? (
                  <Iframe streamUrl={streamObj.streamUrl} />
              ):(props.status!=='FINAL' && streamObj.iframeFlag === 'N' && streamObj.player === 'videojs')?(
                  <VideoJsPlayer {...player} />
              ):(props.status!=='FINAL' && streamObj.message!=='')?(
                <div className='messageview'>{streamObj.message}</div>
              ):(props.status!=='FINAL' && streamObj.message==='')?(
                <div className='messageview'>LIVE STREAM WILL BE AVAILABLE WHEN THE HORSES ARE AT THE POST</div>
              ):(<div className='messageview'>RACE FINISHED</div>)
            }
          </div>
        </StyledVideoView>
        {/* <StyledVideoViewUpdate>
          <p>Updates</p>
          <span title=''>Horse 11 - COUNTRY S VERSE has been scratched</span>
        </StyledVideoViewUpdate> */}
      </StyledVideoContainer>
    </>
  );
};

export default RaceVideo;
