import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
// Mui
import { useTheme, styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import IconButton from '@mui/material/IconButton';
import Fab from '@mui/material/Fab';
import ButtonGroup from '@mui/material/ButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import Divider from '@mui/material/Divider';

import { RaceCardBetType } from '../api/RaceCardApi';


// Icon
// import RestartAltIcon from '@mui/icons-material/RestartAlt';
// import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
// Components
import Page from '@/components/Page';
// import BorderCard from '@/components/BorderCard';
// SVG
import { EvolutionGamesSvg, RealTimeGamesSvg, WEGamesSvg } from '@/shared/svg';
import { StyledBetButton } from './style';

// TODO move this to MUI-Theme
const themeColor = process.env.REACT_APP_THEME === 'fairplay' ? '#2F594D' : '#384766';

// This must be fix on a global state when header is added.
const ScreenDiv = styled('div')(({ theme }) => ({
  position: 'relative',
  height: '100%',
  display: 'flex',
  flexDirection: 'column-reverse',
  justifyContent: 'flex-start',
  alignItems: 'center',
  margin: 0,
  padding: '0px 0px 8px 0px',

  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',

  [theme.breakpoints.up('md')]: {
    marginTop: 80,
  },
  marginTop: 0,
}));

const BetButton = styled(ButtonBase)(({ theme }) => ({
  display: 'block',
  position: 'relative',
  width: '100%',
  height: '100%',
  borderRadius: 5,
  padding: 8,
  [theme.breakpoints.up('md')]: {
    padding: '1rem',
  },
  border: `1px solid ${theme.palette.action.disabledBackground}`,
}));

const OddWrapperDiv = styled('div')(({ theme }) => ({
  borderRadius: 18,
  border: '1.5px solid #FFFFFFCB',
  padding: 2,
  backgroundColor: '#00000021',
  minWidth: '5rem',
}));

const OddsDiv = styled('div')(({ theme }) => ({
  borderRadius: 16,
  border: '1px solid #FFFFFF78',
  padding: '0px 16px',
  background: 'rgba(0, 0, 0, 0.20)',
  textShadow:'none',
  fontFamily:'Pragati Narrow',
  fontSize:'16px',
  fontWeight:'700',
  
}));

const StyledDivider = styled(Typography)(({ theme }) => ({
  backgroundColor: 'white',
  width: '100%'
}));

const betCardTransparencyFactor = 'C8';
const redGradient = ['BE1C31', 'EC3F56'];
const blueGradient = ['228817', '42B935'];
const greenGradient = ['031DF8', '3046FF'];

export const BET_ODD = 'ODD';
export const BET_EVEN = 'EVEN';

type Props = {
  type: RaceCardBetType,
  odds: null | undefined | number,
  betAmount: null | undefined | number,
  onClick: (type: RaceCardBetType) => void,
  disabled?: boolean,
};

const HorseBetButton = ({ type, odds, betAmount, onClick, disabled }: Props) => {
  const { module } = useParams();
  let gradient;
  let label;
  let backgroundImage;
  let backgroundImage1;
  let backgroundColor;
  let backgroundColor1;
  switch (type) {
    case BET_ODD:
      gradient = redGradient;
      label = 'X';
      backgroundImage = 'url("/icons/xo-logo-bg.png")';
      backgroundImage1 = 'url("/icons/X-Icon.svg")';
      backgroundColor = '#957c52';
      backgroundColor1 = `linear-gradient(90deg, #BE1C31 0%, #EC3F56 22.4%, #EC3F56 77.08%, #BE1C31 100%)`;
      break;
    case BET_EVEN:
      gradient = greenGradient;
      label = 'O';
      backgroundImage = 'url("/icons/ox-logo-bg.png")';
      backgroundImage1 = 'url("/icons/O-Icon.svg")';
      backgroundColor = '#726969';
      backgroundColor1 = `linear-gradient(90deg, #031DF8 0%, #3046FF 22.4%, #3046FF 77.08%, #031DF8 100%)`;
      break;
    default:
      gradient = ['#000', '#FFF'];
  };
  //background: `linear-gradient(90deg, #${gradient[0]}${betCardTransparencyFactor} 0%, #${gradient[1]}${betCardTransparencyFactor} 22.4%, #${gradient[1]}${betCardTransparencyFactor} 77.08%, #${gradient[0]}${betCardTransparencyFactor} 100%)`,

  return (
    /* <BetButton    className={`${module} ${label}`} */
    <StyledBetButton>
    <BetButton className={`tnt ${label}`}
      //style= {module == 'horseracing' ? {backgroundImage: backgroundImage,backgroundColor: backgroundColor,backgroundSize: '100% 100%'} : {backgroundImage: backgroundImage1,background: `linear-gradient(90deg, #${gradient[0]}${betCardTransparencyFactor} 0%, #${gradient[1]}${betCardTransparencyFactor} 22.4%, #${gradient[1]}${betCardTransparencyFactor} 77.08%, #${gradient[0]}${betCardTransparencyFactor} 100%)` }}
      //style= {module == 'horseracing' ? {backgroundImage: backgroundImage,backgroundColor: backgroundColor,backgroundSize: '100% 100%'} : {backgroundImage: backgroundImage1,background: backgroundColor1,backgroundSize: '100% 100%'}}
      onClick={() => onClick(type)}
      disabled={disabled}
    >
      <Stack
        direction="column"
        spacing={0}
        alignItems="center"
        justifyContent="center"
        style={{marginBottom: '1rem' }}
      >
        <Typography variant="h5" sx={{ fontFamily:'google_sansbold', }} className='xotext'>
          {label}
        </Typography>
        <Typography variant="caption" sx={{fontSize:'14px !important', fontFamily:'google_sansbold'}}>
          ({type})
        </Typography>
      </Stack>
      <Stack direction="column" spacing={1} alignItems="center" className='betamounttext'>
        <OddWrapperDiv>
          <OddsDiv>
            {odds || '-'}
          </OddsDiv>
        </OddWrapperDiv>
        <Box sx={{ width: '100%' }}>
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            justifyContent="space-between"
            width="100%"
          >
            <Typography variant="caption" component="h4">
              Bet
            </Typography>
            <Typography variant="caption" component="h6">
              {disabled ? 'Disabled' : (betAmount || '-')}
            </Typography>
          </Stack>
          <Divider />
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            justifyContent="space-between"
            flexWrap="wrap"
            width="100%"
            margin={0}
            
          >
            <Typography variant="caption" component="h4">
              Est. Payout
            </Typography>
            <Typography variant="caption" component="h6">
              {(!disabled && betAmount && odds) ? Number(Number(betAmount * odds).toFixed(2)) : '-'}
            </Typography>
          </Stack>
        </Box>
      </Stack>
    </BetButton>
    </StyledBetButton>
  );
};

export default HorseBetButton;
