import React, { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
// Lib
import moment from 'moment/moment';
// Mui
import Container from '@mui/material/Container';
//
import AuthService from '@/services/AuthService';
import { useAppSelector } from '@/hooks/useAppSelector';
import Page from '@/components/Page';
import { StyledDatatable, StyledHeaderView } from '@/pages/pagestyles';
import MUIDataTable from 'mui-datatables';
import HeaderTitle from '@/components/Custom/HeaderTitle';

const options = [
  {
    label: "10",
    value: 10,
  },
  {
    label: "20",
    value: 20,
  },
  {
    label: "30",
    value: 30,
  },
  {
    label: "40",
    value: 40,
  },
];

const CurrencyFormatPH = new Intl.NumberFormat('en-PH', {
  style: 'currency',
  currency: 'PHP',
});

const formatCurrency = (amount: number | bigint) => {
  // Check if the amount is not empty or undefined
  if (amount || amount === 0) {
    return CurrencyFormatPH.format(amount);
  } else {
    // Return a placeholder or an empty string, depending on your preference
    return '-'; // Placeholder text
    // return ''; // Empty string
  }
};

const columns = [
  {
    name: 'id', label: 'Trans ID', options: {
      customBodyRender: (value: string) => value.slice(-8)
    }
  },
  {
    name: 'createdAt', label: 'Time', options: {
      customBodyRender: (value: string) => moment(value).format(
        'DD/MM/YYYY HH:mm:ss'
      )
    }
  },
  { name: 'gameName', label: 'Game Name' },
  { name: 'type', label: 'Type' },
  { name: 'operatorVenueName', label: 'Venue' },
  { name: 'operatorEventName', label: 'Event' },
  { name: 'operatorBetType', label: 'Bet On' },
  { name: 'status', label: 'Status' },
  { name: 'queuePreviousBalance', label: 'Balance Before', options: {
    customBodyRender: (value: number | bigint) => formatCurrency(value),
  } },
  { name: 'odd', label: 'Odds at Close Bet' },
  { name: 'income', label: 'Income', options: {
    customBodyRender: (value: number | bigint) => formatCurrency(value),
  } },
  { name: 'expenses', label: 'Expenses', options: {
    customBodyRender: (value: number | bigint) => formatCurrency(value),
  } },
  { name: 'balance', label: 'Balance After', options: {
    customBodyRender: (value: number | bigint) => formatCurrency(value),
  }},
  { name: 'remark', label: 'Remark' },
  { name: 'betResult', label: 'Result' }
];

const Mywallet = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [walletId, setWalletId] = useState('');
  const { userInfo } = useAppSelector(state => state.auth);
  const { selectedUserWallet } = useAppSelector(state => state.wallet);
  const [walletRecords, setWalletRecords] = useState<Array<IWallet>>([]);
  const [walletData, setWalletData] = useState([]);
  const [currentTotalRecords, setCurrentTotalRecords] = useState(0);
  const getMyWalletData = async () => {
    const response = await AuthService.getMyWalletData();
    setWalletData(response.data);
    getUserWalletInfo();
  };
  const getUserWalletInfo = async () => {
    const response = await AuthService.getMyWallets();
    setWalletRecords(response.data);
  };

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * perPage;
    const lastPageIndex = firstPageIndex + perPage;

    let filtered2 = walletData;
    if (walletId !== '')
      filtered2 = walletData.filter(
        (item: any) => item.currency === walletId
      );
    setCurrentTotalRecords(filtered2.length);
    return filtered2.slice(firstPageIndex, lastPageIndex);
  }, [walletData, currentPage, perPage, walletId]);

  const handleChange = async (e: any) => {
    setPerPage(e.target.value);
  }

  const filterWallet = async (e: any) => {
    let filterVal = '';
    if (e.target.value != '')
      filterVal = (e.target.value == 'REAL' ? 'PHP' : 'PST');
    setWalletId(filterVal);
  };

  const cellTransactionPreviousBalance = (model: any) =>
    (model.balance || 0) - ((model.income || 0) - (model.expenses || 0));

  useEffect(() => {
    if (userInfo && selectedUserWallet) getMyWalletData();
  }, [userInfo, selectedUserWallet]);
  return (
    <Page title="My Wallet" favicon={process.env.REACT_APP_THEME === 'fairplay' ? '/icons/partners/fairplay/favicon.ico' : null}>
      <Container maxWidth={'lg'}>
        <StyledHeaderView>
          <HeaderTitle title="My Wallet" />
          <select className='accounttype' onChange={e => filterWallet(e)}>
            <option value="">All</option>
            {walletRecords.map((item: any, index: number) => {
              return (
                <option value={item.type}>
                  <Link to={'#'} key={index} className='depositbtn'>
                    {item.name} ({item.currency})
                  </Link>
                </option>
              );
            })}

          </select>
        </StyledHeaderView>
        <StyledDatatable className='myactivitytable'>
          {currentTableData && <MUIDataTable
            title={"Displaying your latest account activity."}
            data={currentTableData}
            columns={columns}
            options={{
              filterType: 'checkbox',
              print: false,
              confirmFilters: false,
              filter: false,
              search: false,
              viewColumns: false,
              selectableRows: 'none',
              download: false,
            }}
          />}

        </StyledDatatable>
      </Container>
    </Page>
  );
};

export default Mywallet;