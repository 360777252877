import React from "react";
// import { StyledInfoBoxRight } from "../style";
import { StyledInfoBoxRight } from "../../../components/Custom/mainstyle"
import AccordionSection from "../../../components/Custom/AccordionSection";
import PageHead from "../components/PageHead";

const listDetails = [
    { title: "What is the process for depositing funds into my account?", desc: `<h4>ArionPlay Dev makes depositing funds a breeze. Just follow these simple steps:</h4><ul><li>Log in to your account using your mobile number and password.</li><li>Click the ""Deposit"" button on the top right corner of the page.</li><li>Enter the desired deposit amount.</li><li>You will be redirected to the Gcash page, confirm your deposit using your gcash account and complete the transaction successfully.</li><li>Upon successful completion, the amounts should automatically be credited into your ArionPlay Dev wallet.</li></ul>` },
    { title: "Is there a minimum deposit amount?", desc: "Yes, the minimum amount you can deposit is ₹100." },
    { title: "How do I withdraw my winnings?", desc: `<h4>Click on your wallet balance found on the top right corner of the page, and find the ""Withdraw"" section.</h4><ul><li>Confirm your Gcash mobile number. Note: You may only withdraw into your personal Gcash wallet. Withdrawal of funds to another person's Gcash account may cause for account suspension.</li><li>Enter the amount you wish to withdraw.</li><li>Click on the ""Continue"" button to submit.</li></ul> <p>Wait for a confirmation message to verify that your withdrawal request has been processed. Please note that processing times for withdrawals may vary depending on the correctness of the provided Gcash account number. If you encounter any issues or have questions during the process, feel free to contact our support team for assistance.</p>` },
    
];

const FaqDepositsWithdrawals: React.FC = () => {
    return (
        <StyledInfoBoxRight>
           <PageHead title="Deposits, Withdrawals" />
          <AccordionSection list={listDetails} />
        </StyledInfoBoxRight>
    );
};

export default FaqDepositsWithdrawals;
