import {
  FormGroup,
  Label,
  Input,
  Button,
  ButtonSpinner,
  InputGroup,
} from '@/components';
import { useForm, SubmitHandler } from 'react-hook-form';
import React, { useState } from 'react';
//import { useAppDispatch } from '@/hooks/useAppDispatch';
import { useAppSelector } from '@/hooks/useAppSelector';
import { hasKey } from '@/shared/methods';
import AuthService from '@/services/AuthService';

import {
  StyledWrap,
  StyledTitle,
  StyledDesc,
  StyledPhoneText,
  StyledAlertMessage,
  StyledAlertMessageSucess,
  StyledButtonContainer,
} from './style';
import { AlertWarningiconSvg, KycVerifiedIconSvg } from '@/shared/svg';
import { Grid } from '@mui/material';

interface ChildProps {
  parentModalHandlerFunction: any;
}

const RequestWithdrawForm = (props: ChildProps) => {
  //const dispatch = useAppDispatch();
  const [message, setMessage] = useState('');
  const [isNotValid, setIsNotValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const { userInfo } = useAppSelector(state => state.auth);
  const { selectedUserWallet } = useAppSelector(state => state.wallet);
  const [messagestatus, setMessageStatus] = useState('');

  console.log('Deposit', {
    userInfo,
    selectedUserWallet,
  });
  let selectedUserWalletId: string | null = null;
  if(selectedUserWallet) {
    selectedUserWalletId = selectedUserWallet.id;
  } else if (userInfo) {
    selectedUserWalletId = userInfo.selectedWalletId;
  }

  if (!selectedUserWalletId) {
    // This shall never happen!
    // TODO: Send fucking message to Dawid.
    alert('Apologies, we are maintain deposit function');
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IDepositForm>();

  const onSubmit: SubmitHandler<IDepositForm> = async data => {
    setLoading(true);
    var temp = {"userId":userInfo.id,"amount":data.amount}
    const response = await AuthService.manualWithdraw(temp);
    console.log(response);
    if (response.status) {
      setMessageStatus('error');
      setLoading(false);
      setIsNotValid(true);
      setMessage('Amount successfully requested');
    }
  };

  return (
    <StyledWrap>
      <StyledTitle>Request Withdraw</StyledTitle>
      <StyledDesc>
        Withdraw the amount below from your Wallet account.
      </StyledDesc>
      <form
        className='loginform'
        action='#'
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        {isNotValid ? (
          messagestatus == 'error' ? (
            <StyledAlertMessage>
              <AlertWarningiconSvg /> {message}
            </StyledAlertMessage>
          ) : (
            <StyledAlertMessageSucess>
              <KycVerifiedIconSvg /> {message}
            </StyledAlertMessageSucess>
          )
        ) : null}
        <div>
        <Grid container direction='column' md={12} className='no_padding'>
            <Label display='block' mb='5px' htmlFor='email'>
              Amount
            </Label>
            <FormGroup>
              <StyledPhoneText>&#8369;</StyledPhoneText>
              <InputGroup>
                <Input
                  className='mobilenumber'
                  type='text'
                  id='amount'
                  name='amount'
                  placeholder='Enter Amount'
                  feedbackText={errors?.amount?.message}
                  state={hasKey(errors, 'amount') ? 'error' : 'success'}
                  showState={!!hasKey(errors, 'amount')}
                  {...register('amount', { required: 'Amount is required' })}
                />
              </InputGroup>
            </FormGroup>
          </Grid>
        </div>
        <StyledButtonContainer>
          <Button type='submit' color='brand2' className='signin_btn'>
            {loading ? <ButtonSpinner /> : null}Continue
          </Button>
        </StyledButtonContainer>
      </form>
    </StyledWrap>
  );
};

export default RequestWithdrawForm;
