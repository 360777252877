import React from 'react';
// Mui
// import {
//   StyledPageRight,
//   StyledPageTextContainer,
//   StyledPageBanner,
//   StyledPageTextView,
// } from './style';
//
import {
  StyledPageRight,
  StyledPageTextContainer,
  StyledPageBanner,
  StyledPageTextViewTerms,
  StyledLocation,
} from '../../components/Custom/mainstyle';
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';

const Locationcontent = () => {
  return (
    <StyledPageRight>
      <Grid container spacing={{ xs: 0, md: 3 }}>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
          <StyledLocation>
            <Typography variant='h4' sx={{ fontSize: '16px !important' }}>
              MAYAMOT
            </Typography>
            <Typography sx={{ fontSize: '13px' }}>
              23 Sumulong Highway Mayamot Antipolo Rizal
            </Typography>
            <Typography sx={{ fontSize: '11px', fontWeight: '600' }}>
              1 PM - 5 AM
            </Typography>
          </StyledLocation>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
          <StyledLocation>
            <Typography variant='h4' sx={{ fontSize: '16px !important' }}>
              BAESA
            </Typography>
            <Typography sx={{ fontSize: '13px' }}>
              G/L Baesa Town Center Quirino Highway Baesa Quezon City
            </Typography>
            <Typography sx={{ fontSize: '11px', fontWeight: '600' }}>
              10AM - 4AM
            </Typography>
          </StyledLocation>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
          <StyledLocation>
            <Typography variant='h4' sx={{ fontSize: '16px !important' }}>
              PROMENADE
            </Typography>
            <Typography sx={{ fontSize: '13px' }}>
              Unit F&B U-2 Greenhills Promenade, Greenhills Shopping Center
            </Typography>
            <Typography sx={{ fontSize: '11px', fontWeight: '600' }}>
              11AM - 5AM
            </Typography>
          </StyledLocation>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
          <StyledLocation>
            <Typography variant='h4' sx={{ fontSize: '16px !important' }}>
              ETA DEL CARMEN
            </Typography>
            <Typography sx={{ fontSize: '12px' }}>
              Numancia Mall, P-5 Del Carmen (POB), del carmen, Surigao del Norte
            </Typography>
          </StyledLocation>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
          <StyledLocation>
            <Typography variant='h4' sx={{ fontSize: '16px !important' }}>
              DYNA CALYPSO
            </Typography>
            <Typography sx={{ fontSize: '12px' }}>
              Calypso Resort Hotel Compound, Nat'l Highway, Brgy. Macabling Sta
              Rosa City, Laguna
            </Typography>
            <Typography sx={{ fontSize: '11px', fontWeight: '600' }}>
              1PM - 7AM
            </Typography>
          </StyledLocation>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
          <StyledLocation>
            <Typography variant='h4' sx={{ fontSize: '16px !important' }}>
              SUNSHINE
            </Typography>
            <Typography sx={{ fontSize: '12px' }}>
              1328 Sunshine Boulevard Plaza, Quezon Ave., cor. Scout Santiago
              and Panay Ave. South Triangle District 4, QC
            </Typography>
            <Typography sx={{ fontSize: '11px', fontWeight: '600' }}>
              10AM - 6AM
            </Typography>
          </StyledLocation>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
          <StyledLocation>
            <Typography variant='h4' sx={{ fontSize: '16px !important' }}>
              ISETANN
            </Typography>
            <Typography sx={{ fontSize: '11px' }}>
              Isetann Supermarket Building-Mezzanine Level, Isetann Supermarket
              #295 P.Tuazon Blvd.,San Roque,Cubao Dist.3,QC
            </Typography>
            <Typography sx={{ fontSize: '11px', fontWeight: '600' }}>
              24/7
            </Typography>
          </StyledLocation>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
          <StyledLocation>
            <Typography variant='h4' sx={{ fontSize: '16px !important' }}>
              MORONG
            </Typography>
            <Typography sx={{ fontSize: '12px' }}>
              Morong Centrepoint, No. 058T. Claudio St., Brgy. San Juan Morong
              Rizal
            </Typography>
            <Typography sx={{ fontSize: '11px', fontWeight: '600' }}>
              1PM- 7AM
            </Typography>
          </StyledLocation>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
          <StyledLocation>
            <Typography variant='h4' sx={{ fontSize: '16px !important' }}>
              MANDAUE
            </Typography>
            <Typography sx={{ fontSize: '12px' }}>
              2nd Floor, Colonade Supermarket, B. Ceriza St. corner P. Gomez,
              Brgy. Centro, Mandate City
            </Typography>
          </StyledLocation>
        </Grid>
      </Grid>
    </StyledPageRight>
  );
};

export default Locationcontent;
