import * as React from 'react';
// Mui
import { useTheme, styled } from '@mui/material/styles';
import Popover from '@mui/material/Popover';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
// utils
import { fNumber } from '../utils/formatNumber';
// SVG
// import { GreenDotSVG } from '@/shared/svg';
// Api
import { RaceBetType } from '../api/RaceCardApi';

type Props = {
  raceBet: null | RaceBetType,
  handleConfirm: (raceBet: null | RaceBetType) => void,
  handleCancel: () => void,
};

export default function SportEventsMenu({ raceBet, handleConfirm, handleCancel }: Props) {
  const open = Boolean(raceBet);
  const theme = useTheme();

  return (
    <Popover
      aria-labelledby="racebet-confirm-popover"
      open={open}
      onClose={handleCancel}
      marginThreshold={48}
      // BackdropProps={{ invisible: false }}
      anchorOrigin={{vertical: 'center', horizontal: 'center'}}
      transformOrigin={{vertical: 'center', horizontal: 'center'}}
      PaperProps={{
        elevation: 2,
        sx: {
          padding: 1,
          minWidth: 260,
        },
      }}
    >
      <Typography variant="subtitle1" textAlign="center">
        Confirm Bet
      </Typography>
      {raceBet &&
        <Card elevation={1} sx={{ backgroundColor: '#FFFFFF31', margin: '1rem 0', padding: 1 }}>
          <Typography variant="subtitle1">
            {raceBet.venueName}
          </Typography>
          <Typography variant="subtitle1">
            {raceBet.eventName} - {raceBet.startTime}
          </Typography>
          <Typography variant="subtitle1">
            {raceBet.type} - {raceBet.type === 'ODD' ? 'X' : 'O'}
          </Typography>
          <Typography variant="subtitle1" sx={{ borderTop: '1px solid #FFFFFF69' }}>
            Bet amount: {raceBet.amount} {raceBet.currency}
          </Typography>
        </Card>
      }
      <Stack direction="row" justifyContent="space-between" spacing={1}>
        <Button variant="outlined" fullWidth={true} onClick={handleCancel}>
          Cancel
        </Button>
        <Button  variant="contained" fullWidth={true} onClick={() => handleConfirm(raceBet)}>
          Confirm
        </Button>
      </Stack>
    </Popover>
  );
}