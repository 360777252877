import styled, {
  device,
  SpaceProps,
  ColorProps,
  LayoutProps,
  themeGet,
} from '@/shared/styled';

export const StyledHeaderWrapper = styled.div`
  z-index: 20;
  top: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  position: fixed;
  //height: 100px;
  background-color: #121212;
  background: ${themeGet('colors.bodybackground')};
  border-bottom: 1px solid #ffffff35;
  ${device.MobToMd} {
    //height: 117px;
    position: sticky;
  }
  ${device.TabToLg}{
    position: sticky;
  }
`;

export const StyledHeaderContainer = styled.div`
  width: 100%;
  height: 58px;
  display: flex;
  padding: 10px 16px 10px 16px;
  position: relative;
  ${device.xlarge} {
    width: 1280px !important;
    float: inherit;
    margin: 0px auto;
  }
  ${device.MobToMd} {
    justify-content: space-between;
    //border-top:1px solid #ffffff35;
    padding: 12px 16px 12px 16px;
    //height: 46px;
  }
  ${device.MobToSd}{

  }

`;

export const StyledHeaderMenu = styled.div`
  padding:0px 16px 0px 0px;
  display:flex;
  //display: flex;
  align-items: center;
  position: relative;
  .menuicon{cursor: pointer;}
  ${device.MobToSd}{
    a{
      svg{
        // width:24px;
        // height:24px;
      }
    }
  }


  ${device.MobToMd} {
    padding:0px 8px 0px 0px;
  }
  .dropdown-menu{
    background: none;
    padding: 0px;
    margin-top: 0px;
    //left: -6px;
    box-shadow: none;
    border: none;
  }
`;

export const StyledMenuView =styled.div`
  box-shadow: 0px 0px 2px 2px rgba(255, 255, 255, 0.15);
  background: ${themeGet('colors.topheaderbackground')};
  border-radius: 10px;
  padding:16px 20px 20px 20px;
  position: absolute;
  width: 215px;
  //top:40px;
  transition: all 0.3s;
`;

export const StyledMenuHome = styled.div`
  display: flex;
  border-bottom:1px solid ${themeGet('colors.SideMenuBorder')};
  padding-bottom: 8px;
  a{
    padding: 8px 11px;
    font-size: 13px;
    color:${themeGet('colors.white')};
    font-family: ${themeGet('fonts.googleSansMedium')};
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
    span{
      position: relative;
      top: 2px;
    }
  }

`;

export const StyledMenuGames = styled.div`
display: flex;
border-bottom:1px solid ${themeGet('colors.SideMenuBorder')};
padding-bottom: 16px;
flex-direction: column;
ul{
  li{
    display: flex;
  }
}
h4{
  font-size: 10px;
  letter-spacing: 2.3px;
  color: #517095;
  font-family: ${themeGet('fonts.googleSansBold')};
  padding:16px 0px 10px 11px;
}
a{
  padding: 8px 11px;
  font-size: 13px;
  color:${themeGet('colors.white')};
  font-family: ${themeGet('fonts.googleSansMedium')};
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  span{
    position: relative;
    top: 2px;
  }
}

`;

export const StyledMenuPages = styled.div`
display: flex;
//border-bottom:1px solid ${themeGet('colors.SideMenuBorder')};
//padding-bottom: 16px;
padding-top: 16px;
flex-direction: column;
ul{
  li{
    display: flex;
  }
}
h4{
  font-size: 10px;
  letter-spacing: 2.3px;
  color: #517095;
  font-family: ${themeGet('fonts.googleSansBold')};
  padding:16px 0px 10px 11px;
}
a{
  padding: 8px 11px;
  font-size: 13px;
  color:${themeGet('colors.white')};
  font-family: ${themeGet('fonts.googleSansMedium')};
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  span{
    position: relative;
    top: 2px;
  }
}
`

export const StyledTopHeader = styled.div`
  height: 42px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  background: ${themeGet('colors.topheaderbackground')};
  &.homepage {
    display: none;
  }
  ul {
    display: flex;
    height: 40px;
    align-items: center;
    li {
      border-right: 1px solid rgba(255, 255, 255, 0.08);
      display: flex;
      height: 40px;
      align-items: center;
      a {
        color: ${themeGet('colors.copyrighttextcolor')};
        font-size: 13px;
        font-family: ${themeGet('fonts.googleSansMedium')} !important;
        padding: 0px 20px;
        height: 40px;
        display: flex;
        align-items: center;
        gap: 8px;
      }
    }
    li.active {
      background: rgba(0, 0, 0, 0.33); !important;
      svg {
        path {
          fill: ${themeGet('colors.mainborder')};
        }
      }
      span {
        color: ${themeGet('colors.mainborder')};
      }

      //background: ${themeGet('colors.mainborder')};
    }
  }
  ${device.MobToMd} {
    height: 38px;
    ul {
      height: 38px;
      li {
        //height: 40px;
        a {
          height: 38px;
          svg {
            height: 17px;
          }
        }
      }
    }
  }
`;
export const StyledTopContainer = styled.div`
  //height: 42px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 0px 0px 0px 0px;
  ui {
    li {
    }
   }
  a {
    color: ${themeGet('colors.copyrighttextcolor')};
    activ {
      svg {
        path {
          fill: ${themeGet('colors.mainborder')};
        }
      }
    }
  }
  p {
    gap: 8px;
    display: flex;
    align-items: center;
    padding-right: 16px;
  }
  ${device.xlarge} {
    width: 1280px !important;
    float: inherit;
    margin: 0px auto;
  }
  ${device.TabToLg} {
    ul {
      width: 100%;
      li {
        width: calc(100% / 5);
      }
    }
    padding: 0px;
    a p {
      display: none;
    }
    ul a {
      padding: inherit !important;
      width: 100%;
      align-content: center;
      justify-content: center;
      span {
        display: none;
      }
    }
  }
  span{
    padding-right: 16px;
  }
`;

export const StyledFlexContainer = styled.div`
  display: flex !important;
  //width:calc(100% / 3 - 1rem * 2);
  width: calc(100% - 850px);
  overflow: hidden;
  border-right: 1px solid rgb(76, 76, 76);
  flex-direction: column;
  padding-right: 16px;
  text-align: right;
  font-size: 13px;
  ${device.TabToLg} {
    border-right: 0px;
    display: none !important;
  }
`;

export const StyledFlexContainer1 = styled.div`
  //border-right: 1px solid #4c4c4c;
  display: flex;
  flex-direction: column;
  //padding-right: 16px;
  text-align: right;
  font-size: 13px;
  font-family: ${themeGet('fonts.googleSansMedium')};
  .marquee {
    font-size: 13px;
    font-family: ${themeGet('fonts.googleSansMedium')};
  }
`;

export const StyledWelcomeContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${device.TabToLg} {
    display: none !important;
  }
`;

export const StyledMarqueeContainer = styled.div`
  margin: 0px 0px;
  //width: 209px;
  display: flex;
  justify-content: end;
  color: ${themeGet('colors.copyrighttextcolor')};
  p{
    padding-right: 5px;
    width:100%;
  }
  svg {
    margin-top: 3px;
  }
  .marquee-container {
    //width: 195px;
    display: flex;
  }
`;

export const StyledParagraph = styled.p`
  color: #b5b5b5;
  font-size: 13px;
  line-height: 18px;
  //width: 209px;
  .js-clock{
    width: 140px;
    letter-spacing: .5px;
    display: inline-block;
    padding-right: 5px;
  }
`;

export const StyledLogoContainer = styled.div`
  display: flex;
  align-self: stretch;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1 1 auto;
  min-width: 50px;
  img {
    float: left;
    // margin-right: 7px;
  }
  .mobile_logo {
    display: none;
  }
  ${device.TabToLg} {
    .mobile_logo {
      display: block;
      width: 26px;
      img {
        width: 100%;
      }
    }
    .desk_logo {
      display: none;
    }
  }
  ${device.MobToMd} {
    justify-content: flex-start;
    flex: 0 1 auto;
    min-width: auto;
  }
  ${device.MobToSm}{
    .mobile_logo {
      width: 22px;
    }
  }

  ${device.MobToSd}{
    .mobile_logo {
      width: 22px;
    }
  }
`;

interface IProps extends SpaceProps, ColorProps, LayoutProps {}

export const StyledDotSpace = styled.div`
  background:rgba(76, 76, 76, 0.60);
  width: 5px;
  height: 5px;
  display: flex;
  margin-left: 8px;
  margin-right: 8px;
  border-radius: 6px

  ${device.MobToMd} {
    margin-left:6px;
    margin-right: 6px;
  }
`;
export const StyledPagcorLog = styled.div`

`;
export const StyledHeaderButtonGroup = styled.div<IProps>`
  // display: flex !important;
  // gap: 0.6rem;
  // align-items: center;
  // margin-left: 16px;
  // ${device.MobToSd}{
  //   margin-left: 10px;
  //   gap: 2px;
  // }
  // ${device.MobSLSm}{
  //   margin-left: 10px;
  //   gap: 5px;
  // }
  svg path {
    stroke: ${themeGet('colors.headerbtnbg')};
  }
  .loginbtn {
    background: ${themeGet('colors.mainborder')};
    display: flex;
    align-items: center;
    font-family: ${themeGet('fonts.googleSansBold')};
    font-size: 13px;
    border:0px;
    gap: 4px;
    height: 34px;
    min-width:85px;
    padding:0px 15px;
    color: ${themeGet('colors.pagefontcolor')} !important;
    &:hover{
      background: ${themeGet('colors.mainborder')};
      font-size: 13px;
      border:0px;
      height: 34px;
    }
    &:active {
      background: ${themeGet('colors.mainborder')};
      font-family: ${themeGet('fonts.googleSansBold')};
      svg path {
        stroke: ${themeGet('colors.pagefontcolor')} !important;
        background: none !important;
      }
    }
    &:focus {
      background: ${themeGet('colors.mainborder')};
      font-family: ${themeGet('fonts.googleSansBold')};
      svg path {
        stroke: ${themeGet('colors.pagefontcolor')} !important;
        background: none !important;
      }
    }
    svg {
      width: 16px;

      path {
        stroke: ${themeGet('colors.pagefontcolor')} !important;
      }
    }

    ${device.MobToSd}{
      padding: 0px 5px !important;

    }
  }
  .Deposit{
    svg path {
      fill: ${themeGet('colors.pagefontcolor')} !important;
      stroke: none !important;
    }
   }
  .signupbtn {
    background: none;
    border: 1px solid ${themeGet('colors.mainborder')};
    color: ${themeGet('colors.btnfontcolor')};
    display: flex;
    align-items: center;
    gap: 4px;
    height: 34px;
    min-width:95px;
    font-size: 13px;
    padding:0px 15px;
    font-family: ${themeGet('fonts.googleSansBold')};
    &:hover {
      background: ${themeGet('colors.mainborder')};
      color: ${themeGet('colors.pagefontcolor')} !important;
      font-family: ${themeGet('fonts.googleSansBold')};
      height: 34px;
      font-size: 13px;
      svg path {
        stroke: ${themeGet('colors.pagefontcolor')} !important;
        background: none !important;
      }
    }
    &:active {
      background: ${themeGet('colors.mainborder')} !important;
      color: ${themeGet('colors.pagefontcolor')} !important;
      border: 1px solid ${themeGet('colors.mainborder')};
      font-family: ${themeGet('fonts.googleSansBold')};
      svg path {
        stroke: ${themeGet('colors.pagefontcolor')} !important;
        background: none !important;
      }
    }
    &:focus {
      background: ${themeGet('colors.mainborder')} !important;
      color: ${themeGet('colors.pagefontcolor')} !important;
      border: 1px solid ${themeGet('colors.mainborder')};
      font-family: ${themeGet('fonts.googleSansBold')};
      svg path {
        stroke: ${themeGet('colors.pagefontcolor')} !important;
        background: none !important;
      }
    }
    svg path {
      stroke: ${themeGet('colors.btnfontcolor')} !important;
    }
  }
  .Deposit{min-width:99px;}
  svg:hover {
    background: none !important;
  }
  .withdrawview{
    display: flex;
    border-top:1px solid #31405A;
    justify-content: flex-start;
    padding-left: 10px
  }
 .loginwithdraw{
    background: none;
    margin:20px 0px 0px 0px;
    border: 1.5px solid ${themeGet('colors.mainborder')};
    color: ${themeGet('colors.btnfontcolor')};
    display: flex;
    align-items: center;
    gap: 4px;
    height: 31px;
    font-size: 13px;
    font-family: ${themeGet('fonts.googleSansBold')};
    padding:0px 16px;
    &:hover {
      background: none !important;
      border: 1.5px solid ${themeGet('colors.mainborder')} !important;
      color: ${themeGet('colors.btnfontcolor')};
      box-shadow:none !important;
      svg path {
        fill: ${themeGet('colors.mainborder')} !important;
        background: none !important;
      }
    }
    &:active {
      background: none;
      border: 1.5px solid ${themeGet('colors.mainborder')};
      color: ${themeGet('colors.btnfontcolor')};
      box-shadow:none !important;
      svg path {
        fill: ${themeGet('colors.mainborder')} !important;
        background: none !important;
      }
    }
    &:focus {
      background: none;
      border: 1.5px solid ${themeGet('colors.mainborder')};
      color: ${themeGet('colors.btnfontcolor')};
      box-shadow:none !important;
      svg path {
        fill: ${themeGet('colors.mainborder')} !important;
        background: none !important;
      }
    }
    svg path {
      fill: ${themeGet('colors.btnfontcolor')} !important;
    }
   }
  .dropdown-menu
     { left: inherit ! important;
       right: 0px;
       background:${themeGet('colors.topheaderbackground')};
       min-width: 200px;
       padding: 20px 20px;
       inset: 0px 0px auto auto;
       transform: translate3d(0px, 44px, 0px);
       transition: .3s all;
       box-shadow: 0px 0px 2px 2px rgba(255, 255, 255, 0.15);
       border-radius:10px;
       .btn:hover{
          background: none !important;
          border: 1px solid ${themeGet('colors.mainborder')};
          color: ${themeGet('colors.btnfontcolor')};
        }
        .profile.active{
          background: ${themeGet('colors.btnfontcolor')};
          border-radius: 5px;
          svg{
            path{
              stroke:${themeGet('colors.white')};
            }
          }
          &:hover{
            background: ${themeGet('colors.btnfontcolor')};
          }
        }
        a{
          color: ${themeGet('colors.white')};
          font-family: ${themeGet('fonts.googleSansMedium')} !important;
          font-size: 13px;
          text-decoration: none;
          padding: 7px 7px;
          display: flex;
          align-items: center;
          gap: 6px;
          text-transform: capitalize;
          span{
            position: relative;
            top: 2px;
          }

          }
        a:hover{
          background:${themeGet('colors.topheaderbackground')};
          color: ${themeGet('colors.white')} ! important;;
          }
        a:focus{outline: none;}
        .dropdown-item:focus{background:none !important; color:#fff !important;}
        .dropdown-item:active{background:none !important; color:#fff !important;}
      }
  }

  .dropdown-toggle
      { padding:0px;
        color: #B5B5B5;
        font-size: 14px;
        margin-right: 5px;
        line-height: 17px;
        display: flex;
        border:none !important;
        p{ margin-bottom: 0px;}
        &:hover
        {
          background:none;
          color: #fff;
          font-size: 14px;
          border:none !important;
        }
        &:active
        {
          background:none ! important;
          color: #fff ! important;
          font-size: 14px;
          border:none !important;
          box-shadow:none !important;
        }
        &:focus{
          background:none ! important;
          color: #fff ! important;
          font-size: 14px;
          border:none !important;
          box-shadow:none !important;
        }
        &:focus-visible{
          color: #fff ! important;
        }

      }
   .dropdown{
     button{
      margin-right: 0px;
      padding-right: 5px;s
     }
   .walletamountview{ display: flex;
    flex-direction: column; text-align: left; padding-left: 6px; padding-right: 5px;}
   .username_wallet{
    font-family: ${themeGet('fonts.googleSansBold')};
    font-size:13px;
    color:${themeGet('colors.copyrighttextcolor')};
    ${device.MobToSm}{
      font-size:12px;
    }
    ${device.MobToSd}{
      font-size:12px;
    }
   }
   .username_text{
    font-family: ${themeGet('fonts.googleSansMedium')};
    font-size:12px;
    margin-bottom:0px;
    color:${themeGet('colors.copyrighttextcolor')};
    width: 90px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-transform: capitalize;
      ${device.MobToSm}{
        width: 80px;
      }
      ${device.MobToSd}{
        width: 80px;
      }
   }
   }

   .depost_text{ padding-top:0px !important; padding-bottom:0px !important; padding-left:0px !important;  border:none !important; background: none;
    &:hover{ color: #ffffff; background-color:inherit !important;border-color:inherit !important; border:none; }
    &::after {
      display: inline-block;
      margin-left: 0.255em;
      vertical-align: 0.255em;
      content: "";
      border-top: 0.3em solid;
      border-right: 0.3em solid transparent;
      border-bottom: 0;
      border-left: 0.3em solid transparent;
      color: #fff;
    }
    &::active{
      color: #ffffff;
      background-color: none !important;
      border-color: none !important;
      box-shadow:none;
    }
  }
  .userloginbtn{ padding-left:0px !important; padding-right:0px !important;}
    ${device.TabToLg}{
      .Deposit{
        min-width: auto !important;
        padding: 0px 10px !important;
        span{display: none;}
      }
  }
.submenuview{
  ul{
    min-width: 200px;
    li{
      font-size: 13px;
      font-family: ${themeGet('fonts.googleSansMedium')};
    }
      a{
        font-size: 13px;
        font-family: ${themeGet('fonts.googleSansMedium')};
      }

  }
  ${device.TabToLg}{

     ul a{
      min-height: 30px;
    }
  }
}

`;

export const StyledLoginModalContainer = styled.div`
  display: flex !important;
  background: ${themeGet('colors.mainborder')}; 
`;

export const StyledKycDetails = styled.div`
  display: flex;
  align-self: stretch;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1 1 auto;
  min-width: 50px;
  ${device.MobToMd} {
    display: none;
  }
`;

export const StyledKycViewMobile = styled.div`
  display: none;
  ${device.MobToMd} {
    display: block;
    border-bottom: 1px solid #ffffff35;
    //padding: 6px 0px;
  }
`;
