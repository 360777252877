import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import {
  StyledFooter,
  StyledFooterSupport,
  StyledFooterMain,
  StyledFooterContainer,
  StyledFooterCopyRight,
  StyledFooterMenuListContainer,
  StyledFooterMenuWrapper,
  StyledFooterMenu,
  StyledFooterLogo,
  StyledFooterSocialMedia,
  StyledFooterPartnered,
} from './style';
import {
  TwitterSvg,
  InstagramSvg,
  YoutubeSvg,
  WhatsAppSvg,
  FaceBookSvg,
} from '@/shared/svg';
import CookieStorageService from '@/services/CookieStorageService';
import AuthService from '@/services/AuthService';
import { changeModule } from '@/redux/slices/module';

const Footer = () => {
  const dispatch = useAppDispatch();
  const history = useNavigate();
  const [selectedOption, setSelectedOption] = useState<String>();
  const selectChange = (val: any) => {
    setSelectedOption(val);
  };
  // const redirectToContact = async () => {
  //   selectChange('contactus')
  //   const token = await CookieStorageService.getItem(AuthService.getAuthKey());
  //   if (token === undefined || token == '') {
  //     dispatch(changeModule('login'));
  //     return false;
  //   }
  //   history(`contactus`);
  // };

  const selectMenu = async () => {
    const { pathname } = window.location;
    if (pathname.includes('terms')) setSelectedOption('terms');
    if (pathname.includes('betrules')) setSelectedOption('betrules');
    if (pathname.includes('responsiblegaming'))
      setSelectedOption('responsiblegaming');
    if (pathname.includes('privacypolicy')) setSelectedOption('privacypolicy');
    if (pathname.includes('aboutus')) setSelectedOption('aboutus');
    if (pathname.includes('faqs')) setSelectedOption('faqs');
    if (pathname.includes('contactus')) setSelectedOption('contactus');
  };

  useEffect(() => {
    selectMenu();
  }, []);

  return (
    <StyledFooter className='footer'>
      <StyledFooterSupport>
        <p>
          We greatly appreciate your feedback! The dedicated{' '}
          {process.env.REACT_APP_SITE_TITLE} team is available around the clock
          to assist you. Don&apos;t hesitate to contact us, and we assure you a
          prompt response.
        </p>

        <p>
          For any questions or queries email us at{' '}
          <Link to='mailto: {process.env.REACT_APP_SUPPORT_EMAIL}'>
            {process.env.REACT_APP_SUPPORT_EMAIL}
          </Link>
        </p>
        <p>For support, please contact +639613970012 or +639613651865.</p>
      </StyledFooterSupport>
      <StyledFooterMain>
        <StyledFooterContainer>
          <StyledFooterLogo>
            <Link aria-label='footer_logo' to='/'>
              <img
                height='40px'
                src={process.env.REACT_APP_THEME_LOGO}
                alt=''
              />
            </Link>
            <Link aria-label='footer_logo' to='/' className='nemologoview'>
              <img
                className='nemologo'
                src='/icons/partners/nemo-logo.png'
                alt=''
              />
            </Link>
          </StyledFooterLogo>
          <StyledFooterPartnered>
            <ul className='flex social_media'>
              <li>
                <Link
                  target='_new'
                  aria-label='pagcor-link'
                  to='https://www.pagcor.ph/index.php'
                >
                  <img src='/icons/partners/footer-gaming-logo.svg' alt='' />
                </Link>
              </li>
            </ul>
          </StyledFooterPartnered>
          <StyledFooterSocialMedia>
            <ul className='flex social_media' style={{ minWidth: 200 }}>
              {/* <li>
                <Link aria-label='twiter_link' to='/'>
                  <TwitterSvg />
                </Link>
              </li>
              <li>
                <Link aria-label='twiter_link' to='/'>
                  <InstagramSvg />
                </Link>
              </li>
              <li>
                <Link aria-label='twiter_link' to='/'>
                  <YoutubeSvg />
                </Link>
              </li>
              <li>
                <Link aria-label='twiter_link' to='/'>
                  <WhatsAppSvg />
                </Link>
              </li>
              <li>
                <Link aria-label='twiter_link' to='/'>
                  <FaceBookSvg />
                </Link>
              </li> */}
            </ul>
          </StyledFooterSocialMedia>
        </StyledFooterContainer>
      </StyledFooterMain>
      <StyledFooterMenu>
        <StyledFooterMenuWrapper>
          <StyledFooterMenuListContainer>
            <ul className='flex footer_menu'>
              <li>
                <Link
                  aria-label=''
                  to='/terms'
                  className={selectedOption === 'terms' ? 'active' : ''}
                  onClick={() => selectChange('terms')}
                >
                  Terms &amp; Conditions
                </Link>
              </li>
              <li>
                <Link
                  aria-label=''
                  to='/locations'
                  className={selectedOption === 'locations' ? 'active' : ''}
                  onClick={() => selectChange('locations')}
                >
                  Locations
                </Link>
              </li>
              <li>
                <Link
                  aria-label=''
                  to='/betrules'
                  className={selectedOption === 'betrules' ? 'active' : ''}
                  onClick={() => selectChange('betrules')}
                >
                  Bet Rules
                </Link>
              </li>
              <li>
                <Link
                  aria-label=''
                  to='/responsiblegaming'
                  className={
                    selectedOption === 'responsiblegaming' ? 'active' : ''
                  }
                  onClick={() => selectChange('responsiblegaming')}
                >
                  Responsible Gaming
                </Link>
              </li>
              <li>
                <Link
                  aria-label=''
                  to='/privacypolicy'
                  className={selectedOption === 'privacypolicy' ? 'active' : ''}
                  onClick={() => selectChange('privacypolicy')}
                >
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link
                  aria-label=''
                  to='/aboutus'
                  className={selectedOption === 'aboutus' ? 'active' : ''}
                  onClick={() => selectChange('aboutus')}
                >
                  About Us
                </Link>
              </li>
              <li>
                <Link
                  aria-label=''
                  to={'/contactus'}
                  className={selectedOption === 'contactus' ? 'active' : ''}
                >
                  Contact Us
                </Link>
              </li>
              <li>
                <Link
                  aria-label=''
                  to='/faqs'
                  className={selectedOption === 'faqs' ? 'active' : ''}
                  onClick={() => selectChange('faqs')}
                >
                  FAQs &amp; Help
                </Link>
              </li>
            </ul>
          </StyledFooterMenuListContainer>
          <StyledFooterCopyRight>
            <p>
              <span>&#169;</span> 2023 {process.env.REACT_APP_SITE_TITLE}. All
              Rights Reserved.
            </p>
            {/* <p className='version_text'>V.1.41</p> */}
          </StyledFooterCopyRight>
        </StyledFooterMenuWrapper>
      </StyledFooterMenu>
    </StyledFooter>
  );
};

export default Footer;
