import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
// Mui
import { useTheme, styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Container from '@mui/material/Container';
// Components
import Page from '@/components/Page';
import GameList from '@/components/GameList';
// Services
import GameService from '@/services/GameService';
// SVG
import { EvolutionGamesSvg, RealTimeGamesSvg, WEGamesSvg } from '@/shared/svg';

const GamesPage = () => {
  const theme = useTheme();
  const { providerId } = useParams();

  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down('md'));
  const [gameList, SetGameList] = useState(null);

  const getGamesList = async () => {
    const response = await GameService.getGamesForOperator(providerId);
    if (response.data.length > 0) {
      //filter ans sort by desc order
      
      // Filtered games based on providerId
      const filteredGames = response.data.filter((game: any) => game.providerId === providerId);

      // Separate games into two arrays: with displayOrder > 0 and without displayOrder or with displayOrder = 0
      const gamesWithDisplayOrder = filteredGames.filter((game: any) => game.displayOrder > 0);
      const gamesWithoutDisplayOrder = filteredGames.filter((game: any) => !game.displayOrder || game.displayOrder === 0);

      // Sort games with displayOrder > 0 by displayOrder in ascending order
      gamesWithDisplayOrder.sort((x: any, y: any) => x.displayOrder - y.displayOrder);

      // Combine sorted games with displayOrder > 0 and games without displayOrder
      const combinedGames = [...gamesWithDisplayOrder, ...gamesWithoutDisplayOrder];

      // Grouped games by providerId
      const groupedGames = combinedGames.reduce((group: any, game: any) => {
        const { providerId } = game;
        group[providerId] = group[providerId] ?? [];
        game.image = 'thumbnail_320x320.jpg';
        game.infoUrl = `${process.env.REACT_APP_CDN_IMAGES_PATH}/baccarat-factsheet.pdf`;
        group[providerId].push(game);
        return group;
      }, {});

      // Set the game list state with grouped games
      SetGameList(groupedGames);

    }
  };

  const getGameName = (provider: string) => {
    const gameObj: any = GameService.getGameInfo(provider);
    return gameObj.name;
  };

  useEffect(() => {
    getGamesList();
  }, [providerId]);

  return (
    <Page title="Games" favicon={process.env.REACT_APP_THEME === 'fairplay' ? '/icons/partners/fairplay/favicon.ico' : null}>
      <Container maxWidth={'lg'} sx={{ paddingTop: 2 }}>
        {gameList &&
          Object.entries(gameList).map((gameObjects: any, index: number) => (
            <GameList
              key={gameObjects[0]}
              providerId={gameObjects[0]}
              games={gameList[gameObjects[0]]}
              displaySearch={true}
            />
          ))
        }
      </Container>
    </Page>
  );
};

export default GamesPage;
