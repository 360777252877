import React, { useEffect, useState, useContext, useCallback } from 'react';
import { useParams } from 'react-router-dom';
// Libs
import { useSnackbar } from 'notistack';
// Mui
import { useTheme, styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import ButtonBase from '@mui/material/ButtonBase';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import IconButton from '@mui/material/IconButton';
import Fab from '@mui/material/Fab';
import ButtonGroup from '@mui/material/ButtonGroup';
import { updateModelAttributes } from '@/services/SocketModelUpdate';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
// Context
import UserContext, { UserContextType } from '../contexts/UserContext';
// Sockets
import Sockets from '../services/SocketService';
// Models
import BaseModel from '../@types/models/BaseModel';
import ToteModel from '../@types/models/ToteModel';
import UserModel from '../@types/models/UserModel';
// Components
import VideoPlayer from '../components/VideoPlayer';
import Page from '../components/Page';
import HorseBetButton, { BET_ODD, BET_EVEN } from '../components/HorseBetButton';
import HorsesPopover from '../components/HorsesPopover';
import SportEventsPopover from '../components/SportEventsPopover';
import SportEventsBetConfirmPopover from '../components/SportEventsBetConfirmPopover';
// SVG
import { ExpandIconSvg, CollapseIconSvg, ResetIconSvg, EvolutionGamesSvg, RealTimeGamesSvg, WEGamesSvg, DotSVG, ClosePopSVG, CrownPopSVG } from '../shared/svg';
// Api
import { apiGetCurrentUser } from '../api/UserApi';
import { apiGetSportEventModels, apiPlaceRaceCardBet, RaceEventType, RaceBetType, RaceCardBetType, apiGetTote } from '../api/RaceCardApi';
import RaceVideo from './betting/video';
import { KeyboardArrowDown } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';

// TODO move this to MUI-Theme
const themeColor = process.env.REACT_APP_THEME === 'fairplay' ? '#2F594D' : '#384766';
const MAX_AMOUNT = 50000;
const DEBUG_STREAM_SOURCE: null | string = null;
// We are refreshing the page just in case if sockets stop working.
const REFRESH_EVERY_SECONDS = 30;

const BorderCard = styled(Card)(({ theme }) => ({
  zIndex: 1,
  maxWidth: 660,
  flexShrink: 0,
  width: '100%',
  position: 'relative',
  overflow: 'hidden',
  margin: 8,
  padding: 5,
  marginTop: 4,
  backgroundColor: 'transparent',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  borderRadius: 8,
  border: `1px solid ${themeColor}`,
  background: `${theme.palette.background.default}A8`,
  backdropFilter: 'blur(2px)',
}));

// This must be fix on a global state when header is added.
const ScreenDiv = styled('div')(({ theme }) => ({
  position: 'relative',
  height: '100%',
  display: 'flex',
  flexDirection: 'column-reverse',
  justifyContent: 'flex-start',
  alignItems: 'center',
  margin: 0,
  padding: 0,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
}));

const StyledRaceInfoButtonBase = styled(ButtonBase)(({ theme }) => ({
  color: 'white',
  width: '100%',
  height: '100%',
  border: `1px solid rgba(255, 255, 255, 0.24)`,
  background: `${theme.palette.background.default}A8`,
  borderRadius: 8,
  padding: '0 8px',
  backdropFilter: 'blur(2px)',
}));

const StyledDividerDiv = styled('div')(({ theme }) => ({
  width: '100%',
  height:'1px',
  backgroundColor: '#FFFFFF29',
  borderRight: '1px solid #00000069',
  marginTop:'0px !important' ,
}));


const betCardTransparencyFactor = 'C8';

const GameOddEven = () => {
  const { module } = useParams();
  const amounts = ['100', '200', '300', '400'];
  const { userModel } = useContext<UserContextType>(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // ----------------------------------------------------------------------

  // State.
  // Anchors for popups.
  const [horsesAnchorEl, setHorsesAnchorEl] = useState<null | HTMLElement>(null);
  const [eventsAnchorEl, setEventsAnchorEl] = useState<null | HTMLElement>(null);
  // State represents the video full screen or adjusted screen.
  const [isExpandedVideoStream, setIsExpandedVideoStream] = useState<boolean>(false);
  // This state represent the refresh time.
  const [refreshTime, setRefreshTime] = useState(Date.now());
  // State to place a bet.
  const [betAmount, setBetAmount] = useState<string>(amounts[0]);
  const [stopBet, setStopBet] = useState(false);
  const [resultPopover, setResultPopover] = useState(false);
  
  const [placeRaceBet, setPlaceRaceBet] = useState<null | RaceBetType>(null);
  // Model, separately tote and events.
  const [toteModels, setToteModels] = useState<null | Array<ToteModel>>(null);
  const [sportEventModels, setSportEventModels] = useState<null | Array<RaceEventType>>(null);
  const [activeEventModel, setActiveEventModel] = useState<any>(null);
  const [stopEventModel, setStopEventModel] = useState<any>(null);
  // ----------------------------------------------------------------------
  // Logic related to the state.

  // Based on the current state we have logic that find the current event.
  // const activeEventModel = (
  //   sportEventModels && sportEventModels.find((event: RaceEventType): boolean => event.currentFlag === 'Y') || null
  // );
  // Then check if the event can be used to place a bet.
  // Sometimes events can has a current flag but not be ready to place a bet.
  const isActiveEventOpenToBet = activeEventModel && (activeEventModel.startBetFlag === 'Y' && activeEventModel.stopBetFlag !== 'Y' && activeEventModel.eventStatus !== 'FINAL' && !activeEventModel.eventResult);
  // Stream can be still active, but betting closed.
  const activeStreamSource = activeEventModel?.streamUrl || DEBUG_STREAM_SOURCE;
  // Select tote model related to active event.
  const selectedToteModel = (toteModels && activeEventModel && isActiveEventOpenToBet) ? toteModels.find((toteModel: ToteModel) => toteModel.id === activeEventModel.id) : null;

  console.log('current state', {
    activeEventModel,
    isActiveEventOpenToBet,
    activeStreamSource,
    selectedToteModel,
  });

  useEffect(() => {
    if (!activeEventModel && sportEventModels) {
      const currentEvent = (sportEventModels && sportEventModels.find((event: RaceEventType): boolean => event.stopBetFlag === 'N') || null);
      setActiveEventModel(currentEvent);
    }
  }, [sportEventModels]);

  const onChangeEvent = (event: any) => {
    setActiveEventModel(event);
  };
  // ----------------------------------------------------------------------
  // Functions.

  const updateToteModels = (toteModelData: ToteModel) => {
    if (toteModelData) {
      const newNoteModels = [...(toteModels || [])];
      let updateToteModelIndex = newNoteModels.findIndex((toteModel: ToteModel) => toteModel.id === toteModelData.id);
      if (updateToteModelIndex !== -1) {
        newNoteModels[updateToteModelIndex] = Object.assign(newNoteModels[updateToteModelIndex], toteModelData);
      } else {
        newNoteModels.push(toteModelData);
      }
      setToteModels(newNoteModels);
    }
  }

  const eventFromSocketListener = (socketModelData: BaseModel) => {
    console.log("SOCKETMODELDATA",socketModelData);
    if (socketModelData.typename === 'tote') {
      updateToteModels(socketModelData as ToteModel);
    } else if (socketModelData.typename === 'event' || socketModelData.typename === 'sportevent') {

      let updateSportEventModel = (sportEventModels || []).find((sportEventModel) => sportEventModel.id === socketModelData.id);
      if (updateSportEventModel) {
        const newEventModelArray = [...sportEventModels];
        updateSportEventModel = Object.assign(updateSportEventModel, socketModelData);   
       const sortEventsByTime =  newEventModelArray.sort((a: RaceEventType, b: RaceEventType) => a.startTime.localeCompare(b.startTime))     
      //  const currentEvent = (sortEventsByTime && sortEventsByTime.find((event: RaceEventType): boolean => event.stopBetFlag === 'N') || null);
      //  setActiveEventModel(currentEvent);
       setSportEventModels(sortEventsByTime);
        //setStopBet(true);
        //setResultPopover(true);
      }
      
          if(socketModelData?.eventStatus==='CLOSED'){
            setStopBet(true);
            setStopEventModel(updateSportEventModel);
          } 
     
      if(socketModelData.eventResult){
        setResultPopover(true);
        setStopEventModel(updateSportEventModel);
      }
    }
  }

  const fetchRaceCards = useCallback(async (useEnqueueSnackbar = true) => {
    const events = await apiGetSportEventModels(useEnqueueSnackbar ? enqueueSnackbar : null);
    if (events) {
      const sortEventsByTime =  events.sort((a: RaceEventType, b: RaceEventType) => a.startTime.localeCompare(b.startTime))     
      setSportEventModels(sortEventsByTime);
  
      // After a new card, fetch the tote.
      fetchTote();
    }
  }, [enqueueSnackbar]);

  const fetchTote = useCallback(async (useEnqueueSnackbar = true) => {
    if (activeEventModel) {
      const data = await apiGetTote(activeEventModel.id);
      updateToteModels(data);
    } else {
      console.log('fetchTote::activeEventModel is empty');
    }
  }, [activeEventModel, enqueueSnackbar]);

  const getDotSVG = (green: boolean) => (
    <span  style={{ color: green  ? '#66B15F' : '#FF6161', height: '18px' }}>
      <DotSVG />
    </span>
  );

  // ----------------------------------------------------------------------
  // Handlers.

  const handleOpenRaceCardsClick = (event: React.MouseEvent<HTMLElement>) => {
    setHorsesAnchorEl(event.currentTarget);
  };

  const handleCloseRaceCards = () => {
    setHorsesAnchorEl(null);
  };

  const handleOpenEventsClick = (event: React.MouseEvent<HTMLElement>) => {
    fetchRaceCards();
    setEventsAnchorEl(event.currentTarget);
  };

  const handleCloseEvents = () => {
    setEventsAnchorEl(null);
  };

  const handleReset = () => {
    setBetAmount(amounts[0]);
  };

  const handleInputAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const regex = /^[0-9\b]+$/;
    if (event.target.value === "" || regex.test(event.target.value)) {
      if (event.target.value === '') {
        setBetAmount(event.target.value);
      } else {
        let n = Number(event.target.value || '1')
        if (n < 1) n = 1;
        else if (n > MAX_AMOUNT) n = MAX_AMOUNT;
        setBetAmount(String(n));
      }
    }
  };

  const handleSendBet = async (raceBet: RaceBetType) => {
    console.log('handleSendBet', raceBet);
    setPlaceRaceBet(null);
    const response = await apiPlaceRaceCardBet(
      raceBet.programId,
      raceBet.eventId,
      raceBet,
      enqueueSnackbar
    );
    if (response && response.status == 'OK') {
      enqueueSnackbar(`${raceBet.amount} ${raceBet.currency} <${raceBet.type}> bet placed successfully!`, { variant: 'success' });
    }
  }

  const validatingBetAmount = (val: number) => {
    if(val < 100) {
      enqueueSnackbar('The bet amount must be a minimum of 100.', { variant: 'error' });
      return false;
    }
    if(val % 10 !== 0) {
      enqueueSnackbar('The bet amount must be a multiple of 10.', { variant: 'error' });
      return false;
    }
    return true;
  };

  const handlePlaceBet = async (raceCardBetType: RaceCardBetType, paramUserModel: null|UserModel = null) => {
    const selectedWalletId = (paramUserModel || userModel)?.selectedWalletId;
    if (selectedWalletId) {
      if (activeEventModel) {
        if(validatingBetAmount(Number(betAmount))) {
          const data: RaceBetType = {
            type: raceCardBetType,
            poolName: 'WIN',
            runners: raceCardBetType === BET_ODD ? 1 : 2,
            startTime: activeEventModel.startTime,
            //
            venueId: activeEventModel.venueId,
            venueName: activeEventModel.venueName,
            programId: activeEventModel.programId,
            eventId: activeEventModel.id,
            eventName: activeEventModel.name.replace('Race','Event'),
            //
            userId: selectedWalletId,
            sessionId: 'f01b3d72-ae49-41a0-b85f-678f78e14968',
            amount: Number(betAmount),
            currency: 'PHP',
          };
          setPlaceRaceBet(data);
        }
      } else {
        // Internal error that shall never happen.
        enqueueSnackbar('To place a bet event must be selected', { variant: 'error' });
      }
    } else {
      // Internal error that shall never happen.
      // enqueueSnackbar('User model is not available', { variant: 'error' });
      // ---
      // Fetch the user model first.
      console.log('User model is not available at this point of time, fetch');
      const fetchedUserModel = await apiGetCurrentUser();
      if (fetchedUserModel) {
        console.log('Try again place a bet');
        handlePlaceBet(raceCardBetType, fetchedUserModel);
      }
    }
  }

  // ----------------------------------------------------------------------
  // React.useEffect.

  useEffect(() => {
    // Refresh interval time.
    const interval = setInterval(() => setRefreshTime(Date.now()), REFRESH_EVERY_SECONDS * 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    fetchRaceCards();
  }, [fetchRaceCards, refreshTime]);

  useEffect(() => {
    if (activeEventModel) {
      fetchTote();
      console.log("ACTIVE EVENT MODEL",activeEventModel);
     
      const socket = Sockets.getCurrentSocket();
      
      if(socket) {
        //Sockets.setSocketForUser(activeEventModel.programId, updateModelAttributes);
        socket.subscribeEventId(`stream_${activeEventModel.programId}`, eventFromSocketListener);
        socket.subscribeEventId(activeEventModel.id, eventFromSocketListener);
        console.log('subscribeEventId', activeEventModel.id);
      }
    }
  }, [activeEventModel]);

  // ----------------------------------------------------------------------
console.log(stopBet,stopEventModel,'stopmodel');
const oddsReleased = stopEventModel?.hasOwnProperty('odds');
const win_one = oddsReleased ? stopEventModel.odds['WIN-1'] : '0';
const win_two = oddsReleased ? stopEventModel.odds['WIN-2'] : '0';
  return (
    <Page title={`XO`}>
     
      <ScreenDiv style={{
        // backgroundImage: isExpandedVideoStream ? 'url("https://arionplay-media.s3.ap-southeast-1.amazonaws.com/backgrounds/horses3.jpg")' : undefined,
      }}>
        <BorderCard>
          <Grid container={true} spacing={1}>
            <Grid item={true} xs={12} md={12}>
              <Stack direction="row" spacing={1}>
                <FormControl fullWidth={true} size="small">
                  <OutlinedInput
                    id="bet-amount"
                    type="number"
                    placeholder="Enter bet amount"
                    startAdornment={
                      <InputAdornment position="start" sx={{color:'#fff'}}><div style={{fontWeight:'400', fontSize:'20px'}}>₱</div></InputAdornment>
                    }
                    sx={{
                      backgroundColor: '#40444569', fontFamily:'Pragati Narrow', fontSize:'24px', fontWeight:'700',
                      "& .MuiOutlinedInput-notchedOutline" : {
                        borderColor : theme.palette.action.disabledBackground,
                      },
                    }}
                    value={betAmount}
                    onChange={handleInputAmountChange}
                  />
                </FormControl>
                <Button
                  variant="contained"
                  size="small"
                  onClick={handleReset}
                >
                  Reset
                </Button>
                {/* <Button variant="contained" color="info" onClick={() => setBetAmount('')}>
                  <div style={{ padding: 2, width: '100%', height: '100%' }}>
                    <ResetIconSvg />
                  </div>
                </Button> */}
              </Stack>
            </Grid>

            <Grid item={true} xs={12} md={12}>
              <Stack direction="row" spacing={1}>
                <ToggleButtonGroup
                  fullWidth={true}
                  value={betAmount}
                  exclusive={true}
                  color="primary"
                  aria-label="bet amount"
                  sx={{
                    backgroundColor: '#40444569',
                    borderColor : theme.palette.action.disabledBackground,
                  }}
                >
                {amounts.map((amount: string) => (
                  <ToggleButton
                    key={amount}
                    value={amount}
                    sx={{fontFamily:'Pragati Narrow', fontSize:'22px', fontWeight:'700', height:'44px'}}
                    onClick={(event: React.MouseEvent<HTMLElement>, newBetAmount: string | null) => {
                      setBetAmount(newBetAmount);
                    }}
                  >
                    {amount}
                  </ToggleButton>
                ))}
                </ToggleButtonGroup>
              </Stack>
            </Grid>
            {((strAmount: string) => {
              const numAmount = parseInt(strAmount || '0') || 0;
              return (
                <>
                  <Grid item={true} xs={4} md={4}>
                    <HorseBetButton
                      type={BET_ODD}
                      odds={selectedToteModel?.odds ? selectedToteModel.odds['WIN-1'] : null}
                      betAmount={numAmount}
                      onClick={handlePlaceBet}
                      disabled={!isActiveEventOpenToBet}
                    />
                  </Grid>
                  <Grid item={true} xs={4} md={4} className='openclose'>
                    <StyledRaceInfoButtonBase
                      onClick={handleOpenRaceCardsClick}
                      // aria-controls={openHorsesPopover ? 'account-menu' : undefined}
                      aria-haspopup="true"
                      // aria-expanded={openHorsesPopover ? 'true' : undefined}
                      sx={{ background: 'none' }}
                      disabled={!isActiveEventOpenToBet}
                    >
                      <Stack
                        direction="column"
                        alignItems="center"
                        spacing={1}
                        sx={{width:'100%', height:'100%', justifyContent:'space-between' }}
                      >
                        <Box sx={{width:'auto', display:'flex', flexDirection:'column', alignItems:'center'}}>
                          <div style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                         <div>{getDotSVG(isActiveEventOpenToBet)}</div>
                          <Typography sx={{width:'100%', fontSize:'15px', lineHeight:'19px'}} variant="h5" component="span" style={{ marginLeft: 4 }}>
                            { isActiveEventOpenToBet ? 'OPEN' : 'CLOSED' }
                          </Typography>
                          
                          </div>
                          
                        </Box>
                        {activeEventModel?.eventResult && <div className='678'>

                           <Chip
                            sx={{
                              width: '100%',
                              padding: '2px',
                              margin: '4px',
                            }}
                            size="small"
                            variant="outlined"
                            label={`Winner: ${activeEventModel.eventResult === 'WINNER-1' ? 'X':'O'}`}
                            color="success"
                          />
                         
                        </div>  }
                        <Typography sx={{width:'100%', lineHeight:'14px', marginTop:'0px- !important'}} variant="caption" className='oddstext'>
                             Odds will change  based on betting  activity
                            </Typography>

                            

                        {isActiveEventOpenToBet &&
                          <>
                            {/* <StyledDividerDiv>&nbsp;</StyledDividerDiv> */}
                            
                            <Typography sx={{width:'100%', display:'flex', flexDirection:'column', lineHeight:'16px', marginTop:'0px !important' }} variant="caption" className='racevs'>
                              {/*{(activeEventModel?.activeRunners || '').split(',').length} */}
                              <span>{activeEventModel.venueName}</span>
                              <span>{activeEventModel.name.replace('Race-', 'Event ')}</span>
                            </Typography>
                            <StyledDividerDiv>&nbsp;</StyledDividerDiv>
                            <Typography sx={{width:'100%', lineHeight:'16px', marginTop:'0px !important'}} variant="caption" className='starttime'>
                              Starts @ <span>{activeEventModel?.startTime || '-'}</span>
                            </Typography>
                          </>
                        }
                      </Stack>
                    </StyledRaceInfoButtonBase>
                  </Grid>
                  <Grid item={true} xs={4} md={4}>
                    <HorseBetButton
                      type={BET_EVEN}
                      odds={selectedToteModel?.odds ? selectedToteModel.odds['WIN-2'] : null}
                      betAmount={numAmount}
                      onClick={handlePlaceBet}
                      disabled={!isActiveEventOpenToBet}
                    />
                  </Grid>
                </>
              );
            })(betAmount)}
          </Grid>
        </BorderCard>
        <Box
          style={{
            zIndex: 0,
            position: isExpandedVideoStream ? 'relative' : 'relative',
            overflow: 'hidden',
            flexGrow: 1,
            width: '100%',
            height: '100%',
            minHeight: 72,
          }}
        >
        {activeStreamSource ?
          <VideoPlayer source={activeStreamSource} />:
          <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {activeEventModel && <RaceVideo venueId={activeEventModel.programId.toString()} status={activeEventModel.eventStatus} />}
          </div>
        }
        </Box>
        <Stack
          direction="row"
          justifyContent="space-between"
          spacing={1}
          className='gameoddeven'
          sx={{
            position: 'absolute',
            top: 16,
            right: 0,
            width: '100%',
            padding: '0px 16px',
            minHeight: '2rem',
          }}
         >
          <StyledRaceInfoButtonBase
            onClick={handleOpenEventsClick}
            aria-controls={Boolean(eventsAnchorEl) ? 'events-menu' : undefined}
            aria-expanded={Boolean(eventsAnchorEl) ? 'true' : undefined}
            aria-haspopup="true"
            disabled={!sportEventModels?.length}
            sx={{ width: 'auto', minWidth: '80', minHeight:'26px' }}
            className='5678'
          >
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              className='12'
              sx={{width:'100%'}}
            >
              <div className='gameoddevenview'>
                <div className='eventsvgicon' >{getDotSVG(isActiveEventOpenToBet)}</div>
                <div className='eventslist'>
              {<Typography variant="caption" className='122' >
              
                {sportEventModels?.length ?
                  (
                    activeEventModel ?
                      `${activeEventModel.startTime} - ${activeEventModel.venueName} - ${activeEventModel.name.replace('Race', 'Event')}`:
                      'No active event'
                  ):
                  'No available events'
                }
              </Typography>}
              {<Typography variant="caption"  className='closebetview'>
                {sportEventModels?.length ?
                  (
                    activeEventModel && activeEventModel.odds ? 
                    `${activeEventModel.eventStatus === 'FINAL' ? (activeEventModel.eventResult === 'WINNER-1') ? 'Winner :  X' : 'Winner :  O' : ''} Closed at : X(${activeEventModel.odds['WIN-1'] || 0 }) , O(${activeEventModel.odds['WIN-2'] || 0}) `:''
                  ):
                  'No available events'
                }
              </Typography>}
              </div>
            </div>
            </Stack>
            <KeyboardArrowDown></KeyboardArrowDown>
          </StyledRaceInfoButtonBase>
          {!isMobile &&
            <Fab
              size="small"
              color="info"
              aria-label="Expand video"
              onClick={() => setIsExpandedVideoStream(!isExpandedVideoStream)}
              style={{ padding: 8 }}
            >
              { isExpandedVideoStream ? <CollapseIconSvg /> : <ExpandIconSvg /> }
            </Fab>
          }
        </Stack>
      </ScreenDiv>
      {
        stopBet &&                 
        <div className='betcloseevent'>
          
          <div className='betclosepopup'>
          <div className='closbtn' onClick={() => setStopBet(!stopBet)}>
           <CloseIcon></CloseIcon>
          </div>
          <div className='closeicon'><ClosePopSVG/></div>
            
          <Typography variant="h4" sx={{fontSize:'18px !important', fontWeight:'700', lineHeight:'26px', paddingTop:'15px'}}  >
            
          {`${stopEventModel.venueName}`} - {stopEventModel.name.replace('Race-', 'Event ')}
          </Typography>
          <Typography variant="h5" sx={{fontSize:'18px !important', fontWeight:'700', lineHeight:'26px', paddingBottom:'10px' }}  >
            Closed at
          </Typography>

          <Typography variant="h6" sx={{fontSize:'24px !important', fontWeight:'700', lineHeight:'26px'}}  >
            X : {win_one} O : {win_two} 
            
          </Typography>

          </div>
        </div>
      }
      {
        resultPopover && stopEventModel?.eventResult &&         
        <div className='betcloseevent'>
          
          <div className='betclosepopup betsucesspopup'>
            <img style={{height:'260px', position:'absolute', top:'30px'}}  src='https://www.northalley.com/projects/arionplay/animation.gif' alt=''/>
          <div className='closbtn' onClick={() => setResultPopover(!resultPopover)}><CloseIcon></CloseIcon></div>
           <div className='croenicons'> <CrownPopSVG/></div>

           <Typography variant="h4" sx={{fontSize:'16px !important', fontWeight:'700', lineHeight:'26px', paddingTop:'15px'}}  >
          {`${stopEventModel.venueName}`} - {stopEventModel.name.replace('Race-', 'Event ')}
          </Typography>
          <Typography variant="h5" sx={{fontSize:'16px !important', fontWeight:'700', lineHeight:'26px', paddingBottom:'15px' }}  >
          {stopEventModel.eventResult !== 'REFUND' && ` Winner is `} 
          </Typography>

          <Typography variant="h6" sx={{fontSize:'28px !important', fontWeight:'700', lineHeight:'26px'}}  >
            {stopEventModel.eventResult === 'WINNER-1' && ` X : ${win_one}`}
            {stopEventModel.eventResult === 'WINNER-2' && ` O : ${win_two}`}
            {stopEventModel.eventResult === 'REFUND' && ` TIE `}

            
          </Typography>            
          </div>
        </div>
      }
      <HorsesPopover
        anchorEl={horsesAnchorEl}
        handleClose={handleCloseRaceCards}
        runners={activeEventModel?.runners}
      />

      <SportEventsPopover
        anchorEl={eventsAnchorEl}
        handleClose={handleCloseEvents}
        sportEventModels={sportEventModels}
        activeEventId={activeEventModel?.id}
        isActiveEventOpenToBet={isActiveEventOpenToBet}
        onChangeEvent={onChangeEvent}
      />
      
      <SportEventsBetConfirmPopover
        raceBet={placeRaceBet}
        handleCancel={() => setPlaceRaceBet(null)}
        handleConfirm={handleSendBet}
      />      

    </Page>
  );
};

export default GameOddEven;
