import React, { useState }  from 'react';
import { Link, useNavigate } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover';
import Stack from '@mui/material/Stack';
import useMediaQuery from '@mui/material/useMediaQuery';
//
import { Button as FButton } from '@/components';
// import ProfileDropDown from '@/components/profile/profile-dropdown';
import WalletDropdown from '@/components/profile/wallet-dropdown';
//
import CookieStorageService from '@/services/CookieStorageService';
import { setIsAuthenticatedData, resetUser } from '@/redux/slices/login';
import AuthService from '@/services/AuthService';
import { useAppDispatch } from '@/hooks/useAppDispatch';


import {
  DepositSvg,
  UserLoginSvg,
  MyWalletSvg,
  BettingHistorySvg,
  MyAccountSvg,
  MyActivitySvg,
  FaqHelp,
  LogoutSvg,
} from '@/shared/svg';



import { StyledHeaderButtonGroup } from './style';

interface ChildProps {
  parentModalHandlerFunction: any;
}

export const PostLoginButtons = (props: ChildProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const openMenuPage = (page: string) => () => {
    handleClose();
    setTimeout(function(){
      navigate(`/${page}`);
    }, 500);
  };


  const logoutUser = () => {
    CookieStorageService.deleteCookieData(AuthService.getAuthKey());
    dispatch(setIsAuthenticatedData(false));
    dispatch(resetUser());
    setTimeout(function(){
      window.open('/', '_self');
    }, 500);
  };

  return (
    <StyledHeaderButtonGroup >
      <Stack direction="row" flexWrap="nowrap" justifyContent="center" alignItems="center" spacing={1}>
        <WalletDropdown />
        {/* <Button
          onClick={() => props.parentModalHandlerFunction('kyc')}
          className='loginbtn'
        >
          {' '}
          <DepositSvg />
          WithDraw{' '}
        </Button> */}
        {/* <FButton
          onClick={() => props.parentModalHandlerFunction('deposit')}
          className='loginbtn Deposit'
        >
          {' '}
          <DepositSvg />
          <span>Deposit </span>
        </FButton> */}
        {isMobile ? (
            <Button
              color="primary"
              variant="outlined"
              sx={{ minWidth: 'auto' }}
              // edge="start"
              aria-label="deposit"
              onClick={() => props.parentModalHandlerFunction('deposit')}
            >
              <DepositSvg />
            </Button>
          ) : (
            <><Button
              color="primary"
              className='loginbtn'
              variant="outlined"
              aria-label="deposit"
              
              onClick={() => props.parentModalHandlerFunction('deposit')}
            >
              <DepositSvg />
              Deposit
            </Button>
            {/* <Button
              color="primary"
              variant="outlined"
              aria-label="deposit"
              startIcon={<DepositSvg />}
              onClick={() => props.parentModalHandlerFunction('requestdeposit')}
            >
                Request Deposit
              </Button> */}
              {/* <Button
              color="primary"
              variant="outlined"
              aria-label="withdraw"
              startIcon={<DepositSvg />}
              onClick={() => props.parentModalHandlerFunction('requestwithdraw')}
            >
                Request Withdraw
              </Button> */}
              </>
          )}
        <IconButton
          aria-label="Profile dropdown"
          color="inherit"
          onClick={handleClick}
        >
          <UserLoginSvg />
        </IconButton>
      </Stack>
      <Menu
        anchorEl={anchorEl}
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        id="account-menu"
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={handleClose}
        className='submenuview'
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            backgroundColor: '#1a2436',
            color: '#fff',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.62))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: '#1a2436',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem component={Link} to="/myaccount">
          <ListItemIcon>
            <MyAccountSvg />
          </ListItemIcon>
          My account
        </MenuItem>
        <MenuItem component={Link} to="/mywallet">
          <ListItemIcon>
            <MyWalletSvg />
          </ListItemIcon>
          My Wallet
        </MenuItem>
        <MenuItem component={Link} to="/bettinghistory">
          <ListItemIcon>
            <BettingHistorySvg />
          </ListItemIcon>
          Betting History
        </MenuItem>
        <MenuItem component={Link} to="/myactivity">
          <ListItemIcon>
            <MyActivitySvg />
          </ListItemIcon>
          My Activity
        </MenuItem>
        <Divider style={{ backgroundColor: '#fff' }}/>
        <MenuItem component={Link} to="/faqs">
          <ListItemIcon>
            <FaqHelp />
          </ListItemIcon>
          {`FAQs & Help`}
        </MenuItem>
        <MenuItem onClick={logoutUser}>
          <ListItemIcon>
            <LogoutSvg />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </StyledHeaderButtonGroup>
  );
};
