import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
//
import CookieStorageService from '@/services/CookieStorageService';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import { changeModule } from '@/redux/slices/module';
import GameCard from '@/components/RTGgamecard/card';
import ComingSoongamecard from '@/components/ComingSoongamecard';
import GameService from '@/services/GameService';
import TwoGameCard from '@/components/2Ggamecard';
import TripleCrown from '@/components/TripleCrown';
import RTGGameCard from '@/components/RTGgamecard';
import EVLGameCard from '@/components/EVLgamecard';
import RacingService from '@/services/RacingService';
import ComingSoonPopUp from '@/components/ComingSoonPopUp';
import GameList from '@/components/GameList';

import { useAppSelector } from '@/hooks/useAppSelector';

type Props = {
  onClose: () => void;
};

const MenuContext = ({ onClose }: Props) => {
  const [gameList, setGameList] = useState(null);

  const getGamesList = async () => {
    const response = await GameService.getGamesData();
    if (response.data && response.data.length > 0) {
      //filter ans sort by desc order
      const groupByCategory = response.data
        // .filter((game: any) => game.status === 'ACTIVE' && game.priority > 0)
        .sort((x: any, y: any) => {
          return y.priority - x.priority;
        })
        .reduce((group: any, game: any) => {
          const { providerId } = game;
          group[providerId] = group[providerId] ?? [];
          game.image = 'thumbnail_320x320.jpg';
          game.infoUrl = `${process.env.REACT_APP_GAME_S3_PATH}${game.providerId}/${game.id}/factsheet_eng.pdf`;
          //game.infoUrl = `${process.env.REACT_APP_CDN_IMAGES_PATH}/baccarat-factsheet.pdf`;
          // if(game.infoUrl === ''){
          //   game.infoUrl = `${process.env.REACT_APP_CDN_IMAGES_PATH}/factsheet.pdf`;
          // }

          group[providerId].push(game);
          return group;
        }, {});
      setGameList(groupByCategory);
    }
  };

  useEffect(() => {
    getGamesList();
  }, []);

  // ----

  return (
    <Box
      style={{
        padding: '12px 1rem 1rem 1rem',
        backgroundAttachment: 'scroll',
        backgroundRepeat: 'repeat',
        backgroundSize: '4px, 4px',
        backgroundImage:
          'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAACXBIWXMAAAsTAAALEwEAmpwYAAABy2lUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iWE1QIENvcmUgNS40LjAiPgogICA8cmRmOlJERiB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiPgogICAgICA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIgogICAgICAgICAgICB4bWxuczp0aWZmPSJodHRwOi8vbnMuYWRvYmUuY29tL3RpZmYvMS4wLyIKICAgICAgICAgICAgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIj4KICAgICAgICAgPHRpZmY6T3JpZW50YXRpb24+MTwvdGlmZjpPcmllbnRhdGlvbj4KICAgICAgICAgPHhtcDpDcmVhdG9yVG9vbD53d3cuaW5rc2NhcGUub3JnPC94bXA6Q3JlYXRvclRvb2w+CiAgICAgIDwvcmRmOkRlc2NyaXB0aW9uPgogICA8L3JkZjpSREY+CjwveDp4bXBtZXRhPgoE1OjLAAAAIElEQVQIHWNgYGDYAcQw8J8RxgLS/4F4J4wP4sBVonAA34gG35P5j4cAAAAASUVORK5CYII=)',
      }}
    >
      <Box sx={{ mB: 2 }}>
        <Link to='/' onClick={onClose}>
          <img height='40px' src={process.env.REACT_APP_THEME_LOGO} alt='' />
        </Link>
      </Box>
      <Box>
        {gameList &&
          ['we', 'evo', 'rtg'].map((providerId: string) => (
            <GameList
              key={providerId}
              providerId={providerId}
              games={gameList[providerId]}
              isMenu={true}
              onClick={(gameId: string) => onClose()}
            />
          ))}
      </Box>
    </Box>
  );
};

export default MenuContext;
