import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
// Mui
import Container from '@mui/material/Container';
//
import CookieStorageService from '@/services/CookieStorageService';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import { changeModule } from '@/redux/slices/module';
import AuthService from '@/services/AuthService';
import Page from '@/components/Page';
import HeaderTitle from '@/components/Custom/HeaderTitle';
import WidgetIndex from '@/components/Custom/widgets';
import { Grid } from '@mui/material';
import Responscontent from '../responsible/responscontent';
import Betrulescontent from './betrulescontent';

const leftSideWidgets = [{comp: 'Contact', desc: `Everything you need to know about the ArionPlay Dev Gaming. Can't find the answer you're looking for?`}, {comp: 'Support'}, {comp: 'Chat'}];
const Betrules = () => {
  const dispatch = useAppDispatch();
  const history = useNavigate();
  const [tab, setTab] = useState('livegames');
  const redirectToContact = async () => {
    const token = await CookieStorageService.getItem(AuthService.getAuthKey());
    if (token === undefined || token == '') {
      dispatch(changeModule('login'));
      return false;
    }
    history(`/contactus`);
  };

  const selectTab = async (val: any) => {
    setTab(val);
  };

  return (
    <Page title="Bet Rules" favicon={process.env.REACT_APP_THEME === 'fairplay' ? '/icons/partners/fairplay/favicon.ico' : null}>
      <Container maxWidth={'lg'} style={{paddingLeft:'0px', paddingRight:'0px'}}>
      <HeaderTitle title="Bet Rules" />
        <Grid container spacing={3} style={{}}>
          <Grid item style={{ width: "366px" }}>
            <WidgetIndex componentsList={leftSideWidgets} />
          </Grid>
          <Grid item xs style={{position:'relative'}}>
           <Betrulescontent/>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default Betrules;
