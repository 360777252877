import {
  FormGroup,
  Label,
  Input,
  Button,
  ButtonSpinner,
  InputGroup,
} from '@/components';
import { useForm, SubmitHandler } from 'react-hook-form';
import React, { useEffect, useState } from 'react';
import { useAppSelector } from '@/hooks/useAppSelector';
import { hasKey } from '@/shared/methods';
import AuthService from '@/services/AuthService';

import {
  StyledWrap,
  StyledTitle,
  StyledDesc,
  StyledAlertMessage,
  StyledButtonContainer,
  StyledAlertMessageSucess,
  StyledPhoneText,
  StyledErrorMessage,
  StyledWrapError,
  StyledWrapSucess,
  StyledSucessMessage,
} from './style';
import {
  AlertWarningiconSvg,
  DangericonSvg,
  KycVerifiedIconSvg,
  TickCircleSvg,
} from '@/shared/svg';
import CookieStorageService from '@/services/CookieStorageService';
import { Grid } from '@mui/material';

interface ChildProps {
  parentModalHandlerFunction: any;
}

const DepositForm = (props: ChildProps) => {
  console.log(props);
  //const dispatch = useAppDispatch();
  const [message, setMessage] = useState('');
  const [messageDetails, setMessageDetails] = useState('');
  const [messagestatus, setMessageStatus] = useState('');
  const [isNotValid, setIsNotValid] = useState(false);
  const [isamountValidation, setIsamountValidation] = useState(false);
  const [loading, setLoading] = useState(false);
  const [displayDiv, setDisplayDive] = useState('part1');
  const [withDrawObj, setWithDrawObj] = useState(false);
  const [displayPopup, setDisplayPopup] = useState('');

  //const { userInfo } = useAppSelector(state => state.auth);
  const { selectedUserWallet } = useAppSelector(state => state.wallet);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IDepositForm>();

  const getUserInfo = async () => {
    const token = await CookieStorageService.getItem(AuthService.getAuthKey());
    if (token != undefined) {
      const userData = await AuthService.getUserData();
      if(!userData.data.withdrawDetails?.gcash && selectedUserWallet.type != 'DEMO') {
        setWithDrawObj(true);
      }
    }
  };


  const onGcashSubmit: SubmitHandler<IDepositForm> = async data => {
    const res = {withdrawDetails:{gcash:{mobile:'0'+parseInt(data.phoneNumber),status:'verified'}}}
    const response = await AuthService.updateUserProfile(res);
    if(response.status){
      setWithDrawObj(false);
      setValue('phoneNumber', '');
    }
  }

  // Custom validation rule for amount field
  const validateAmount = (value:any) => {
    if (!value) return 'Amount is required';
    if (isNaN(value)) return 'Invalid amount';
    if (parseFloat(value) <= 0) return 'Amount must be greater than 0';
    if (!/^\d+(\.\d{1,2})?$/.test(value)) return 'Invalid amount format';

    return true; // Validation passed
  };

  const onSubmit: SubmitHandler<IDepositForm> = async data => {

    var amount = data.amount;

    if (typeof amount !== 'number' && isNaN(amount)) {
      setIsamountValidation(true);
      setMessage('Amount must be > 0, not 0');
      return;
    }

    if(data.amount <= 0){
      setIsamountValidation(true);
      setMessage('Amount must be > 0, not 0');
      return;
    }

    if(data.amount > 0 && data.amount < 200){
      setIsamountValidation(true);
      setMessage('The minimum amount required is 200. Please withdraw an <br/> amount of 200 or above to proceed.');
      return;
    }

    if(data.amount > 50000){
      setIsamountValidation(true);
      setMessage('For withdrawal requests above ₱50,000. <br/> Please contact Customer Service at csr@nemo-ig.');
      return;
    }
    setLoading(true);
    const response = await AuthService.withdraw(data, selectedUserWallet.id);
    console.log(response,"WODTHDRAW RESPONSE");
    if (response.data.message) {
      if(response.data.message.toLowerCase() === 'success'){
        setMessageStatus('success');
        setMessage('Your transaction has successfully completed.');
      } else if(response.data.message.toLowerCase() === 'fail'){
        setMessageStatus('error');
        setMessage(`Your transaction has failed.`);
        setMessageDetails(response.data.details);
      } else {
        setMessageStatus('error');
        setMessage('Your transaction has been in process.');
      }
      setLoading(false);
      setIsNotValid(true);

    } else if(response.data.error === 'firstTimeDepositError'){
      setDisplayPopup('firstTimeDepositError');
      setMessageStatus('error');
      setLoading(false);
      setIsNotValid(true);
      setMessage(response.data.error);
    } else if(response.data.error === 'limitreached'){
      setDisplayPopup('limitreached');
      setMessageStatus('error');
      setLoading(false);
      setIsNotValid(true);
      setMessage(response.data.error);
    } else {
      setMessageStatus('error');
      setLoading(false);
      setIsNotValid(true);
      setMessage(response.data.error);
    }
    setDisplayDive('part2');
  }
  
  useEffect(() => {
    getUserInfo();
  }, []);

  return (
    <StyledWrap>
      {(displayDiv === 'part1' && !withDrawObj && selectedUserWallet.type != 'DEMO') ? (
        <><StyledTitle>Withdraw</StyledTitle><StyledDesc>Please enter the amount to withdraw to your Wallet Account.</StyledDesc><form
          className='loginform'
          action='#'
          onSubmit={handleSubmit(onSubmit)}
          noValidate
        >
          {isamountValidation ? (<StyledAlertMessage style={{border: '1px solid #DC6803', background:'rgba(255, 255, 255, 0.05)', fontWeight:'400', fontSize:'13px', color:"#FEFEFE", gap:'10px', lineHeight:'17px', padding:'8px', fontFamily: 'Google Sans', }}><AlertWarningiconSvg /><span dangerouslySetInnerHTML={{ __html: message }} style={{fontFamily: 'Google Sans'}}></span></StyledAlertMessage>) : null}
          <div>
            <Grid container direction='column' md={12} className='no_padding'>
              <Label display='block' mb='5px' htmlFor='email'>
                Amount
                <span style={{color:'#CECECE', fontSize:'13px', fontWeight:'400', width:'100%', textAlign:'right', fontFamily: 'Google Sans',}}>( Maximum of ₱50,000 per day )</span>
              </Label>
              <FormGroup>
              <StyledPhoneText>&#8369;</StyledPhoneText>
                <InputGroup>
                  <Input
                    className='mobilenumber'
                    type='text'
                    id='amount'
                    name='amount'
                    placeholder='Enter Amount'
                    feedbackText={errors?.amount?.message}
                    state={hasKey(errors, 'amount') ? 'error' : 'success'}
                    showState={!!hasKey(errors, 'amount')}
                    {...register('amount', { validate: validateAmount })}
                  />
                </InputGroup>
              </FormGroup>
            </Grid>
          </div>
          <StyledButtonContainer>
            <Button type='submit' color='brand2' className='signin_btn'>
              {loading ? <ButtonSpinner /> : null}Continue
            </Button>
          </StyledButtonContainer>
        </form></>
      ) : (messagestatus === 'success' && !withDrawObj && selectedUserWallet.type != 'DEMO') ? (
        <StyledWrapSucess>
          <KycVerifiedIconSvg />
          <StyledSucessMessage>{message}</StyledSucessMessage>
        </StyledWrapSucess>
      ) : (messagestatus === 'error' && displayPopup !== 'firstTimeDepositError' && displayPopup !== 'limitreached' && !withDrawObj && selectedUserWallet.type != 'DEMO') ?  (
        <StyledWrapError>
          <AlertWarningiconSvg />
           <StyledErrorMessage>{message}</StyledErrorMessage>
           {messageDetails && <StyledErrorMessage><span>{messageDetails}</span></StyledErrorMessage> }
        </StyledWrapError>
      ) : (messagestatus === 'error' && displayPopup === 'firstTimeDepositError' && !withDrawObj && selectedUserWallet.type != 'DEMO') ?  (
        <StyledWrapError>
          <DangericonSvg />
           <StyledErrorMessage>Your Security Matters</StyledErrorMessage>
           <p style={{color:'#cecece', fontSize:'16px', fontWeight:'400', fontFamily: 'Google Sans', }}>To enhance safety and prevent fraud,<br/> withdrawals are temporarily on hold for 3 <br/>banking days following your latest deposit.</p>
           <p style={{color:'#cecece', fontSize:'16px', fontWeight:'400', fontFamily: 'Google Sans',}}>This excludes weekends and holidays</p>
           <p style={{color:'#cecece', fontSize:'16px', fontWeight:'400', fontFamily: 'Google Sans', }}>We appreciate your understanding as we <br/>ensure a secure experience for all our <br/>valued players.</p>
           
        </StyledWrapError>
      ) : (messagestatus === 'error' && displayPopup === 'limitreached' && !withDrawObj && selectedUserWallet.type != 'DEMO') ?  (
        <StyledWrapError>
          <DangericonSvg />
           <StyledErrorMessage style={{color:'#fff', fontSize:'24px', fontWeight:'700', textAlign:'center', fontFamily: 'Google Sans',}}>Daily withdrawal limit  <br/> Reached</StyledErrorMessage>
           <p style={{color:'#CECECE', fontSize:'16px', fontWeight:'400', textAlign:'center', fontFamily: 'Google Sans',}}>You have reached your maximum of <br/> 50,000 Pesos for today. Please try <br/> withdrawing again in 24 hours.</p>
           <p style={{color:'#FFB791', fontSize:'14px', fontWeight:'400', textAlign:'center', fontFamily: 'Google Sans', }}>For any additional help please contact <br/> csr@nemo-ig.com</p>
        </StyledWrapError>
      ) : (selectedUserWallet.type == 'DEMO') ?  (
        <StyledWrapError>
          <AlertWarningiconSvg />
           <StyledErrorMessage>For money withdrawals, kindly switch to a real account.</StyledErrorMessage>
           {messageDetails && <StyledErrorMessage><span>{messageDetails}</span></StyledErrorMessage> }
        </StyledWrapError>
      ) :
      <><StyledTitle>Gcash Mobile Verification</StyledTitle><StyledDesc>Verify your Gcash registered number to withdraw amount from your wallet.</StyledDesc>
      <form
          className='loginform'
          action='#'
          onSubmit={handleSubmit(onGcashSubmit)}
          noValidate
        >
          <div>
            <Grid container direction='column' md={12} className='no_padding'>
                <Label display='block' mb='5px' htmlFor='email'>
                  Phone Number
                </Label>
                <FormGroup>
                  <InputGroup>
                    <Input
                      className='mobilenumber'
                      type='text'
                      id='phoneNumber'
                      name='phoneNumber'
                      placeholder='Enter Phone Number'
                      feedbackText={errors?.phoneNumber?.message}
                      state={hasKey(errors, 'phoneNumber') ? 'error' : 'success'}
                      showState={!!hasKey(errors, 'phoneNumber')}
                      {...register('phoneNumber', {
                        required: 'Phone Number is required',
                        pattern: {
                          value: /^(?!(0))[0-9]{10}$/,
                          message: 'Enter 10 digit valid phone number',
                        },
                      })}

                    />
                  </InputGroup>
                </FormGroup>
            </Grid>
          </div>
          <StyledButtonContainer>
            <Button type='submit' color='brand2' className='signin_btn'>
              {loading ? <ButtonSpinner /> : null}Verify
            </Button>
          </StyledButtonContainer>
        </form></>
        }
    </StyledWrap>
  );
};

export default DepositForm;
