import React from "react";
// import { StyledInfoBoxRight } from "../style";
import { StyledInfoBoxRight } from "../../../components/Custom/mainstyle"
import AccordionSection from "../../../components/Custom/AccordionSection";
import PageHead from "../components/PageHead";

const listDetails = [
    { title: "Who sets the online odds and lines?", desc: "At ArionPlay Dev, our team of expert oddsmakers play a crucial role in setting and continuously adjusting the odds and lines for various events and games. These odds are determined by considering multiple factors, such as the team's performance, player statistics, historical data, injuries, weather conditions, and other relevant information." },
    
];

const FaqTrustSafety: React.FC = () => {
    return (
        <StyledInfoBoxRight>
            <PageHead title="Trust & Safety" />
          <AccordionSection list={listDetails} />
        </StyledInfoBoxRight>
    );
};

export default FaqTrustSafety;
