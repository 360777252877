// Lib
import axios from 'axios';
import { EnqueueSnackbar } from 'notistack';

import ToteModel from '../@types/models/ToteModel';

export type RaceCardBetType = 'ODD' | 'EVEN';
export type RaceYesNoType = 'Y' | 'N';

export type RaceBetType = {
  type: RaceCardBetType,
  poolName: string,
  runners: number,
  startTime: string,
  //
  venueId: string | number,
  venueName: string, ///
  programId: string | number,
  eventId: string | number,
  eventName: string, ///
  //
  userId: string,
  sessionId: string,
  amount: number,
  currency: string,
};

export type EventRunnerType = {
  id: number,
  horseName: string,
  jockeyName: string,
  scratchStatus: RaceYesNoType,
  betStatus: RaceYesNoType,
  stats: null | Array<number>,
  jerseyUrl: null | string,
  drawNumber: null | string,
};

interface EventType {
  id: string,
  programId: number,
  eventId: string,
  name: string,
  startTime: string,
  currentFlag: RaceYesNoType,
  startBetFlag: RaceYesNoType,
  stopBetFlag: RaceYesNoType,
  releaseDividendFlag: RaceYesNoType,
  whiteConeHoistedFlag: null | string,
  runners: null | Array<EventRunnerType>,
  activeRunners: string,
  withDrawals: string,
  nonStarters: string,
  number: null | string,
};

interface VenueType {
  venueId: number,
  venueCode: string,
  venueName: string,
  venueStatus: RaceYesNoType,
  programId: number,
  programName: string,
  programStatus: RaceYesNoType,
  displayOrder: string,
  showCard: RaceYesNoType,
  streamUrl: null | string,
  events?: null | Array<EventType>,
};

type RaceCardsGroupType = {
  date: string;
  venues: Array<VenueType>;
};

export interface RaceEventType extends VenueType, EventType {
  eventStatus?: string;
  eventResult?: string;
  winnerOdds?: string;
  odds?: any;
};


/**
 * Convert grouped data into list of events that can be easy selected.
 */
const convertVenuesStructureIntoEventsStructure = (data: null | RaceCardsGroupType): Array<RaceEventType> => {
  const venues: Array<VenueType> = (data?.venues || []);
  const raceEvents: Array<RaceEventType> = [];
  for (const venue of venues) {
    const events: Array<EventType> = (venue.events || []);
    for (const event of events) {
      // Merge event and the venue data except events from the venue.
      const raceEvent = Object.assign(event, venue);
      delete raceEvent['events'];
      raceEvents.push(raceEvent);
    }
  }
  return raceEvents;
};

/*
 * Fetch Race Cards from external server to display user
 */
export const apiGetSportEventModels = async (
  errorEnqueueSnackbar: null | EnqueueSnackbar = null,
): Promise<null | Array<RaceEventType>> => {
  try {
    // For dev.
    // return convertVenuesStructureIntoEventsStructure(DUMMY_DATA);
    //
    // const result = await axios.get('https://dev-admin.arionplay.com/na-api/api/programs/events/default');
    const result = await axios.get(`${process.env.REACT_APP_AUTHENTICATION_API_URL}/provider/na/events`);
    if (result.status === 200) {
      // return convertVenuesStructureIntoEventsStructure(result.data as RaceCardsGroupType);
      return result.data as Array<RaceEventType>;
    } else {
      console.error(`Not a 200 OK, but ${result.status}`);
    }
  } catch (error) {
    console.error(error);
    if (errorEnqueueSnackbar) {
      errorEnqueueSnackbar(`${error.message}: ${error.response?.data?.error || 'n/a'}`, {
        variant: 'error',
        autoHideDuration: 1000,
      });
    }
  }
  return null;
};

/*
 * Fetch Race Cards from external server to display user
 */
export const apiGetTote = async (
  toteId: string,
  errorEnqueueSnackbar: null | EnqueueSnackbar = null,
): Promise<null | any> => {
  try {
    const result = await axios.get(`${process.env.REACT_APP_AUTHENTICATION_API_URL}tote/${toteId}`);
    if (result.status === 200) {
      return result.data as ToteModel;
    } else {
      console.error(`Not a 200 OK, but ${result.status}`);
    }
  } catch (error) {
    console.error(error);
    if (errorEnqueueSnackbar) {
      errorEnqueueSnackbar(`${error.message}: ${error.response?.data?.error || 'n/a'}`, {
        variant: 'error',
        autoHideDuration: 1000,
      });
    }
  }
  return null;
};

/*
 * Fetch Race Cards from external server to display user
 */
export const apiPlaceRaceCardBet = async (
  programId: string | number,
  eventId: string | number,
  body: RaceBetType,
  errorEnqueueSnackbar: null | EnqueueSnackbar = null,
): Promise<null | any> => {
  try {
    //
    const timestamp = Date.now();
    const result = await axios.post(
      `${process.env.REACT_APP_AUTHENTICATION_API_URL}/provider/na/debit`,
      Object.assign({
        betId: timestamp,
        transactionId: timestamp,
      }, body),
    );
    if (result.status === 200) {
      return result.data
    } else {
      console.error(`Not a 200 OK, but ${result.status}`);
    }
  } catch (error) {
    console.error(error);
    if (errorEnqueueSnackbar) {
      errorEnqueueSnackbar(`${error.message}: ${error.response?.data?.error || 'n/a'}`, {
        variant: 'error',
        autoHideDuration: 1000,
      });
    }
  }
  return null;
};

/*
 * Fetch Race Cards from external server to display user
 */
export const apiPlaceRaceCardBetX = async (
  programId: number,
  eventId: number,
  body: RaceBetType,
  errorEnqueueSnackbar: null | EnqueueSnackbar = null,
): Promise<null | RaceCardsGroupType> => {
  try {
    //
    const result = await axios.post(
      `https://dev-admin.arionplay.com/na-api/api/program/${programId}/event/${eventId}/bet`,
      body,
    );
    if (result.status === 200) {
      return result.data as RaceCardsGroupType;
    } else {
      console.error(`Not a 200 OK, but ${result.status}`);
    }
  } catch (error) {
    console.error(error);
    if (errorEnqueueSnackbar) {
      errorEnqueueSnackbar(`${error.message}: ${error.response?.data?.error || 'n/a'}`, {
        variant: 'error',
        autoHideDuration: 1000,
      });
    }
  }
  return null;
};

const DUMMY_DATA2: RaceCardsGroupType = {
  "date": "2023-10-13",
  "venues": [],
};

const DUMMY_DATA: RaceCardsGroupType = {
  "date": "2023-10-19",
  "venues": [
    {
      "venueId": 380,
      "venueCode": "SAN",
      "venueName": "Sandown (AUS)",
      "venueStatus": "Y",
      "programId": 14417,
      "programName": "SAN-Day",
      "displayOrder": "1",
      "programStatus": "Y",
      "showCard": "Y",
      "streamUrl": "https://d3g5pjcf7e7t4g.cloudfront.net/uno-dos/index.m3u8",
      "events": [
        {
          "id": "207",
          "eventId": "207",
          "name": "Race-1",
          "startTime": "10:00:00",
          "currentFlag": "Y",
          "startBetFlag": "Y",
          "stopBetFlag": "N",
          "programId": 14417,
          "releaseDividendFlag": "N",
          "whiteConeHoistedFlag": null,
          "number": null,
          "runners": [
            {
              "id": 907,
              "horseName": "Horse-1",
              "jockeyName": "Jockey-1",
              "scratchStatus": "N",
              "betStatus": "Y",
              "stats": null,
              "jerseyUrl": null,
              "drawNumber": null
            },
            {
              "id": 908,
              "horseName": "Horse-2",
              "jockeyName": "Jockey-2",
              "scratchStatus": "N",
              "betStatus": "Y",
              "stats": null,
              "jerseyUrl": null,
              "drawNumber": null
            }
          ],
          "activeRunners": "1,2",
          "withDrawals": "",
          "nonStarters": ""
        },
        {
          "id": "208",
          "eventId": "208",
          "name": "Race-2",
          "startTime": "10:10:00",
          "currentFlag": "N",
          "startBetFlag": "Y",
          "stopBetFlag": "N",
          "programId": 14417,
          "releaseDividendFlag": "N",
          "whiteConeHoistedFlag": null,
          "number": null,
          "runners": [
            {
              "id": 909,
              "horseName": "Horse-1",
              "jockeyName": "Jockey-1",
              "scratchStatus": "N",
              "betStatus": "Y",
              "stats": null,
              "jerseyUrl": null,
              "drawNumber": null
            },
            {
              "id": 910,
              "horseName": "Horse-2",
              "jockeyName": "Jockey-2",
              "scratchStatus": "N",
              "betStatus": "Y",
              "stats": null,
              "jerseyUrl": null,
              "drawNumber": null
            }
          ],
          "activeRunners": "1,2",
          "withDrawals": "",
          "nonStarters": ""
        },
        {
          "id": "209",
          "eventId": "209",
          "name": "Race-3",
          "startTime": "10:20:00",
          "currentFlag": "N",
          "startBetFlag": "Y",
          "stopBetFlag": "N",
          "programId": 14417,
          "releaseDividendFlag": "N",
          "whiteConeHoistedFlag": null,
          "number": null,
          "runners": [
            {
              "id": 911,
              "horseName": "Horse-1",
              "jockeyName": "Jockey-1",
              "scratchStatus": "N",
              "betStatus": "Y",
              "stats": null,
              "jerseyUrl": null,
              "drawNumber": null
            },
            {
              "id": 912,
              "horseName": "Horse-2",
              "jockeyName": "Jockey-2",
              "scratchStatus": "N",
              "betStatus": "Y",
              "stats": null,
              "jerseyUrl": null,
              "drawNumber": null
            }
          ],
          "activeRunners": "1,2",
          "withDrawals": "",
          "nonStarters": ""
        },
        {
          "id": "210",
          "eventId": "210",
          "name": "Race-4",
          "startTime": "10:30:00",
          "currentFlag": "N",
          "startBetFlag": "Y",
          "stopBetFlag": "N",
          "programId": 14417,
          "releaseDividendFlag": "N",
          "whiteConeHoistedFlag": null,
          "number": null,
          "runners": [
            {
              "id": 913,
              "horseName": "Horse-1",
              "jockeyName": "Jockey-1",
              "scratchStatus": "N",
              "betStatus": "Y",
              "stats": null,
              "jerseyUrl": null,
              "drawNumber": null
            },
            {
              "id": 914,
              "horseName": "Horse-2",
              "jockeyName": "Jockey-2",
              "scratchStatus": "N",
              "betStatus": "Y",
              "stats": null,
              "jerseyUrl": null,
              "drawNumber": null
            }
          ],
          "activeRunners": "1,2",
          "withDrawals": "",
          "nonStarters": ""
        },
        {
          "id": "211",
          "eventId": "211",
          "name": "Race-5",
          "startTime": "10:40:00",
          "currentFlag": "N",
          "startBetFlag": "Y",
          "stopBetFlag": "N",
          "programId": 14417,
          "releaseDividendFlag": "N",
          "whiteConeHoistedFlag": null,
          "number": null,
          "runners": [
            {
              "id": 915,
              "horseName": "Horse-1",
              "jockeyName": "Jockey-1",
              "scratchStatus": "N",
              "betStatus": "Y",
              "stats": null,
              "jerseyUrl": null,
              "drawNumber": null
            },
            {
              "id": 916,
              "horseName": "Horse-2",
              "jockeyName": "Jockey-2",
              "scratchStatus": "N",
              "betStatus": "Y",
              "stats": null,
              "jerseyUrl": null,
              "drawNumber": null
            }
          ],
          "activeRunners": "1,2",
          "withDrawals": "",
          "nonStarters": ""
        },
        {
          "id": "212",
          "eventId": "212",
          "name": "Race-6",
          "startTime": "10:50:00",
          "currentFlag": "N",
          "startBetFlag": "Y",
          "stopBetFlag": "N",
          "programId": 14417,
          "releaseDividendFlag": "N",
          "whiteConeHoistedFlag": null,
          "number": null,
          "runners": [
            {
              "id": 917,
              "horseName": "Horse-1",
              "jockeyName": "Jockey-1",
              "scratchStatus": "N",
              "betStatus": "Y",
              "stats": null,
              "jerseyUrl": null,
              "drawNumber": null
            },
            {
              "id": 918,
              "horseName": "Horse-2",
              "jockeyName": "Jockey-2",
              "scratchStatus": "N",
              "betStatus": "Y",
              "stats": null,
              "jerseyUrl": null,
              "drawNumber": null
            }
          ],
          "activeRunners": "1,2",
          "withDrawals": "",
          "nonStarters": ""
        },
        {
          "id": "213",
          "eventId": "213",
          "name": "Race-7",
          "startTime": "11:00:00",
          "currentFlag": "N",
          "startBetFlag": "Y",
          "stopBetFlag": "N",
          "programId": 14417,
          "releaseDividendFlag": "N",
          "whiteConeHoistedFlag": null,
          "number": null,
          "runners": [
            {
              "id": 919,
              "horseName": "Horse-1",
              "jockeyName": "Jockey-1",
              "scratchStatus": "N",
              "betStatus": "Y",
              "stats": null,
              "jerseyUrl": null,
              "drawNumber": null
            },
            {
              "id": 920,
              "horseName": "Horse-2",
              "jockeyName": "Jockey-2",
              "scratchStatus": "N",
              "betStatus": "Y",
              "stats": null,
              "jerseyUrl": null,
              "drawNumber": null
            }
          ],
          "activeRunners": "1,2",
          "withDrawals": "",
          "nonStarters": ""
        }
      ]
    }
  ]
};