import {
  FormGroup,
  Label,
  Input,
  Button,
  ButtonSpinner,
  Select,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from '@/components';
import { useForm, SubmitHandler } from 'react-hook-form';
import { Link } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import { Grid } from '@mui/material/';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import {
  setIsAuthenticatedData,
  setUserData,
  setIsKYCStatus,
  setKYCRejectedRemarks,
  setKYCRejectedReason,
  setIsKYCPopup,
} from '@/redux/slices/login';
import { useAppSelector } from '@/hooks/useAppSelector';
import { hasKey } from '@/shared/methods';
import AuthService from '@/services/AuthService';
import { useNavigate } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import Stack from '@mui/material/Stack';
// import {StyledWrap, StyledTitle, StyledDesc, StyledBottomText, StyledForgotText, StyledPhoneText, StyledAlertMessage, StyledPersonalContainer,
// } from '@/pages/auth/style';
import {
  StyledWrap,
  StyledTitle,
  StyledDesc,
  StyledBottomText,
  StyledForgotText,
  StyledPhoneText,
  StyledAlertMessage,
  StyledPersonalContainer,
} from './style';
import {
  GlobalIconSvg,
  MobileSvg,
  PasswordSvg,
  ErrorWarningiconSvg,
  EyeHideIconSvg,
  EyeIconSvg,
} from '@/shared/svg';
import Sockets from '@/services/SocketService';
import { updateModelAttributes } from '@/services/SocketModelUpdate';

interface ChildProps {
  parentModalHandlerFunction: any;
  closePopup: any;
}
const generateRandomCaptcha = () => {
  return Math.floor(1000 + Math.random() * 9000).toString(); // Generates a number between 1000 and 9999
};
const SigninForm = (props: ChildProps) => {
  const dispatch = useAppDispatch();
  const [captchaText, setCaptchaText] = useState(generateRandomCaptcha());
  const history = useNavigate();
  const [message, setMessage] = useState('');
  const [passwordType, setPasswordType] = useState('password');
  const [isNotValid, setIsNotValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isCaptchaDone, setIsCaptchaDone] = useState(false);
  const [captchaMessage, setCaptchaMessage] = useState('');
  const { isLoading, isError, errorMessage } = useAppSelector(
    state => state.auth
  );

  const [userCaptcha, setUserCaptcha] = useState('');
  const recaptchaRef = useRef(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ILoginForm>();

  const showPassword = () => {
    if (passwordType === 'password') {
      setPasswordType('text');
      return;
    }
    setPasswordType('password');
  };

  const onSubmit: SubmitHandler<ILoginForm> = async data => {
    // setCaptchaMessage('');
    // if (!isCaptchaDone) {
    //   setCaptchaMessage('Please verify captcha');
    //   return;
    // }
    setLoading(true);
    data.phone = `${data.countrycode}${data.phone}`;
    //dispatch(authenticateUser(data));

    const resData = { ...data, userCaptcha: 'xyz', skipCaptcha: true };

    const response = await AuthService.authenticate(resData);

    if (response.status) {
      const userData = await AuthService.getUserData();
      // recaptchaRef.current.reset();
      // setCaptchaMessage('');
      // setUserCaptcha('');
      // setIsCaptchaDone(false);
      if (userData.status) {
        setLoading(false);
        Sockets.setSocketForUser(userData.data.id, updateModelAttributes);
        dispatch(setUserData(userData.data));
        dispatch(setIsAuthenticatedData(true));
        dispatch(setIsKYCPopup(userData.data.kycPopupClose));
        if (userData.data.kycVerified === undefined) {
          dispatch(setIsKYCStatus('created'));
        } else {
          dispatch(setIsKYCStatus(userData.data.kycVerified));
          if (
            userData.data.kycVerified !== undefined &&
            userData.data.kycVerified === 'DECLINED'
          ) {
            dispatch(setKYCRejectedRemarks(userData.data.kycDeclinedRemarks));
            dispatch(setKYCRejectedReason(userData.data.kycDeclinedReason));
          }
        }
        props.closePopup('close');
        location.reload();
        // const currentUrl = await LocalStorageService.getItem('current_url');
        // if(currentUrl!==undefined && currentUrl!==''){
        //   history(currentUrl);
        // }
      }
    } else {
      setLoading(false);
      setIsNotValid(true);
      setMessage(response.data);
      // recaptchaRef.current.reset();
      // setCaptchaMessage('');
      // setUserCaptcha('');
      // setIsCaptchaDone(false);
    }
  };

  const openInfoPopup = async (val: string) => {
    props.parentModalHandlerFunction(val);
  };

  useEffect(() => {
    setLoading(isLoading);
    if (isError) {
      setIsNotValid(true);
      setMessage(errorMessage);
    }
  }, [isError, isLoading]);

  const handleCaptcha = (token: string) => {
    setIsCaptchaDone(true);
    setUserCaptcha(token);
    setCaptchaMessage('');
  };

  return (
    <StyledWrap className='signinview'>
      <StyledTitle>Welcome!</StyledTitle>
      <StyledDesc>Login to {process.env.REACT_APP_SITE_TITLE}.</StyledDesc>
      <form
        className='loginform'
        autoComplete='off'
        action='#'
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        {isNotValid ? (
          <StyledAlertMessage>
            <ErrorWarningiconSvg /> {message}
          </StyledAlertMessage>
        ) : null}
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1 }}
          sx={{ marginBottom: '10px' }}
        >
          <Grid item xs={4} className='no_padding mobilecode'>
            <Label display='block' mb='5px' htmlFor='email'>
              Code
            </Label>
            <InputGroup>
              <InputGroupAddon dir='prepend'>
                <InputGroupText>
                  <GlobalIconSvg />
                </InputGroupText>
              </InputGroupAddon>
              <Select
                id='countrycode'
                name='countrycode'
                className='select'
                {...register('countrycode')}
              >
                <option value='+63'>PH(+63)</option>
                <option value='+91'>IN(+91)</option>
                <option value='+44'>UK(+44)</option>
              </Select>
            </InputGroup>
          </Grid>
          <Grid item xs={8} className='mobilephone'>
            <FormGroup>
              <Label display='block' mb='5px' htmlFor='email'>
                Phone Number
              </Label>
              <InputGroup>
                <InputGroupAddon dir='prepend'>
                  <InputGroupText>
                    <MobileSvg />
                  </InputGroupText>
                </InputGroupAddon>
                <StyledPhoneText>(0)</StyledPhoneText>
                <Input
                  className='mobilenumber'
                  type='tel'
                  id='phone'
                  name='phone'
                  placeholder='Phone Number'
                  feedbackText={errors?.phone?.message}
                  state={hasKey(errors, 'phone') ? 'error' : 'success'}
                  showState={!!hasKey(errors, 'phone')}
                  {...register('phone', {
                    required: 'Phone is required',
                    pattern: {
                      value: /^(?!(0))[0-9]{10}$/,
                      message: 'invalid phone format',
                    },
                  })}
                />
              </InputGroup>
            </FormGroup>
          </Grid>
        </Grid>
        <Grid container rowSpacing={1} sx={{ marginBottom: '10px' }}>
          <Grid item xs={12}>
            <Label display='block' mb='5px' htmlFor='email'>
              Password
            </Label>

            <InputGroup>
              <InputGroupAddon dir='prepend'>
                <InputGroupText>
                  <PasswordSvg />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                id='password'
                type={passwordType}
                placeholder='Enter your password'
                feedbackText={errors?.password?.message}
                state={hasKey(errors, 'password') ? 'error' : 'success'}
                showState={!!hasKey(errors, 'password')}
                {...register('password', {
                  required: 'Password is required',
                  minLength: {
                    value: 6,
                    message: 'Minimum length is 6',
                  },
                })}
              />
              <InputGroupAddon className='posswordicon'>
                <div
                  className='passwodicon'
                  color='light'
                  onClick={() => showPassword()}
                >
                  {passwordType === 'password' && <EyeHideIconSvg />}
                  {passwordType === 'text' && <EyeIconSvg />}
                </div>
              </InputGroupAddon>
            </InputGroup>
          </Grid>
          {/* <Grid sx={{ marginTop: '20px', position: 'relative' }}>
            <ReCAPTCHA
              sitekey='6Lc_CWQqAAAAAHbsSmSBA9j-8p09voH1rLhXaxDl'
              ref={recaptchaRef}
              onChange={handleCaptcha}
            />
            <Stack
              sx={{
                fontSize: '11px',
                color: '#ff7c54',
              }}
            >
              {captchaMessage}
            </Stack>
          </Grid> */}
        </Grid>
        <StyledForgotText>
          <Link
            className='flex'
            to={''}
            onClick={() => openInfoPopup('forgot')}
          >
            Forgot Password?
          </Link>
        </StyledForgotText>
        <StyledPersonalContainer>
          <Button type='submit' color='brand2' className='signin_btn'>
            {loading ? <ButtonSpinner /> : null}Sign In
          </Button>
        </StyledPersonalContainer>
        <StyledBottomText>
          Don&apos;t have an account?&nbsp;
          <Link
            className='flex'
            to={''}
            onClick={() => openInfoPopup('register')}
          >
            Sign Up
          </Link>{' '}
        </StyledBottomText>
      </form>
    </StyledWrap>
  );
};

export default SigninForm;
