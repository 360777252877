import React, { useState } from 'react';
import {
  StyledEvlCard,
  StyledEvlCardContainer,
  StyledEvlCardImg,
  StyledEvlText,
  StyledEvlHoverContainer,
  StyledEvlPopup,
  StyledEvlPopupOverlay,
  StyledEvlPopupContainer,
  StyledEvlPopupHeading,
  StyledEvlPopupContent,
  StyledOvelayContainer,
  StyledOverLayBody,
  StyledTitle,
  StyledOverMainContainer,
  StyledAllVenu,
  StyledComminView,
  StyledBannerImg,
  StyledCommingBody,
} from './style';
import { PlaySvg, InfoSvg, CloseIconSvg } from '@/shared/svg/index';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import AuthService from '@/services/AuthService';
import { changeModule } from '@/redux/slices/module';
import CookieStorageService from '@/services/CookieStorageService';
import { Link } from 'react-router-dom';
import ComingSoonPopUp from '../ComingSoonPopUp';

interface childProps {
  id: string | number;
  image: string;
  name: string;
  infoUrl: string;
}
const ComingSoongamecard = (props: childProps) => {
  const dispatch = useAppDispatch();
  const [displayModule, setDisplayModule] = useState(false);
  const gameInfo = async () => {
    setDisplayModule(true);
  };
  const closeGameInfo = async () => {
    setDisplayModule(false);
  };
  const openInfoPopup = async () => {
    setDisplayModule(true);
  };
  return (
    <StyledEvlCard>
      <StyledEvlCardContainer>
        <StyledEvlCardImg>
        <Link to={''} onClick={() => openInfoPopup()}>
            <img
            src={`${process.env.REACT_APP_CDN_AMAZON_IMAGES_PATH}/${props.image}`}
            alt=''
          />
          </Link>
        </StyledEvlCardImg>                
      </StyledEvlCardContainer>
      {displayModule && (
        <StyledEvlPopup>
          <StyledEvlPopupOverlay></StyledEvlPopupOverlay>
          <StyledOverMainContainer>
          <StyledOverLayBody>
            <StyledTitle>Habanero</StyledTitle>
            <button className='close_popup' onClick={() => closeGameInfo()}>
               <CloseIconSvg />
              </button>
            <StyledAllVenu>
                <StyledComminView
                  >
                  <StyledBannerImg>
                    <img
                      src='https://arionplay-media.s3.ap-southeast-1.amazonaws.com/hab/noimage/hab-no-image.png'
                      alt=''
                    />
                  </StyledBannerImg>
                  <StyledCommingBody>
                    <h4>Discover a World of Sheer Gaming</h4>
                    <p>Habanero creates award-winning Slot and Table games popular in all gaming markets.</p>
                    <ul>
                      <li>150+ Games in HTML5 Slots, Table Games and Video Poker</li>
                      <li>Multi Everything 34 Languages, any currency including crypto; in a multi-tenant environment</li>
                      <li>Certified Games Now certified for 22 markets.</li>
                      <li>More Connected Add our games via a Seamless or Transfer Wallet API</li>
                      <li>Discover Sheer Gaming with our collection of award winning Slots, Table games and more!</li>
                    </ul>
                  </StyledCommingBody>
                  
                </StyledComminView>
                <h6>Coming Soon!</h6>
            </StyledAllVenu>
          </StyledOverLayBody>
      </StyledOverMainContainer>
        </StyledEvlPopup>
      )}
    </StyledEvlCard>
  );
};

export default ComingSoongamecard;
