import styled, { device, themeGet } from '@/shared/styled';

export const StyledWrap = styled.div`
  width: 100%;
  .loginform {
    border-radius: 16px;
    background: rgba(255, 255, 255, 0.05);
    padding: 16px 16px 16px 16px;
    margin-top: 20px;
    font-family: ${themeGet('fonts.googleSansBold')};
  }
  .no_padding {
    padding-right: 0px;
  }
  .signin_btn {
    background: ${themeGet('colors.mainborder')};
    display: flex;
    align-items: center;
    font-family: ${themeGet('fonts.googleSansBold')};
    font-size: 13px;
    gap: 4px;
    color: ${themeGet('colors.pagefontcolor')} !important;
    width: 100%;
    margin-top: 15px;
    height: 42px;
    border: none;
  }
  .row {
    margin-bottom: 16px;
  }
  .mobilenumber {
    padding-left: 52px;
  }
  .termsandcondition {
    height: 240px;
    overflow-y: auto;
    color: #fff;
    padding-right: 10px;
    ${device.TabToLg} {
      // height: 350px;
    }
    p {
      font-size: 15px;
      color: #a4adc0;
      font-family: ${themeGet('fonts.googleSansRegular')};
      margin-bottom: 6px;
    }
    h5 {
      font-size: 15px;
      color: #a4adc0;
      font-family: ${themeGet('fonts.googleSansRegular')};
    }
    ol {
      padding-left: 25px;
      h4 {
        font-size: 16px;
        color: #a4adc0;
        font-family: ${themeGet('fonts.googleSansRegular')};
      }
      li {
        font-size: 14px;
        margin-bottom: 8px;
        color: #a4adc0;
        font-family: ${themeGet('fonts.googleSansRegular')};
      }
    }
  }
  .nomargin {
    margin-bottom: 0px;
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }
  form {
    display: flex;
    width: 100%;
    flex-direction: column;
  }
  .termsview {
  }
`;

export const StyledTitle = styled.h3`
  color: #c2c9d6;
  text-align: center;
  font-family: ${themeGet('fonts.googleSansBold')};
  font-size: 24px;
  line-height: 24px;
  margin-bottom: 4px;
  p {
    font-size: 15px;
    color: ${themeGet('colors.eventnumberdefaulttxtcolor')};
    a {
      color: ${themeGet('colors.mainborder')};
    }
  }
  ${device.MobToMd} {
    margin-bottom: 15px;
  }
`;

export const StyledSubHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${device.MobToMd} {
    flex-direction: column-reverse;
  }
`;
export const StyledSubHeaderText = styled.div`
  p {
    font-size: 15px;
    color: ${themeGet('colors.eventnumberdefaulttxtcolor')};
    margin-bottom: 0px;
    font-family: ${themeGet('fonts.googleSansMedium')};
    line-height: 19px;
  }
  ${device.MobToMd} {
    p {
      text-align: center;
      br {
        display: none;
      }
    }
  }
`;

export const StyledSubHeadLogo = styled.div`
  ${device.MobToMd} {
    width: 100%;
    text-align: center;
    img {
      margin-bottom: 15px;
    }
  }
`;
export const StyledDesc = styled.p`
  font-size: 15px;
  color: ${themeGet('colors.eventnumberdefaulttxtcolor')};
  margin-bottom: 20px;
`;

export const StyledAlertMessage = styled.div`
  color: #f31415;
  background-color: #f8d7da;
  border-color: #f5c6cb;
  position: relative;
  padding: 5px 10px;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const StyledLabelWrap = styled.div`
  margin-bottom: 5px;
  justify-content: space-between;
  display: flex;
`;

export const StyledDivider = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin: 15px 0;
  color: #c2c9d6;
  &:after,
  &:before {
    content: '';
    display: block;
    flex: 1;
    height: 2px;
    background-color: ${themeGet('colors.tableheaderborder')};
  }
  &:before {
    margin-right: 10px;
  }
  &:after {
    margin-left: 10px;
  }
`;

export const StyledBottomText = styled.div`
  margin-top: 20px;
  text-align: center;
  font-size: 13px;
  color: #c2c9d6;
  a {
    color: ${themeGet('colors.homebannertxtcolor')};
    font-family: ${themeGet('fonts.googleSansMedium')};
    font-size: 14px;
  }
`;

export const StyledForgotText = styled.div`
  text-align: center;
  padding: 5px 0px;
  a {
    background: none;
    color: ${themeGet('colors.mainborder')};
    font-family: ${themeGet('fonts.googleSansMedium')};
    font-size: 14px;
  }
`;
export const StyledPhoneText = styled.div`
  position: absolute;
  left: 34px;
  top: 10px;
  color: #f0f0f0;
  font-size: 13px;
  z-index: 99;
`;
export const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  padding: 0px;
  padding-top: 24px;
  gap: 20px;

  .loginbtn {
    background: ${themeGet('colors.mainborder')};
    display: flex;
    align-items: center;
    font-family: ${themeGet('fonts.googleSansBold')};
    font-size: 16px;
    border: 0px;
    gap: 4px;
    height: 42px;
    padding: 0px 24px;
    color: ${themeGet('colors.pagefontcolor')} !important;
    &:hover {
      background: ${themeGet('colors.mainborder')};
      font-size: 16px;
      border: 0px;
      height: 42px;
    }
    svg path {
      stroke: ${themeGet('colors.pagefontcolor')} !important;
    }
    &:disabled {
      opacity: 0.2;
    }
  }
  .signupbtn {
    background: none;
    border: 1.5px solid ${themeGet('colors.mainborder')};
    color: ${themeGet('colors.btnfontcolor')};
    display: flex;
    align-items: center;
    gap: 4px;
    height: 42px;
    font-size: 16px;
    padding: 0px 24px;
    font-family: ${themeGet('fonts.googleSansBold')};
    &:hover {
      background: none;
      border: 1.5px solid ${themeGet('colors.mainborder')};
      color: ${themeGet('colors.btnfontcolor')};
      height: 42px;
      font-size: 16px;
    }
    &:active {
      background: none;
      border: 1.5px solid ${themeGet('colors.mainborder')};
      color: ${themeGet('colors.btnfontcolor')};
    }
  }
`;
export const StyledBrowserClose = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  padding: 40px 0px;
  gap: 30px;
  h4 {
    color: ${themeGet('colors.eventnumberdefaulttxtcolor')};
  }
  svg {
    path:last-child {
      fill: inherit;
    }
  }
`;
